/* eslint-disable camelcase */
import axios from 'axios';
import { API_CONSULTATIONS } from '../config';

const getAll = async () => {
    const res = await axios.get(API_CONSULTATIONS, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_CONSULTATIONS}/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const getByPatient = async (id) => {
    const res = await axios.get(`${API_CONSULTATIONS}/pt/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const getByPurpose = async (id) => {
    const res = await axios.get(`${API_CONSULTATIONS}/cp/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const getByPatientAndPurpose = async (patient_id, purpose_id) => {
    const res = await axios.get(
        `${API_CONSULTATIONS}/pt/${patient_id}/cp/${purpose_id}`,
        {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    return res.data;
};

const create = async (body) => {
    const res = await axios.post(API_CONSULTATIONS, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const update = async (id, body) => {
    const res = await axios.put(`${API_CONSULTATIONS}/${id}`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const remove = async (id) => {
    const res = await axios.delete(`${API_CONSULTATIONS}/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};


export {
    getAll,
    getById,
    getByPatient,
    getByPurpose,
    getByPatientAndPurpose,
    create,
    update,
    remove,
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
} from 'react-rainbow-components';
import { navigateTo } from '../../../history';

import './styles.css';

const CpurposeTableButtons = (props) => {
    const { value, handleDeleteDialog, handleEdit } = props;
    const { _id, label } = value;
    return (
        <div className="rainbow-flex rainbow-flex_wrap">
            <Button style={{ margin: 'auto 1em' }} label="Consulter et Modifier" variant="brand" className="button-action variant-brand" onClick={() => { navigateTo(`/c-purposes/${_id}`); handleEdit(value); }} />
            <Button label="Supprimer" variant="destructive" className="button-action variant-destructive" onClick={() => { handleDeleteDialog({ _id, label }); }} />
        </div>


    );
};

CpurposeTableButtons.propTypes = {
    value: PropTypes.object.isRequired,
    handleDeleteDialog: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
};


export default (CpurposeTableButtons);

import {
    START_LOADING_DASHBOARD,
    STOP_LOADING_DASHBOARD,
    LOAD_DASHBOARD_DATA,
} from '../../actions/dashboard';

const initialState = {
    numberOfActs: 0,
    consultationsNumber: 0,
    telecNumber: 0,
    // revenu: 0,
    numberOfNewPatients: 0,
    consultationsStats: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    telecStats: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    isLoading: false,
    isFirstTime: true,
};

export default function dashboardData(state = initialState, action) {
    switch (action.type) {
        case LOAD_DASHBOARD_DATA:
            return { ...state, ...action.payload };
        case START_LOADING_DASHBOARD:
            return { ...state, isLoading: action.payload };
        case STOP_LOADING_DASHBOARD:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Modal, Card, Button, Input,
} from 'react-rainbow-components';
import { Email, Lock } from '../icons';
import { disconnectAsync, sessionExpired } from '../../redux/actions/auth';
import { setSnackbar } from '../../redux/actions/snackbar';
import { auth } from '../../firebase';
import history from '../../history';
import './styles.css';

class EmailVerification extends React.Component {
    state = {
        email: '',
        password: '',
        modalIsOpen: false,
    };


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }


    handleOpenModal = () => {
        const { modalIsOpen } = this.state;
        if (modalIsOpen) {
            this.setState({ email: '', password: '' });
        }
        this.setState({ modalIsOpen: !modalIsOpen });
    }


    handleSubmit = async () => {
        const { email, password } = this.state;
        const { dispatch, setSnackbar } = this.props;
        try {
            const res = await auth.signInWithEmailAndPassword(
                email,
                password,
            );
            if (res.user) {
                res.user.sendEmailVerification();
                setSnackbar(
                    "l'email de vérification à été envoyé avec succès",
                    'success',
                );
            }
        } catch (err) {
            sessionExpired(err, dispatch);
        }

        this.handleOpenModal();
    }

    render() {
        const { modalIsOpen, email, password } = this.state;
        const emailVerifLabel = "Envoyer l'e-mail de vérification".toUpperCase();
        const { disconnectAsync } = this.props;
        return (
            <div className="email-verif-container">
                <Card className="react-rainbow-admin-forms_card rainbow-p-top_large"
                    footer={(
                        <div className="rainbow-flex rainbow-justify_spread">
                            <Button className="variant-brand" variant="brand" onClick={() => { disconnectAsync(null); history.push('/login'); }} label="CONTINUER" />
                            <Button className="variant-brand" variant="brand" onClick={() => this.handleOpenModal()} label={emailVerifLabel} />
                        </div>
                    )}>
                    <div className="react-rainbow-admin-forms_header">
                        <img src="/assets/images/logo_dark.png" alt="katomi logo" className="react-rainbow-admin-forms_logo" />
                        <h1> Activation de votre compte </h1>
                    </div>
                    <p className="text-card">
                        Un email de vérification vous à été envoyé, veuillez le consulter afin d'activer votre compte.
                    </p>
                </Card>
                <Modal
                    isOpen={modalIsOpen}
                    title="Activation de votre compte"
                    onRequestClose={this.handleOpenModal}
                    footer={(
                        <div className="rainbow-flex rainbow-justify_end">
                            <Button label="Annuler" variant="neutral" onClick={() => this.handleOpenModal()} />
                            <Button label="Envoyer" variant="brand" onClick={() => this.handleSubmit()} />
                        </div>
                    )}
                >
                    <Input
                        onChange={(e) => { this.handleChange(e); }}
                        className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                        icon={<Email />}
                        value={email}
                        name="email"
                        label="Email"
                        placeholder="Tapez votre Email"
                        type="text"
                    />
                    <Input
                        onChange={(e) => { this.handleChange(e); }}
                        className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                        icon={<Lock />}
                        value={password}
                        name="password"
                        label="Mot de passe"
                        placeholder="Tapez votre mot de passe"
                        type="password"
                    />
                </Modal>

            </div>

        );
    }
}

EmailVerification.propTypes = {
    disconnectAsync: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
};

// EmailVerification.defaultProps = {
// };


// const stateToProps = () => {
// };

const dispatchToProps = dispatch => bindActionCreators(
    {
        disconnectAsync,
        setSnackbar,
    },
    dispatch,
);

export default (connect(null, dispatchToProps)(EmailVerification));

/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Avatar from 'react-rainbow-components/components/Avatar';
import { PersonIcon } from '../../components/icons';
import { API_PHOTOS } from '../../config';

const getClassNames = isSelected => classnames('react-rainbow-admin-messages_contact', {
    'react-rainbow-admin-messages_contact--active': isSelected,
});

// eslint-disable-next-line max-len
const ConversationsList = ({ conversations, ...props }) => Object.values(conversations).map((conv, index) => {
    const {
        user, selectedUser, onClick, showSelectedUser,
    } = props;
    let isSelected = false;
    if (selectedUser && showSelectedUser) {
        isSelected = selectedUser._id === conv.user;
    }
    const name = `${conv.user_info.lastName} ${conv.user_info.firstName}`;
    const photoUrl = `${API_PHOTOS}/load/${conv.user}/profile/thumbnail/profile-picture.webp`;
    const lastMessage = conv.messages[0];
    const lastMessageSender = lastMessage.sender === user._id
        ? 'Vous'
        : `${conv.user_info.firstName}`;
    const lastMessageContent = lastMessage.content;
    const key = `contact-${index}`;

    const isUnseen = !lastMessage.seen && !isSelected && lastMessage.sender !== user._id;
    return (
        <article
            role="presentation"
            key={key}
            className={classnames(getClassNames(isSelected), { 'unseen-message': isUnseen })}
            onClick={() => onClick(conv)}
        >
            <Avatar
                className="react-rainbow-admin-messages_contact-avatar"
                src={photoUrl}
                icon={<PersonIcon />}
                size="small"
            />
            <div className={classnames('react-rainbow-admin-messages_contact-content')}>
                <div className="rainbow-flex" style={{ alignItems: 'center' }}>
                    <h3 className="react-rainbow-admin-messages_contact-name">
                        {name}
                    </h3>
                    <span className={classnames({ 'unseen-indicator': isUnseen })} />
                </div>
                <p className="react-rainbow-admin-messages_contact-message">
                    <b>
                        {lastMessageSender}
: &nbsp;
                    </b>
                    {' '}
                    {lastMessageContent}
                </p>
            </div>
        </article>
    );
});

ConversationsList.propTypes = {
    onClick: PropTypes.func,
    conversations: PropTypes.object.isRequired,
    selectedUser: PropTypes.object,
    showSelectedUser: PropTypes.bool,
    selectedConversation: PropTypes.object,
};

ConversationsList.defaultProps = {
    onClick: () => null,
    selectedUser: null,
    showSelectedUser: true,
    selectedConversation: {},
};

const stateToProps = state => ({
    selectedUser: state.chat.selectedUser,
    doctors: state.chat.doctors,
    patients: state.chat.patients,
    conversations: state.chat.conversations,
    user: state.auth.user,
});

export default connect(stateToProps, null)(ConversationsList);

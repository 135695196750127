/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-rainbow-components';

const TelecHistoryTableActions = (props) => {
    const { row, handleDeleteDialog } = props;
    return (
        <div className="rainbow-flex rainbow-flex_wrap">
            <Button
                label="Supprimer"
                variant="destructive"
                className="button-action variant-destructive"
                onClick={() => {
                    handleDeleteDialog(row);
                }}
            />
        </div>
    );
};

TelecHistoryTableActions.propTypes = {
    row: PropTypes.object.isRequired,
    handleDeleteDialog: PropTypes.func.isRequired,
};

export default TelecHistoryTableActions;

/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Column, Table,
} from 'react-rainbow-components';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import TablePagination from '../../../components/TablePagination';
import TableActions from './tableActions';
import TableTitle from '../../../components/TableTitle';

const formatDate = (date) => {
    const newDate = new Date(date).getTime();
    return DateTime.fromMillis(newDate).toFormat('dd/MM/yyyy à hh:mm');
};


class TelecHistoryTable extends React.Component {
    state = {
        activePage: 1,
    };

     prepareTableData = () => {
         const { telecList } = this.props;
         const tableData = [];
         telecList.map(telec => tableData.push({
             patient: `${telec.doctor.lastName} ${telec.doctor.firstName}`,
             date: formatDate(telec.date),
             status: telec.status,
             _id: telec._id,
             telec,
         }));
         return tableData;
     };

    setActivePage = (ev, activePage) => {
        this.setState({ activePage });
    }


     getTableData = () => {
         const { activePage } = this.state;
         const firstItem = (activePage - 1) * 8;
         const lastItem = firstItem + 8;
         return this.prepareTableData().slice(firstItem, lastItem);
     };

     getPages = () => {
         const { telecList } = this.props;
         Math.ceil(telecList.length / 8);
     };

     render() {
         const { activePage } = this.state;
         return (
             <div>
                 <div className="react-rainbow-admin-users_tab-content">
                     <TableTitle title="Historique des téléconsultations" />
                     <Table resizeColumnDisabled data={this.getTableData()} keyField="nature" emptyDescription="Aucune téléconsultation effectuée" emptyTitle="">
                         <Column header="Date" field="date" />
                         <Column header="Patient" field="patient" />
                         <Column header="Status" field="status" />
                         <Column header="Actions" field="_id" component={TableActions} />
                     </Table>
                     <TablePagination
                         pages={this.getPages()}
                         activePage={activePage}
                         onChange={this.setActivePage} />

                 </div>
             </div>
         );
     }
}


TelecHistoryTable.propTypes = {
    telecList: PropTypes.array.isRequired,
};


const stateToProps = state => ({
    telecList: state.teleconsultation.old,
});

export default connect(stateToProps)(TelecHistoryTable);

/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    ButtonIcon,
    AvatarMenu,
    Avatar,
    MenuItem,
    MenuDivider,
    ButtonMenu,
} from 'react-rainbow-components/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencilAlt,
    faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import {
    BarsIcon,
    PersonIcon,
} from '../icons';
import Notification from './notification';
import { disconnectAsync } from '../../redux/actions/auth';
import { API_PHOTOS } from '../../config';
import { navigateTo } from '../../history';
import { setNotificationsAsync, deleteMessageNotifs } from '../../redux/actions/notifications';
import { setPatientsListAsync } from '../../redux/actions/patients';
import { setTelecListAsync } from '../../redux/actions/teleconsultation';
import { setTelexListAsync } from '../../redux/actions/teleexpertise';
import { removeNotification } from '../../api/notifications';
import './styles.css';

const SectionHeading = (props) => {
    const [profilePicture, setProfilePicture] = useState(null);
    const { user, setNotificationsAsync } = props;

    useEffect(() => {
        let unsubscribe;
        if (user) {
            unsubscribe = setNotificationsAsync(user._id);
            setProfilePicture(`${API_PHOTOS}/load/${user._id}/profile/thumbnail/profile-picture.webp?${Date.now()}`);
        }

        return () => {
            if (user) {
                unsubscribe();
            }
        };
    }, [user]);


    const handleNotificationClick = async (notif) => {
        const {
            setPatientsListAsync,
            setTelecListAsync,
            setTelexListAsync,
            resolveCurrentUrl,
        } = props;
        const currentRoute = resolveCurrentUrl();
        await removeNotification(notif._id);
        switch (notif.type) {
            case 'teleconsultation':
                if (currentRoute === 'teleconsultation') {
                    setTelecListAsync();
                }
                navigateTo('teleconsultation');

                break;
            case 'patients':
                if (currentRoute === 'patients') {
                    setPatientsListAsync();
                }
                navigateTo('patients');

                break;
            case 'teleexpertise':
                if (currentRoute === 'teleexpertise') {
                    setTelexListAsync();
                }
                navigateTo('teleexpertise');
                break;
            default: break;
        }
    };

    const mapNotifications = () => {
        const { notifications } = props;
        return notifications.map(notif => (
            <MenuItem
                onClick={() => { handleNotificationClick(notif); }}
                label={(
                    <Notification
                        title={notif.content} />
                )} />
        ));
    };

    const {
        onToogleSidebar, disconnectAsync, notifications,
        unseenMessages, deleteMessageNotifs,
    } = props;
    return (
        <div>
            {user ? (
                <header className="react-rainbow-admin_header rainbow-position_fixed rainbow-flex rainbow-align_center rainbow-p-horizontal_large rainbow-background-color_white header-v2">
                    <img src="/assets/images/katomi-k.png" alt="katomi logo" className="katomi-logo-v2" />
                    <section className="rainbow-flex rainbow-align_center react-rainbow-admin_header-actions">
                        <div className="notif-container">
                            <div className="rainbow-flex" style={{ position: 'relative' }}>
                                <p className="notification-indicator">
                                    {unseenMessages.length}
                                </p>
                                <ButtonIcon
                                    size="large"
                                    onClick={() => { navigateTo('/messages'); deleteMessageNotifs(unseenMessages); }}
                                    icon={<i className="fad fa-comments-alt notification-icon-v2" />}
                                />
                            </div>

                            <div className="rainbow-flex" style={{ position: 'relative' }}>
                                <p className="notification-indicator">{notifications.length}</p>
                                {' '}
                                <ButtonMenu
                                    className="rainbow-m-right_medium react-rainbow-admin_header-button-notification"
                                    menuAlignment="right"
                                    buttonVariant="base"
                                    buttonSize="large"
                                    icon={<i className="fad fa-bell notification-icon-v2" />}>
                                    <MenuItem label={`Notifications ${notifications.length}`} variant="header" />

                                    {mapNotifications()}
                                </ButtonMenu>
                            </div>
                        </div>
                        <AvatarMenu
                            src={profilePicture}
                            icon={<PersonIcon />}
                            assistiveText={`Docteur ${user.lastName} ${user.firstName}`}
                            title={`Docteur ${user.lastName} ${user.firstName}`}
                            menuAlignment="right"
                            menuSize="small">
                            <li className="rainbow-p-horizontal_small rainbow-align_center rainbow-flex">
                                <Avatar
                                    src={profilePicture}
                                    icon={<PersonIcon />}
                                    assistiveText={`Docteur ${user.lastName} ${user.firstName}`}
                                    title={`Docteur ${user.lastName} ${user.firstName}`}
                                    size="small" />
                                <div className="rainbow-m-left_x-small">
                                    <p className="rainbow-font-size-text_medium rainbow-color_dark-1">{`Docteur ${user.lastName} ${user.firstName}`}</p>
                                    <p className="rainbow-font-size-text_small rainbow-color_gray-3">{user.speciality}</p>
                                </div>
                            </li>
                            <MenuDivider variant="space" />
                            <MenuItem
                                label="Profil"
                                icon={<FontAwesomeIcon icon={faPencilAlt} />}
                                iconPosition="left"
                                onClick={() => { navigateTo('/profile'); }}
                            />
                            <MenuItem
                                label="Déconnexion"
                                icon={<FontAwesomeIcon icon={faPowerOff} />}
                                iconPosition="left"
                                onClick={() => { disconnectAsync(); }}
                            />
                        </AvatarMenu>
                    </section>
                    <ButtonIcon
                        onClick={onToogleSidebar}
                        className="react-rainbow-admin_header-hamburger-button"
                        size="large"
                        icon={<BarsIcon />} />
                </header>
            ) : null}
        </div>
    );
};


SectionHeading.propTypes = {
    setNotificationsAsync: PropTypes.func.isRequired,
    onToogleSidebar: PropTypes.func,
    disconnectAsync: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    notifications: PropTypes.array,
    unseenMessages: PropTypes.array.isRequired,
    deleteMessageNotifs: PropTypes.func.isRequired,
    setPatientsListAsync: PropTypes.func.isRequired,
    setTelecListAsync: PropTypes.func.isRequired,
    setTelexListAsync: PropTypes.func.isRequired,
    resolveCurrentUrl: PropTypes.func.isRequired,
};

SectionHeading.defaultProps = {
    onToogleSidebar: () => {},
    notifications: [],
};


const stateToProps = state => ({
    user: state.auth.user,
    notifications: state.notifications.notifications,
    unseenMessages: state.notifications.unseenMessages,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        disconnectAsync,
        setNotificationsAsync,
        deleteMessageNotifs,
        setPatientsListAsync,
        setTelecListAsync,
        setTelexListAsync,
    },
    dispatch,
);


export default connect(stateToProps, dispatchToProps)(SectionHeading);

/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import {
    Input,
    Tabset,
    Tab,
    Button,
    Breadcrumbs,
    Breadcrumb,
    // Card,
} from 'react-rainbow-components/components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Peer from 'peerjs';
import { ArrowUp } from '../../../components/icons';
import ChatMessages from '../../messages/chatMessages';
import SelectedContact from '../../messages/selectedContact';
import { postTelexMessageAsync } from '../../../redux/actions/teleexpertise';
import PatientAntecedentsTable from '../../../components/PatientAntecedents';
import { navigateTo } from '../../../history';
import PatientDocuments from '../../../components/PatientDocuments';
import { API_PHOTOS } from '../../../config';
import '../../messages/styles.css';

class ConsultTelex extends Component {
  state = {
      activeTab: 'medical',
      messageToSend: '',
      messages: [],
      selectedUser: null,
      globalActiveTab: 'discussion',
      peer: null,
      activeConnection: null,
  };

  componentDidMount() {
      this.connectToPeer();
  }

  componentDidUpdate(oldProps) {
      const { selectedTelex } = this.props;
      if (oldProps.selectedTelex !== selectedTelex && selectedTelex.patient && document) {
      // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ messages: selectedTelex.messages });
          this.setSelectedUser();
          this.scrollToBottom();
      }
  }

  componentWillUnmount() {
      const { peer } = this.state;
      if (peer) {
          peer.destroy();
      }
  }

  connectToPeer = () => {
      const { user } = this.props;

      const peer = new Peer(user._id, { host: 'p2p.katomi.co', secure: true });
      this.setState({ peer });
      peer.on('connection', (conn) => {
          this.setState({
              activeConnection: conn,
          });

          conn.on('data', (data) => {
              this.handleReceiveMessage(data);
          });

          conn.on('close', () => {
              this.setState({ activeConnection: null });
          });
      });
  };


  setSelectedUser = () => {
      const { user, selectedTelex } = this.props;
      let selectedUser;
      if (user._id === selectedTelex.sender._id) {
          selectedUser = selectedTelex.receiver;
      } else {
          selectedUser = selectedTelex.sender;
      }
      this.setState({ selectedUser });
  };

  handleSelectTab = (tab) => {
      this.setState({ activeTab: tab });
  };

  handleSelectGlobalTab = (tab) => {
      this.setState({ globalActiveTab: tab });
  };

  mapImages = (images, category) => {
      const { selectedTelex } = this.props;
      const patientId = selectedTelex.patient._id;
      const result = [];
      images.map(fileName => result.push({
          original: `${API_PHOTOS}/load/${patientId}/${category}/lossless/`.concat(
              fileName,
          ),
          thumbnail: `${API_PHOTOS}/load/${patientId}/${category}/thumbnail/`.concat(
              fileName,
          ),
      }));
      return result;
  };

  // eslint-disable-next-line consistent-return
  handleTabs = () => {
      const { activeTab } = this.state;
      const { selectedTelex } = this.props;
      if (selectedTelex.patient) {
          const {
              antecedents, bio, radio, autres,
          } = selectedTelex.patient;
          switch (activeTab) {
              case 'medical':
                  return (
                      <PatientAntecedentsTable
                          withAddOption={false}
                          tableData={antecedents.medical}
                      />
                  );
              case 'surgical':
                  return (
                      <PatientAntecedentsTable
                          withAddOption={false}
                          tableData={antecedents.surgical}
                      />
                  );
              case 'treatments':
                  return (
                      <PatientAntecedentsTable
                          withAddOption={false}
                          tableData={antecedents.treatments}
                      />
                  );
              case 'bio':
                  return (
                      <PatientDocuments
                          allowEdits={false}
                          category="bio"
                          images={this.mapImages(bio, 'bio')}
                      />
                  );
              case 'radio':
                  return (
                      <PatientDocuments
                          allowEdits={false}
                          category="radio"
                          images={this.mapImages(radio, 'radio')}
                      />
                  );
              case 'autres':
                  return (
                      <PatientDocuments
                          allowEdits={false}
                          category="autres"
                          images={this.mapImages(autres, 'autres')}
                      />
                  );

              default:
                  return null;
          }
      }
  };

  handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
  };

  handleGlobalTabs = () => {
      const {
          globalActiveTab,
          messageToSend,
          activeTab,
          messages,
          selectedUser,
      } = this.state;
      const { selectedTelex } = this.props;
      if (globalActiveTab === 'medrec') {
          return (
              <div style={{ maxWidth: '80%' }}>
                  <div className="react-rainbow-admin-messages_contacts">
                      <Tabset
                          id="tabset-3"
                          onSelect={(ev, tab) => {
                              this.handleSelectTab(tab);
                          }}
                          activeTabName={activeTab}
                          className="rainbow-background-color_gray-1 rainbow-p-horizontal_x-large rainbow-m-bottom_large"
                      >
                          <Tab
                              label="Antécédents Médicaux"
                              name="medical"
                              id="Antécédents Médicaux"
                              ariaControls="Antécédents Médicaux"
                          />
                          <Tab
                              label="Antécédents Chirurgicaux"
                              name="surgical"
                              id="Antécédents Chirurgicaux"
                              ariaControls="Antécédents Chirurgicaux"
                          />
                          <Tab
                              label="Traitements en cours"
                              name="treatments"
                              id="Traitements en cours"
                              ariaControls="Traitements en cours"
                          />
                          {selectedTelex.constraints.bio ? (
                              <Tab
                                  label="Examens Biologiques"
                                  name="bio"
                                  id="Examens Biologiques"
                                  ariaControls="Examens Biologiques"
                              />
                          ) : null}
                          {selectedTelex.constraints.radio ? (
                              <Tab
                                  label="Examens Radiologiques"
                                  name="radio"
                                  id="Examens Radiologiques"
                                  ariaControls="Examens Radiologiques"
                              />
                          ) : null}
                          {selectedTelex.constraints.autres ? (
                              <Tab
                                  label="Autres Documents"
                                  name="autres"
                                  id="Autres Documents"
                                  ariaControls="Autres Documents"
                              />
                          ) : null}
                      </Tabset>

                      {this.handleTabs()}
                  </div>
              </div>
          );
      }
      return (
          <div className="react-rainbow-admin-messages_body">
              <SelectedContact selectedUser={selectedUser} />
              <span className="react-rainbow-admin-messages_body--divider" />
              <div className="react-rainbow-admin-messages_body-messages" id="chat">
                  <ChatMessages messages={messages} injectedStyle={{ width: '70vw' }} />
              </div>
              <div className="react-rainbow-admin-messages_input-container">
                  <form
                      onSubmit={(e) => {
                          this.handleSubmitMessage(e);
                      }}
                  >
                      <Input
                          className="react-rainbow-admin-messages_input"
                          label="Tapez votre message"
                          hideLabel
                          value={messageToSend}
                          name="messageToSend"
                          onChange={this.handleChange}
                          placeholder="Tapez votre message"
                          icon={<ArrowUp />}
                          iconPosition="right"
                      />
                      <Button
                          type="submit"
                          icon={<ArrowUp />}
                          style={{ marginTop: '1rem', width: '100%' }}
                          label="Envoyer"
                          variant="brand"
                          className="variant-brand"
                      />
                  </form>
              </div>
          </div>
      );
  };

handleReceiveMessage = (content) => {
    const { selectedUser, messages } = this.state;
    const body = {
        content,
        time: new Date().toLocaleString('fr'),
        sender: selectedUser._id,
    };
    const newMessages = [...messages];
    newMessages.push(body);
    this.scrollToBottom();
    this.setState({ messages: [...newMessages] });
}

scrollToBottom = () => {
    setTimeout(() => {
        if (document) {
            const element = document.getElementById('chat');
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
        }
    }, 500);
};

  handleSubmitMessage = (e) => {
      e.preventDefault();
      const {
          messageToSend, messages, activeConnection, selectedUser,
      } = this.state;
      const { postTelexMessageAsync, selectedTelex, user } = this.props;

      if (messageToSend.trim().length) {
          const body = {
              content: messageToSend,
              time: new Date().toLocaleString('fr'),
              sender: user._id,
          };
          this.peerSendData(selectedUser._id, messageToSend);
          body.isRemoteConnected = activeConnection !== null;
          postTelexMessageAsync(selectedTelex._id, body);
          const newMessages = messages;
          newMessages.push(body);
          this.scrollToBottom();
          this.setState({ messageToSend: '', messages: [...newMessages] });
      }
  };

  peerSendData = (remoteId, data) => {
      const { activeConnection, peer } = this.state;
      if (activeConnection) {
          // Sending the message to the existing connection
          activeConnection.send(data);
      } else {
          // connecting to peer
          const conn = peer.connect(remoteId);
          if (conn) {
              conn.on('open', () => {
                  // connection opened
                  this.setState({
                      activeConnection: conn,
                  });
                  // sending the message through the opened connection
                  conn.send(data);

                  // listening to data from this connection
                  conn.on('data', (data) => {
                      this.handleReceiveMessage(data);
                  });

                  conn.on('close', () => {
                      this.setState({ activeConnection: null });
                  });
              });
          }
      }
  };


  render() {
      const { selectedUser, globalActiveTab } = this.state;
      const { selectedTelex } = this.props;
      //   let birthDate; let size; let weight;
      //   if (selectedTelex) {
      //       birthDate = selectedTelex.patient.birthDate;
      //       size = selectedTelex.patient.size;
      //       weight = selectedTelex.patient.weight;
      //   }
      return (
          <div>
              {selectedTelex && selectedUser ? (
                  <div>
                      <div className="react-rainbow-admin-users_header-container">
                          <Breadcrumbs>
                              <Breadcrumb
                                  label="Téléexpertise"
                                  onClick={() => navigateTo('/teleexpertise')}
                              />
                              <Breadcrumb
                                  label={`${selectedTelex.patient.lastName} ${selectedTelex.patient.firstName}`}
                              />
                          </Breadcrumbs>
                      </div>
                      <div className="telex-medrec-container">
                          <Tabset
                              id="tabset-4"
                              onSelect={(ev, tab) => {
                                  this.handleSelectGlobalTab(tab);
                              }}
                              activeTabName={globalActiveTab}
                              className="global-telex-tabs"
                          >
                              <Tab
                                  label="Discussion avec le médecin"
                                  name="discussion"
                                  id="Discussion avec le médecin"
                                  ariaControls="Discussion avec le médecin"
                              />
                              <Tab
                                  label="Dossier médical du patient"
                                  name="medrec"
                                  id="Dossier médical du patient"
                                  ariaControls="Dossier médical du patient"
                              />

                          </Tabset>
                          {this.handleGlobalTabs()}
                      </div>
                  </div>
              ) : null}
          </div>
      );
  }
}

ConsultTelex.propTypes = {
    postTelexMessageAsync: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    selectedTelex: PropTypes.object,
};

ConsultTelex.defaultProps = {
    selectedTelex: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    selectedTelex: state.teleexpertise.selectedTelex,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        postTelexMessageAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(ConsultTelex);

/*

 <Card title="Données Générales" style={{ padding: '2rem', margin: '2rem', justifyContent: 'center' }}>
                                  <UserField label="Date de naissance"
                                      value={birthDate || ''}
                                      className="react-rainbow-admin-user-detail_user-identity_field" />
                                  <UserField label="Taille"
                                      value={size || ''}
                                      className="react-rainbow-admin-user-detail_user-identity_field" />
                                  <UserField label="Poids"
                                      value={weight || ''}
                                      className="react-rainbow-admin-user-detail_user-identity_field" />
                              </Card>

                              */

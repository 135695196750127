/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import {
    SET_TELEEXPERTISE_LIST,
    SELECT_TELEEXPERTISE,
    ADD_TELEEXPERTISE,
    UPDATE_TELEEXPERTISE,
} from '../../actions/teleexpertise';

const initialState = {
    teleexpertiseList: [],
    selectedTelex: null,
};

const teleexpertise = (state = initialState, action) => {
    switch (action.type) {
        case SET_TELEEXPERTISE_LIST:
            return { ...state, teleexpertiseList: action.payload };
        case SELECT_TELEEXPERTISE:
            return { ...state, selectedTelex: action.payload };
        case ADD_TELEEXPERTISE:
            return {
                ...state,
                teleexpertiseList: [action.payload, ...state.teleexpertiseList],
            };
        case UPDATE_TELEEXPERTISE: {
            const teleexpertiseList = [];
            for (const telex of state.teleexpertise) {
                if (telex._id === action.payload._id) {
                    teleexpertiseList.push(action.payload);
                } else teleexpertiseList.push(telex);
            }
            return {
                ...state,
                teleexpertiseList,
            };
        }

        default:
            return state;
    }
};

export default teleexpertise;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
} from 'react-rainbow-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectPatientAsync } from '../../../redux/actions/patients';
import { setConsultationsByPatientListAsync } from '../../../redux/actions/consultations';
import { navigateTo } from '../../../history';
import './styles.css';

const ConsultPatient = (props) => {
    const {
        value, handleDeleteDialog, selectPatientAsync,
        setConsultationsByPatientListAsync,
    } = props;
    const { _id, fullName } = value;

    const handleHistoryClick = () => {
        selectPatientAsync(_id);
        setConsultationsByPatientListAsync(_id);
        navigateTo(`/patients/${_id}/consultations`);
    };

    const lineHeight = window.innerWidth > 1505 ? '2rem' : '1rem';
    // const disableButtons = user && !user.stripe;
    const consultButtonLabel = 'Consulter et Modifier';

    const handleSelectPatient = () => {
        selectPatientAsync(_id);
        navigateTo(`/patients/${_id}`);
    };

    return (
        <div className="rainbow-flex rainbow-flex_wrap">
            <Button label={consultButtonLabel} variant="brand" className="button-action variant-brand" onClick={() => handleSelectPatient(_id)} />
            <Button label="Historique des consultations" variant="success" className="button-action variant-success" style={{ lineHeight }} onClick={() => { handleHistoryClick(); }} />
            <Button label="Supprimer" variant="destructive" className="button-action variant-destructive" onClick={() => { handleDeleteDialog({ _id, fullName }); }} />
        </div>


    );
};

ConsultPatient.propTypes = {
    // user: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
    handleDeleteDialog: PropTypes.func.isRequired,
    selectPatientAsync: PropTypes.func.isRequired,
    setConsultationsByPatientListAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators({
    selectPatientAsync,
    setConsultationsByPatientListAsync,
}, dispatch);

export default connect(stateToProps, dispatchToProps)(ConsultPatient);

/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Avatar } from 'react-rainbow-components';
import { connect } from 'react-redux';
import { PersonIcon } from '../../components/icons';
import SentText from './sentText';
import { API_PHOTOS } from '../../config';

const getClassNames = isUser => classnames('react-rainbow-admin-messages_message', {
    'rainbow-flex_row-reverse': isUser,
});

const getTextClassNames = isUser => classnames({
    'react-rainbow-admin-messages_message-text-contact': !isUser,
    'react-rainbow-admin-messages_message-text-user': isUser,
});

const ChatMessages = ({ messages, user, injectedStyle }) => messages.map((message, index) => {
    const {
        content,
        time,
        seen,
    } = message;
    const photoUrl = `${API_PHOTOS}/load/${message.sender}/profile/thumbnail/profile-picture.webp`;
    const isUser = message.sender === user._id;
    const key = `msg-${index}`;

    return (
        <div key={key} className={getClassNames(isUser)} id={key} style={injectedStyle}>
            <Avatar
                className="react-rainbow-admin-messages_message-avatar"
                src={photoUrl}
                icon={<PersonIcon />}
                size="medium" />
            <SentText isUser={isUser} date={time} isChecked={seen} />
            <p className={getTextClassNames(isUser)}>{content}</p>
        </div>
    );
});

ChatMessages.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string,
        time: PropTypes.string,
        sender: PropTypes.string,
    })),
    injectedStyle: PropTypes.object,
};

ChatMessages.defaultProps = {
    messages: [],
    injectedStyle: {},
};

const stateToProps = state => ({
    user: state.auth.user,
});

export default connect(stateToProps, null)(ChatMessages);

import axios from 'axios';
import { API_TELEEXPERTISE } from '../config';

const getByUser = async () => {
    const res = await axios.get(`${API_TELEEXPERTISE}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_TELEEXPERTISE}/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const create = async (body) => {
    const res = await axios.post(`${API_TELEEXPERTISE}`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const postMessage = async (id, body) => {
    const res = await axios.post(`${API_TELEEXPERTISE}/${id}`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

export {
    getByUser, getById, create, postMessage,
};

/* eslint-disable no-underscore-dangle */
import * as CPurposesAPI from '../../../api/cPurposes';
import { setContentIsLoading, setDialogIsLoading } from '../loading';
import { sessionExpired } from '../auth';
import { setSnackbar } from '../snackbar';

export const SET_CPURPOSES_LIST = 'SET_CPURPOSES_LIST';
export const ADD_CPURPOSE = 'ADD_CPURPOSE';
export const UPDATE_CPURPOSE = 'UPDATE_CPURPOSE';
export const REMOVE_CPURPOSE = 'REMOVE_CPURPOSE';
export const SELECT_CPURPOSE = 'SELECT_CPURPOSE';

const setCPurposesList = value => ({
    type: SET_CPURPOSES_LIST,
    payload: value,
});

const setCPurposesListAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await CPurposesAPI.getAll();
        dispatch(setCPurposesList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const addCPurpose = cPurpose => ({
    type: ADD_CPURPOSE,
    payload: cPurpose,
});

const addCPurposeAsync = cPurpose => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await CPurposesAPI.create(cPurpose);
        dispatch(addCPurpose(res.data));
        dispatch(setSnackbar('Motif ajouté avec succès', 'success'));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const updateCPurpose = cPurpose => ({
    type: UPDATE_CPURPOSE,
    payload: cPurpose,
});

const updateCPurposeAsync = cPurpose => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await CPurposesAPI.update(cPurpose._id, cPurpose);
        dispatch(updateCPurpose(res.data));
        dispatch(setSnackbar('Motif modifié avec succès', 'success'));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const removeCPurpose = id => ({
    type: REMOVE_CPURPOSE,
    payload: id,
});

const removeCPurposeAsync = id => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await CPurposesAPI.remove(id);
        dispatch(removeCPurpose(res.data._id));
        dispatch(setSnackbar('Motif supprimé avec succès', 'success'));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const selectCPurpose = value => ({
    type: SELECT_CPURPOSE,
    payload: value,
});

const selectCPurposeAsync = id => async (dispatch) => {
    dispatch(setDialogIsLoading(true));
    try {
        const res = await CPurposesAPI.getById(id);
        dispatch(selectCPurpose(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setDialogIsLoading(false));
};

export {
    setCPurposesList,
    setCPurposesListAsync,
    addCPurpose,
    addCPurposeAsync,
    updateCPurpose,
    updateCPurposeAsync,
    removeCPurpose,
    removeCPurposeAsync,
    selectCPurpose,
    selectCPurposeAsync,
};

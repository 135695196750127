/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
    ProgressIndicator,
    ProgressStep,
    Button,
    Modal,
    Input,
    Textarea,
    Select,
    CheckboxGroup,
} from 'react-rainbow-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDoctorsAsync } from '../../../redux/actions/chat';
import { setPatientsListAsync } from '../../../redux/actions/patients';
import { createTelexAsync } from '../../../redux/actions/teleexpertise';
import Contacts from '../../messages/contacts';
import { navigateTo } from '../../../history';
import '../styles.css';
import { setSnackbar } from '../../../redux/actions/snackbar';

const stepNames = ['select-doctor', 'select-patient', 'add-note'];


class AddTeleexpertise extends React.Component {
      state = {
          currentStepIndex: 0,
          open: true,
          subject: '',
          content: '',
          doctor: null,
          patient: null,
          checkboxValues: [],
      };

      componentDidMount() {
          const { setDoctorsAsync, setPatientsListAsync } = this.props;
          setPatientsListAsync();
          setDoctorsAsync();
      }

      handleNext = (data) => {
          const { currentStepIndex } = this.state;
          const nextStep = currentStepIndex + 1;
          if (currentStepIndex === 0) {
              this.setState({ doctor: data, currentStepIndex: nextStep });
          } else {
              this.setState({ patient: data, currentStepIndex: nextStep });
          }
      }


      handleSubmit = () => {
          const {
              doctor, patient, subject,
              content,
              checkboxValues,
          } = this.state;
          const { createTelexAsync } = this.props;
          const constraints = {
              bio: false,
              radio: false,
              autres: false,
          };
          // eslint-disable-next-line no-return-assign
          checkboxValues.map(value => constraints[value] = true);
          const body = {
              receiver: doctor._id,
              patient,
              subject,
              message: {
                  content,
                  time: new Date().toLocaleString('fr'),
              },
              constraints,
          };
          createTelexAsync(body);
          this.handleClose();
      }

    handleBackClick = () => {
        const { currentStepIndex } = this.state;
        const previewStepIndex = currentStepIndex - 1;
        switch (currentStepIndex) {
            case 1: this.setState({
                currentStepIndex: previewStepIndex,
                doctor: null,
                checkboxValues: [],
            }); break;
            case 2: this.setState({
                currentStepIndex: previewStepIndex,
                patient: null,
                checkboxValues: [],
            }); break;
            case 3: this.setState({ currentStepIndex: previewStepIndex, subject: '', content: '' }); break;
            default: break;
        }
    }

    handleClose = () => {
        navigateTo('/teleexpertise');
    }

    handlNextClick = () => {
        const {
            currentStepIndex, patient, content, subject,
        } = this.state;
        const { setSnackbar } = this.props;
        if (currentStepIndex === 1) {
            if (patient && patient !== '') {
                const nextStep = currentStepIndex + 1;
                this.setState({ currentStepIndex: nextStep });
            } else {
                setSnackbar('Veuillez selectionner un patient', 'error');
            }
        } else if (content.trim().length > 0 && subject.trim().length > 0) {
            this.handleSubmit();
        } else {
            setSnackbar("Veuillez remplir l'objet et le contenu", 'error');
        }
    }

    isBackDisabled = () => {
        const { currentStepIndex } = this.state;
        if (currentStepIndex > 0 && currentStepIndex < stepNames.length) {
            return false;
        }
        return true;
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    switchProgress = () => {
        const { patients, doctors } = this.props;
        const { checkboxValues } = this.state;
        const checkboxOptions = [
            { value: 'bio', label: 'Partager les examens biologiques' },
            { value: 'radio', label: 'Partager les examens radiologiques' },
            { value: 'autres', label: "Partager les documents de catégorie 'autres documents'" },
        ];

        const selectOptions = [{ label: '', value: '' }];
        patients.map((patient) => {
            const fullName = `${patient.lastName} ${patient.firstName}`;
            return selectOptions.push({ label: fullName, value: patient._id });
        });
        const {
            currentStepIndex, subject, content, patient,
        } = this.state;
        switch (currentStepIndex) {
            case 0:
                return (
                    <div className="contacts-container">
                        {' '}
                        <Contacts
                            doctors={doctors}
                            showheaderLabel={false}
                            onClick={this.handleNext}
                        />
                        {' '}
                    </div>
                );
            case 1:
                return (
                    <div style={{ flex: 1 }}>
                        <Select
                            label="Séléctionner un patient"
                            name="patient"
                            options={selectOptions}
                            value={patient}
                            onChange={this.handleChange}
                        />
                        <CheckboxGroup
                            label="Partager des documents"
                            className="margify-checkbox"
                            options={checkboxOptions}
                            value={checkboxValues}
                            onChange={(e) => { this.setState({ checkboxValues: e }); }}
                        />
                    </div>
                );
            case 2:
                return (

                    <form className="telex-add-form">
                        {' '}
                        <Input required label="Motif" value={subject} name="subject" onChange={(e) => { this.handleChange(e); }} />
                        {' '}
                        <Textarea required label="Commentaire" value={content} rows={4} name="content" onChange={(e) => { this.handleChange(e); }} />
                        {' '}
                    </form>
                );
            default: return null;
        }
    }

    render() {
        const { currentStepIndex, open } = this.state;
        let buttonLabel = 'Suivant';
        if (currentStepIndex === 2) {
            buttonLabel = 'Envoyer';
        }
        const nextStepDisabled = currentStepIndex === 0;
        return (

            <div className="rainbow-m-bottom_large rainbow-p-bottom_large">
                <Modal
                    isOpen={open}
                    size="medium"
                    className="padding-modal"
                    onRequestClose={this.handleClose}
                    title="Demande d'un avis Professionnel"
                >
                    <ProgressIndicator currentStepName={stepNames[currentStepIndex]}>
                        <ProgressStep name="select-doctor" label="Séléctionner un médecin" />
                        <ProgressStep name="select-patient" label="Séléctionner un patient" />
                        <ProgressStep name="add-note" label="Ajouter une note" />
                    </ProgressIndicator>
                    <div className="add-telex-container">
                        {this.switchProgress()}
                    </div>
                    <div className="rainbow-m-top_xx-large rainbow-align-content_center rainbow-flex_wrap">
                        <Button
                            label="Retour"
                            onClick={this.handleBackClick}
                            variant="brand"
                            disabled={this.isBackDisabled()}
                            className="rainbow-m-horizontal_medium variant-brand"
                        />
                        <Button
                            label={buttonLabel}
                            onClick={this.handlNextClick}
                            variant="brand"
                            disabled={nextStepDisabled}
                            className="rainbow-m-horizontal_medium variant-brand"
                        />

                    </div>
                </Modal>

            </div>
        );
    }
}

AddTeleexpertise.propTypes = {
    setDoctorsAsync: PropTypes.func.isRequired,
    setPatientsListAsync: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    createTelexAsync: PropTypes.func.isRequired,
    doctors: PropTypes.array,
    patients: PropTypes.array,
};

AddTeleexpertise.defaultProps = {
    doctors: [],
    patients: [],
};

const stateToProps = state => ({
    doctors: state.chat.doctors,
    patients: state.patients.patientsList,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setDoctorsAsync,
        setPatientsListAsync,
        setSnackbar,
        createTelexAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AddTeleexpertise);

/* eslint-disable no-underscore-dangle */
import { DateTime } from 'luxon';
import * as TeleconsultAPI from '../../../api/teleconsultation';
import { setSnackbar } from '../snackbar';
import {
    STARTED, ENDED, PAYMENT_PENDING, CHANGED, REJECTED,
} from '../../../config';
import { sessionExpired } from '../auth';
import { createNotification } from '../../../api/notifications';
import { parseAction } from './logsHelper';


export const SET_TELECONSULTATION_LIST = 'SET_TELECONSULTATION_LIST';
export const SELECT_TELECONSULTATION = 'SELECT_TELECONSULTATION';
export const ADD_TELECONSULTATION = 'ADD_TELECONSULTATION';
export const UPDATE_TELECONSULTATION = 'UPDATE_TELECONSULTATION';
export const DELETE_TELECONSULTATION = 'DELETE_TELECONSULTATION';

export const createTeleconsultation = value => ({
    type: ADD_TELECONSULTATION,
    payload: value,
});
export const selectTeleconsultation = value => ({
    type: SELECT_TELECONSULTATION,
    payload: value,
});
export const setTeleconsultationList = value => ({
    type: SET_TELECONSULTATION_LIST,
    payload: value,
});
export const updateTeleconsultation = value => ({
    type: UPDATE_TELECONSULTATION,
    payload: value,
});

export const deleteTeleconsultation = value => ({
    type: DELETE_TELECONSULTATION,
    payload: value,
});


export const createTelecAsync = data => async (dispatch) => {
    try {
        const response = await TeleconsultAPI.createTeleconsultation(data);
        if (response.data) {
            dispatch(createTeleconsultation(response.data));
            dispatch(setSnackbar('teleconsultation planifiée avec succès', 'success'));
            const patientName = `${response.data.patient.lastName} ${response.data.patient.firstName}`;
            const notifContent = `${patientName} vous invite à une session de téléconsultation`;
            const notification = {
                receiver: data.doctor,
                content: notifContent,
                type: 'teleconsultation',
            };
            createNotification(notification);
        }
    } catch (err) {
        if (!sessionExpired(dispatch, err) && err) {
            dispatch(setSnackbar("Une erreur s'est produite !", 'error'));
        }
    }
};

export const setTelecListAsync = () => async (dispatch) => {
    try {
        const response = await TeleconsultAPI.getTeleconsultations();
        if (response.data) {
            dispatch(setTeleconsultationList(response.data));
        }
    } catch (err) {
        if (!sessionExpired(dispatch, err)) {
            dispatch(setSnackbar("Une erreur s'est produite !", 'error'));
        }
    }
};

export const updateTelecAsync = (id, data) => async (dispatch) => {
    try {
        const response = await TeleconsultAPI.update(id, data);
        if (response.data) {
            if (data.status !== STARTED && data.status !== ENDED) {
                dispatch(updateTeleconsultation(response.data));
                dispatch(setSnackbar('Modification effectué avec succès', 'success'));
                let content;
                switch (data.status) {
                    case PAYMENT_PENDING:
                        content = `Docteur ${data.doctorFullName} vous confirme sa participation à la session de téléconsultation que vous avez prévu`; break;
                    case REJECTED:
                        content = `Docteur ${data.doctorFullName} ne peut confirmer sa participation à la session de téléconsultation que vous avez prévu`; break;
                    case CHANGED:
                        content = `Docteur ${data.doctorFullName} vous propose une autre date pour la session de téléconsultation que vous avez prévu`; break;
                    default: content = ''; break;
                }

                const notifBody = {
                    receiver: data.patient,
                    type: 'teleconsultation',
                    content,
                };
                createNotification(notifBody);
            }
        }
    } catch (err) {
        if (!sessionExpired(dispatch, err)) {
            dispatch(setSnackbar("Une erreur s'est produite !", 'error'));
        }
    }
};

export const deleteTelecAsync = id => async (dispatch) => {
    try {
        const response = await TeleconsultAPI.remove(id);
        if (response.data) {
            const { isIncoming } = response.data;
            dispatch(deleteTeleconsultation({ _id: id, isIncoming }));
            dispatch(
                setSnackbar('Téléconsultation annulée avec succès', 'success'),
            );

            if (isIncoming) {
                const telecDateTS = new Date(response.data.date).getTime();
                const telecDate = DateTime.fromMillis(telecDateTS).toFormat(
                    "dd/MM/yyyy 'à' HH':'mm ",
                );
                const doctorName = `${response.data.doctor.lastName} ${response.data.doctor.firstName}`;
                const notifContent = `Docteur ${doctorName} a annulé la session de téléconsultation prévu le ${telecDate}`;
                const notification = {
                    receiver: response.data.patient._id,
                    content: notifContent,
                    type: 'teleconsultation',
                };
                createNotification(notification);
            }
        }
    } catch (err) {
        if (!sessionExpired(dispatch, err)) {
            dispatch(setSnackbar("Une erreur s'est produite !", 'error'));
        }
    }
};


export const postLog = async (id, actionType, actionData, doctorMeta) => {
    try {
        const log = {
            action: parseAction(actionType),
            actionData,
            logTime: Date.now(),
            userType: 'doctor',
        };
        const body = {
            log,
            doctorMeta,
        };
        await TeleconsultAPI.postLog(id, body);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(actionType, 'error occured on posting log');
    }
};

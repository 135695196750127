/* eslint-disable max-len */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { Button, Breadcrumbs, Breadcrumb } from 'react-rainbow-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Calendar from './calendar';
import AddUnavailability from './unavailability';
import AddConsultation from './add';
import AddTelecAv from './telecAvailability';
import {
    setConsultationsListAsync,
    updateConsultationAsync,
} from '../../redux/actions/consultations';
import { setCPurposesListAsync } from '../../redux/actions/cPurposes';
import { setPatientsListAsync } from '../../redux/actions/patients';
import { UNAVAILABILITY, AVAILABILITY, TELECONSULTATION } from '../../config';

const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome');

// eslint-disable-next-line consistent-return
const prepareEventName = (event) => {
    switch (event.consultationType) {
        case AVAILABILITY:
            if (event.patient) {
                return `${event.patient.lastName} ${event.patient.firstName}`;
            }
            return 'non reservé';

        case UNAVAILABILITY:
            return 'indisponibilité';
        default:
            if (event.patient) {
                return `${event.patient.lastName} ${event.patient.firstName}`;
            }
    }
};

class Agenda extends React.Component {
  state = {
      openUnavailability: false,
      openConsultation: false,
      openTelecAv: false,
      selectedEvent: null,
      consultationsForAgenda: [],
      consultationTimes: {},
  };

  componentDidMount() {
      const {
          setConsultationsListAsync,
          setPatientsListAsync,
          setCPurposesListAsync,
      } = this.props;
      setConsultationsListAsync();
      setPatientsListAsync();
      setCPurposesListAsync();
      if (window) {
      // eslint-disable-next-line react/no-string-refs
          this.refs.calendarEl.style.height = `${window.innerHeight * 0.75}px`;
      }
  }

  componentDidUpdate(oldProps) {
      const { consultations } = this.props;
      if (oldProps.consultations !== consultations) {
          this.prepareConsultations(consultations);
      }
  }

  moveConsultation = (id, date) => {
      const { updateConsultationAsync } = this.props;
      updateConsultationAsync({ _id: id, date });
  };

  prepareConsultations = (consultations) => {
      const results = [];
      const newConsultationTimes = {};
      for (const consultation of consultations) {
          const start = DateTime.fromISO(consultation.date, {
              locale: 'fr',
          });
          const consultName = prepareEventName(consultation);
          newConsultationTimes[start.ts] = {
              name: consultName,
              type: consultation.consultationType,
          };
          const end = consultation.dateEnd
              ? DateTime.fromISO(consultation.dateEnd, {
                  locale: 'fr',
              })
              : start.plus({ minutes: 60 });
          const result = {
              id: consultation._id,
              title: consultName,
              type: consultation.consultationType,
              start: start.toJSDate(),
              end: end.toJSDate(),
              patient: consultation.patient ? consultation.patient._id : null,
              consultationPurpose: consultation.consultationPurpose,
          };

          results.push(result);
      }
      this.setState({
          consultationTimes: newConsultationTimes,
          consultationsForAgenda: results,
      });
  };

  handleSelectEvent = (event) => {
      this.setState({ selectedEvent: event });
      switch (event.type) {
          case UNAVAILABILITY:
              this.handleAddUnavailability();
              break;
          case AVAILABILITY:
          case TELECONSULTATION:
              this.handleAddTelecAv();
              break;
          default:
              this.handleAddEvent();
              break;
      }
  };

  handleAddUnavailability = () => {
      const { openUnavailability } = this.state;
      if (openUnavailability) {
          this.setState({ selectedEvent: null });
      }
      this.setState({
          openUnavailability: !openUnavailability,
      });
  };

  handleAddTelecAv = () => {
      const { openTelecAv } = this.state;
      if (openTelecAv) {
          this.setState({ selectedEvent: null });
      }
      this.setState({
          openTelecAv: !openTelecAv,
      });
  };

  handleAddEvent = () => {
      const { openConsultation } = this.state;
      if (openConsultation) {
          this.setState({ selectedEvent: null });
      }
      this.setState({ openConsultation: !openConsultation });
  };

  render() {
      const {
          openUnavailability,
          openConsultation,
          selectedEvent,
          consultationsForAgenda,
          consultationTimes,
          openTelecAv,
      } = this.state;
      return (
          <div>
              <div className="react-rainbow-admin-users_header-container">
                  <Breadcrumbs>
                      <Breadcrumb label="Agenda" className="is-jura" />
                  </Breadcrumbs>
              </div>
              <div className="agenda-container">
                  <div className="agenda-buttons-container">
                      <Button
                          className="agenda-add-button section-add-button"
                          variant="brand"
                          onClick={this.handleAddEvent}
                      >
                          <FontAwesomeIcon icon={faPlus} className="rainbow-m-right_medium" />
            Ajouter un événement
                      </Button>
                      <Button
                          className="agenda-add-button section-add-button"
                          onClick={this.handleAddUnavailability}
                      >
                          <FontAwesomeIcon icon={faPlus} className="rainbow-m-right_medium" />
            Ajouter une indisponibilité
                      </Button>
                      <Button
                          className="agenda-add-button section-add-button"
                          variant="success"
                          onClick={this.handleAddTelecAv}
                      >
                          <FontAwesomeIcon icon={faPlus} className="rainbow-m-right_medium" />
            Ajouter une téléconsultation
                      </Button>
                  </div>

                  <div ref="calendarEl">
                      <Calendar
                          onEventDrop={data => this.moveConsultation(data.event.id, data.start.toISOString())
                          }
                          events={consultationsForAgenda}
                          onSelectEvent={event => this.handleSelectEvent(event)}
                      />
                  </div>

                  <AddUnavailability
                      open={openUnavailability}
                      consultation={selectedEvent}
                      handleClose={this.handleAddUnavailability}
                  />
                  <AddConsultation
                      consultationTimes={consultationTimes}
                      open={openConsultation}
                      consultation={selectedEvent}
                      handleClose={this.handleAddEvent}
                  />
                  <AddTelecAv
                      consultationTimes={consultationTimes}
                      open={openTelecAv}
                      consultation={selectedEvent}
                      handleClose={this.handleAddTelecAv}
                  />
              </div>
          </div>

      );
  }
}

Agenda.propTypes = {
    setConsultationsListAsync: PropTypes.func.isRequired,
    setPatientsListAsync: PropTypes.func.isRequired,
    setCPurposesListAsync: PropTypes.func.isRequired,
    updateConsultationAsync: PropTypes.func.isRequired,
    consultations: PropTypes.array,
};

Agenda.defaultProps = {
    consultations: [],
};

const mapStateToProps = state => ({
    consultations: state.consultations.consultationsList,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setConsultationsListAsync,
        setPatientsListAsync,
        setCPurposesListAsync,
        updateConsultationAsync,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Agenda);

/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-rainbow-components/components/Avatar';
import { PersonIcon } from '../../components/icons';
import { API_PHOTOS } from '../../config';

const SelectedContact = (props) => {
    const {
        selectedUser, style,
    } = props;
    let name = '';
    let photoUrl = '';
    let speciality = 'Patient';
    if (selectedUser) {
        name = `${selectedUser.lastName} ${selectedUser.firstName}`;
        photoUrl = `${API_PHOTOS}/load/${selectedUser._id}/profile/thumbnail/profile-picture.webp`;
        speciality = selectedUser.speciality ? selectedUser.speciality : speciality;
    }

    return (
        <article className="react-rainbow-admin-messages_body-selected-contact" style={{ ...style }}>
            <Avatar
                src={photoUrl}
                icon={<PersonIcon />}
                size="small" />
            <div className="react-rainbow-admin-messages_body-selected-contact-content">
                <h3 className="react-rainbow-admin-messages_body-selected-contact-name">{name}</h3>
                <p className="react-rainbow-admin-messages_body-selected-contact--status">{speciality}</p>
            </div>
        </article>
    );
};

SelectedContact.propTypes = {
    selectedUser: PropTypes.object,
    style: PropTypes.object,
};

SelectedContact.defaultProps = {
    selectedUser: null,
    style: {},
};


export default SelectedContact;

/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
    Modal, Button, Select, DateTimePicker,
} from 'react-rainbow-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { CONSULTATION, OPERATION } from '../../config';
import { setSnackbar } from '../../redux/actions/snackbar';
import { addConsultationAsync, updateConsultationAsync, removeConsultationAsync } from '../../redux/actions/consultations';
import ExistingConsultAlert from './ExistingConsultAlert';

class AddConsultation extends React.Component {
    state = {
        modalTitle: 'Ajouter un événement',
        type: 'add',
        consultationType: '',
        endDate: Date.now(),
        startDate: Date.now(),
        alertForExistingConsultIsOpen: false,
    }

    componentDidUpdate(props) {
        const { consultation, open } = this.props;
        if (props.open !== open && open) {
            if (consultation) {
                const {
                    start, end, type, id, ...rest
                } = consultation;
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    startDate: start,
                    endDate: end,
                    consultationType: type,
                    _id: id,
                    ...rest,
                    modalTitle: 'Modifier un événement',
                    type: 'edit',
                });
            }
        }
    }

    prepareConsultationTypes = () => {
        const result = [{ label: '', value: '' }];
        [CONSULTATION, OPERATION].map(
            item => result.push({ label: item, value: item }),
        );
        return result;
    }

    prepareCPurpose = () => {
        const { cPurposes } = this.props;
        const result = [{ label: '', value: '' }];
        if (cPurposes) {
            cPurposes.map(item => result.push({ label: item.label, value: item._id }));
        }

        return result;
    }

    preparePatients = () => {
        const { patients } = this.props;
        const result = [{ label: '', value: '' }];
        if (patients) {
            patients.map(patient => result.push(
                { label: `${patient.lastName} ${patient.firstName}`, value: patient._id },
            ));
        }

        return result;
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleDateChange = (type, value) => {
        this.setState({ [type]: value });
    }

    validateInputs = () => {
        const {
            startDate, patient, consultationType, endDate,
        } = this.state;
        const { setSnackbar } = this.props;
        if (!startDate) {
            setSnackbar('Veuillez Choisir la date de début', 'error');
            return false;
        }
        if (!patient || patient === '') {
            setSnackbar('Veuillez Selectionner un patient', 'error');
            return false;
        }

        if (consultationType === '') {
            setSnackbar('Veuillez Choisir la date de début', 'error');
            return false;
        }

        if (endDate) {
            if (startDate > endDate && consultationType === OPERATION) {
                setSnackbar('Date de début et Date de fin incompatibles', 'error');
                return false;
            }
        }
        return true;
    }

 showAlertForExistingConsult = (alertContent) => {
     const { alertForExistingConsultIsOpen } = this.state;
     if (alertContent) {
         this.setState({ alertForExistingConsultIsOpen: !alertForExistingConsultIsOpen, alertContent: alertContent.name });
     } else {
         this.setState({ alertForExistingConsultIsOpen: !alertForExistingConsultIsOpen });
     }
 }

    handleSubmit = () => {
        const {
            type, startDate, startTime, endDate, endTime, alertForExistingConsultIsOpen, ...rest
        } = this.state;

        const { addConsultationAsync, consultationTimes, updateConsultationAsync } = this.props;
        if (this.validateInputs()) {
            if (consultationTimes[startDate] !== undefined && !alertForExistingConsultIsOpen) {
                this.showAlertForExistingConsult(consultationTimes[startDate]);
            } else {
                const isStartDateObject = typeof (startDate) === 'object';
                const isEndDateObject = typeof (endDate) === 'object';
                const dateTime = DateTime.fromMillis(isStartDateObject ? startDate.getTime() : startDate);
                const body = { date: dateTime, ...rest };
                if (endDate) {
                    const dateEnd = DateTime.fromMillis(isEndDateObject ? endDate.getTime() : endDate);
                    body.dateEnd = dateEnd;
                }
                if (type === 'add') {
                    if (body.consultationType !== OPERATION) {
                        delete body.dateEnd;
                    }
                    delete body._id;
                    addConsultationAsync(body);
                    this.closeModal();
                } else {
                    if (body.consultationType !== OPERATION) {
                        delete body.dateEnd;
                    }
                    updateConsultationAsync(body);
                    this.closeModal();
                }
                if (alertForExistingConsultIsOpen) {
                    this.showAlertForExistingConsult();
                }
            }
        }
    }


    closeModal = () => {
        const { handleClose } = this.props;
        this.setState({
            modalTitle: 'Ajouter un événement',
            type: 'add',
            consultationType: '',
            patient: null,
            consultationPurpose: null,
            endDate: null,
            endTime: null,
            startDate: null,
            startTime: null,
        });
        handleClose();
    }

    handleDeleteEvent = () => {
        const { _id } = this.state;
        const { removeConsultationAsync } = this.props;
        removeConsultationAsync(_id);
        this.closeModal();
    };


    render() {
        const { open } = this.props;
        const consultationsTypesOptions = this.prepareConsultationTypes();
        const cPurposesOptions = this.prepareCPurpose();

        const patientsOptions = this.preparePatients();

        const {
            modalTitle, consultationType, endDate, startDate,
            patient, consultationPurpose, type, alertForExistingConsultIsOpen,
            alertContent,
        } = this.state;
        const modalDisplay = alertForExistingConsultIsOpen ? 'none' : null;
        return (
            <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">

                <Modal
                    style={{ display: modalDisplay }}
                    isOpen={open}
                    onRequestClose={this.closeModal}
                    title={modalTitle}
                    footer={(
                        <div className="rainbow-flex rainbow-justify_end">
                            {type === 'edit' ? (
                                <Button
                                    className="rainbow-m-right_large variant-destructive"
                                    label="Supprimer"
                                    variant="destructive"
                                    onClick={this.handleDeleteEvent}
                                />
                            ) : null}

                            <Button label="Enregistrer" className="variant-brand" variant="brand" onClick={() => { this.handleSubmit(); }} />
                        </div>
                    )}
                >

                    <Select
                        label="Nom du Patient"
                        required
                        name="patient"
                        value={patient}
                        onChange={(e) => { this.handleChange(e); }}
                        options={patientsOptions}
                        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                    />

                    <Select
                        label="Motif de Consultation"
                        requiredDébut
                        name="consultationPurpose"
                        onChange={(e) => { this.handleChange(e); }}
                        value={consultationPurpose}
                        options={cPurposesOptions}
                        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                    />
                    <Select
                        label="Type de Consultation"
                        required
                        name="consultationType"
                        value={consultationType}
                        onChange={(e) => { this.handleChange(e); }}
                        options={consultationsTypesOptions}
                        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                    />

                    <div className="rainbow-flex  rainbow-justify rainbow-p-horizontal_small">
                        <DateTimePicker
                            locale="fr"
                            label="Date et heure de début"
                            onChange={(value) => { this.handleDateChange('startDate', value.getTime()); }}
                            value={startDate ? new Date(startDate) : null}
                        />
                    </div>
                    {consultationType === OPERATION ? (
                        <div className="rainbow-flex  rainbow-justify rainbow-p-horizontal_small">
                            <DateTimePicker
                                locale="fr"
                                label="Date et heure de début"
                                onChange={(value) => { this.handleDateChange('endDate', value.getTime()); }}
                                value={endDate ? new Date(endDate) : null}
                            />
                        </div>
                    ) : null}

                </Modal>
                <ExistingConsultAlert
                    alertContent={alertContent}
                    open={alertForExistingConsultIsOpen}
                    handleCancel={this.showAlertForExistingConsult}
                    handleSubmit={this.handleSubmit}
                />
            </div>
        );
    }
}


AddConsultation.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    consultation: PropTypes.object,
    cPurposes: PropTypes.array,
    patients: PropTypes.array,
    addConsultationAsync: PropTypes.func.isRequired,
    updateConsultationAsync: PropTypes.func.isRequired,
    removeConsultationAsync: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    consultationTimes: PropTypes.array.isRequired,
};

AddConsultation.defaultProps = {
    consultation: null,
    cPurposes: null,
    patients: null,
};


const stateToProps = state => ({
    cPurposes: state.cPurposes.cPurposesList,
    patients: state.patients.patientsList,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        addConsultationAsync,
        updateConsultationAsync,
        removeConsultationAsync,
        setSnackbar,
    },
    dispatch,
);

export default connect(
    stateToProps,
    dispatchToProps,
)(AddConsultation);

/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import { DateTime } from 'luxon';
import {
    SET_TELECONSULTATION_LIST,
    SELECT_TELECONSULTATION,
    ADD_TELECONSULTATION,
    UPDATE_TELECONSULTATION,
    DELETE_TELECONSULTATION,
} from '../../actions/teleconsultation';
import { PAYED, STARTED, ENDED } from '../../../config';

const initialState = {
    incoming: [],
    old: [],
    startingToday: [],
    selectedTelec: null,
};

const teleconsultation = (state = initialState, action) => {
    switch (action.type) {
        case SET_TELECONSULTATION_LIST:
            return {
                ...state,
                incoming: action.payload.incoming,
                old: action.payload.old,
                startingToday: action.payload.startingToday,
            };
        case SELECT_TELECONSULTATION:
            return { ...state, selectedTelec: action.payload };
        case ADD_TELECONSULTATION:
            return {
                ...state,
                incoming: [action.payload, ...state.incoming],
            };
        case UPDATE_TELECONSULTATION: {
            const incoming = [];
            const jsDate = new Date(action.payload.date);
            const telecsDate = DateTime.fromMillis(jsDate.getTime());
            const dayDiff = telecsDate.diffNow('day').values.days;
            const _canStartTelec = status => status === PAYED || status === STARTED || status === ENDED;
            const telecState = _canStartTelec(action.payload.status);

            if (Math.abs(dayDiff) <= 1 && telecState) {
                return {
                    ...state,
                    startingToday: [action.payload, ...state.startingToday],
                };
            }
            for (const telec of state.incoming) {
                if (telec._id === action.payload._id) {
                    incoming.push(action.payload);
                } else incoming.push(telec);
            }

            return {
                ...state,
                incoming,
            };
        }
        case DELETE_TELECONSULTATION: {
            if (action.payload.isIncoming) {
                const incoming = [];
                for (const telec of state.incoming) {
                    if (telec._id !== action.payload._id) {
                        incoming.push(telec);
                    }
                }
                return {
                    ...state,
                    incoming,
                };
            }
            const old = [];
            for (const telec of state.old) {
                if (telec._id !== action.payload._id) {
                    old.push(telec);
                }
            }
            return {
                ...state,
                old,
            };
        }
        default:
            return state;
    }
};

export default teleconsultation;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Card, CheckboxToggle, Input,
    Button, Select,
} from 'react-rainbow-components/';
import validate from './validate';
import {
    Email,
    Lock,
    Phone,
    User,
    SignPost,
    BusinessCard,
} from '../../components/icons';
import { selectOptions } from '../../config';
import { signUpAsync } from '../../redux/actions/auth';
import { setSnackbar } from '../../redux/actions/snackbar';
import history from '../../history';
import KatomiCGU from '../../components/KatomiCGU';
import './styles.css';

class SignupForm extends React.Component {
  state = {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      address: '',
      numCom: '',
      country: '',
      speciality: '',
      imageToShow: null,
      cguModalIsOpen: false,
      constCGU: false,
  };

  handleCGHUModal = () => {
      const { cguModalIsOpen } = this.state;
      this.setState({ cguModalIsOpen: !cguModalIsOpen });
  }

  handleChange = (e) => {
      if (e.target.name === 'country') {
          this.setState({
              [e.target.name]: e.target.value,
              countryVal: e.target.selectedOptions[0].label,
          });
      } else if (e.target.name === 'constCGU') {
          const { constCGU } = this.state;
          this.setState({ [e.target.name]: !constCGU });
      } else {
          this.setState({ [e.target.name]: e.target.value });
      }
  };

  handleOpenModal = () => {
      const { cguModalIsOpen } = this.state;
      this.setState({ cguModalIsOpen: !cguModalIsOpen });
  }

  // eslint-disable-next-line consistent-return
  handleSubmit = (e) => {
      e.preventDefault();
      const { imageToShow, countryVal, ...rest } = this.state;
      const { signUpAsync, setSnackbar } = this.props;
      if (imageToShow) {
          if (countryVal) {
              if (!rest.constCGU) {
                  setSnackbar("Veuillez lire et accepter les conditions générales d'utilisation de Katomi avant de continuer", 'error');
                  return false;
              }
              signUpAsync({ country: countryVal, ...rest });
          } else {
              setSnackbar('Veuillez choisir votre pays', 'error');
          }
      } else {
          setSnackbar("L'ajout d'une image de la carte professionnelle est obligatoire", 'error');
      }
  };

  handleCapture = (e) => {
      const { target } = e;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(target.files[0]);

      fileReader.onload = (e) => {
          this.setState({
              imageToUpload: target.files[0],
              imageToShow: e.target.result,
          });
      };
  };

  render() {
      const {
          imageToShow, country, speciality, cguModalIsOpen, constCGU,
      } = this.state;
      const COUNTRY_OPTIONS = selectOptions.allCountriesList;
      const SPECIALITIES_OPTIONS = selectOptions.doctorSpecialitiesList;

      return (
          <div className="signup-container">
              <KatomiCGU open={cguModalIsOpen} handleClose={this.handleCGHUModal} />


              <Card
                  className="login-card signup-card"
              >
                  <form
                      noValidate
                      onSubmit={(e) => {
                          this.handleSubmit(e);
                      }}
                      className="signup-form"
                  >
                      <div className="react-rainbow-admin-forms_header">
                          <img
                              src="/assets/images/logo_dark.png"
                              alt="katomi logo"
                              className="logo-img-auth"
                          />
                          <h1 style={{ color: '#576574' }}>Inscrivez vous à votre espace sécurisé</h1>
                          <div className="rainbow-flex rainbow-align_center" style={{ margin: '1rem auto' }}>
                              <p>Vous avez déja un compte?</p>
                              <p
                                  onClick={() => {
                                      history.push('/login');
                                  }}
                              >
                  Connectez-vous
                              </p>
                          </div>
                      </div>
                      <article className="rainbow-rainbow-forms_inputs-container">
                          <div className="rainbow-flex  rainbow-justify rainbow-p-horizontal_small">
                              <Field
                                  onChange={(e) => {
                                      this.handleChange(e);
                                  }}
                                  className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                  icon={<Email />}
                                  component={Input}
                                  name="email"
                                  label="Email"
                                  required
                                  type="email"
                              />
                              <Field
                                  onChange={(e) => {
                                      this.handleChange(e);
                                  }}
                                  className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                  icon={<Lock />}
                                  component={Input}
                                  name="password"
                                  label="Mot de passe"
                                  defaultMessage="Mot de passe"
                                  required
                                  type="password"
                              />
                          </div>
                          <div className="rainbow-flex  rainbow-justify rainbow-p-horizontal_small">
                              <Field
                                  onChange={(e) => {
                                      this.handleChange(e);
                                  }}
                                  className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                  icon={<User />}
                                  component={Input}
                                  required
                                  name="lastName"
                                  label="Nom"
                                  type="text"
                              />
                              <Field
                                  onChange={(e) => {
                                      this.handleChange(e);
                                  }}
                                  className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                  icon={<User />}
                                  component={Input}
                                  required
                                  name="firstName"
                                  label="Prénom"
                                  type="text"
                              />
                          </div>
                          <div className="rainbow-flex  rainbow-justify rainbow-p-horizontal_small">
                              <Field
                                  onChange={(e) => {
                                      this.handleChange(e);
                                  }}
                                  className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                  icon={<SignPost />}
                                  component={Input}
                                  name="address"
                                  label="Adresse"
                                  type="text"
                              />
                              <Field
                                  onChange={(e) => {
                                      this.handleChange(e);
                                  }}
                                  className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                  icon={<Phone />}
                                  component={Input}
                                  name="phoneNumber"
                                  label="Téléphone"
                                  type="text"
                              />
                          </div>
                          <div className="rainbow-flex rainbow-justify rainbow-p-horizontal_small">
                              <Select
                                  onChange={(e) => {
                                      this.handleChange(e);
                                  }}
                                  className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                  options={COUNTRY_OPTIONS}
                                  value={country}
                                  name="country"
                                  label="Pays"
                                  required
                                  type="text"
                              />
                          </div>
                          <div className="rainbow-flex rainbow-justify rainbow-p-horizontal_small">
                              <Select
                                  onChange={(e) => {
                                      this.handleChange(e);
                                  }}
                                  className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                  value={speciality}
                                  options={SPECIALITIES_OPTIONS}
                                  name="speciality"
                                  label="Spécialité"
                                  required
                                  type="text"
                              />
                          </div>
                          <div className="rainbow-flex rainbow-justify rainbow-p-horizontal_small">
                              <Field
                                  onChange={(e) => {
                                      this.handleChange(e);
                                  }}
                                  className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                  icon={<BusinessCard />}
                                  component={Input}
                                  name="numCom"
                                  required
                                  label="Numéro d'inscription au conseil de l'ordre des médecins"
                                  type="text"
                              />
                          </div>
                          <div
                              className="rainbow-flex rainbow-justify rainbow-p-horizontal_small"
                              style={{ flexDirection: 'column', marginTop: '1rem' }}
                          >
                              <div className="image-container">
                                  <img
                                      style={{
                                          width: imageToShow ? '100%' : 'auto',
                                          height: '100%',
                                          borderRadius: '2rem',
                                      }}
                                      src={imageToShow}
                                      alt=""
                                  />
                              </div>
                              <input
                                  accept="image/*"
                                  style={{ display: 'none', dragging: false }}
                                  id="icon-button-photo"
                                  onChange={e => this.handleCapture(e)}
                                  type="file"
                              />
                              <label
                                  style={{ display: 'flex', justifyContent: 'center' }}
                                  htmlFor="icon-button-photo"
                              >
                                  <div className="image-input variant-brand">
                                      <span>
                      Ajouter une image de votre carte professionnelle
                                      </span>
                                  </div>
                              </label>

                              <div
                                  style={{
                                      margin: '0 40',
                                      display: 'flex',
                                      justifyContent: 'center',
                                  }}
                              />
                          </div>
                          <div style={{
                              margin: '1rem 3rem', display: 'flex', alignItems: 'center', flexWrap: 'wrap',
                          }}>
                              <Field
                                  label="j'ai lu et j'accepte les"
                                  value={constCGU}
                                  name="constCGU"
                                  onChange={(e) => { this.handleChange(e); }}
                                  component={CheckboxToggle}
                              />

                              <p style={{ color: '#01b6f5', cursor: 'pointer' }} onClick={this.handleOpenModal}>
                                      &nbsp;  CGU de katomi
                              </p>
                          </div>
                      </article>
                      <Button
                          className="rainbow-m-top_medium variant-brand"
                          style={{ display: 'block', margin: 'auto' }}
                          type="submit"
                          variant="brand">
                          <span>S'inscrire</span>
                          <i className="fad fa-sign-in login-icon-v2" />
                      </Button>
                  </form>

              </Card>
          </div>
      );
  }
}

SignupForm.propTypes = {
    signUpAsync: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
};

const dispatchToProps = dispatch => bindActionCreators(
    {
        signUpAsync,
        setSnackbar,
    },
    dispatch,
);

export default reduxForm({
    form: 'sign-up-form',
    validate,
})(connect(null, dispatchToProps)(SignupForm));

/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import * as PatientsAPI from '../../../api/patients';
import { setContentIsLoading } from '../loading';
import { setSnackbar } from '../snackbar';
import { sessionExpired } from '../auth';
import { navigateTo } from '../../../history';

export const SET_PATIENTS_LIST = 'SET_PATIENTS_LIST';
export const ADD_PATIENT = 'ADD_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const REMOVE_PATIENT = 'REMOVE_PATIENT';
export const SELECT_PATIENT = 'SELECT_PATIENT';

const setPatientsList = value => ({
    type: SET_PATIENTS_LIST,
    payload: value,
});

const setPatientsListAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const createdPatients = await PatientsAPI.getAll();
        const usersPatients = await PatientsAPI.getAllPatientsUsers();

        const allPatients = [...createdPatients.data, ...usersPatients.data];
        dispatch(setPatientsList(allPatients));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const setGrantedAccessListAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const usersPatients = await PatientsAPI.getAllPatientsUsers();

        dispatch(setPatientsList(usersPatients.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const addPatient = patient => ({
    type: ADD_PATIENT,
    payload: patient,
});

const _uploadImages = (imagesFiles, patientId) => {
    Object.keys(imagesFiles).map((category) => {
        imagesFiles[category].map(async (image) => {
            await PatientsAPI.addImages(patientId, category, image);
        });
    });
};

const addPatientAsync = (patient, imagesFiles) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.create(patient);

        if (res.data) {
            _uploadImages(imagesFiles, res.data._id);
        }
        dispatch(addPatient(res.data));
        dispatch(setSnackbar('Dossier patient Ajouté avec succes', 'success'));
        navigateTo('/patients');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const updatePatient = patient => ({
    type: UPDATE_PATIENT,
    payload: patient,
});

const updatePatientAsync = (patient, imagesFiles) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.update(patient._id, patient);

        if (res.data) {
            _uploadImages(imagesFiles, patient._id);
        }
        dispatch(updatePatient(res.data));
        dispatch(setSnackbar('Dossier patient Modifié avec succes', 'success'));
        navigateTo('/patients');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const removePatient = id => ({
    type: REMOVE_PATIENT,
    payload: id,
});

const removePatientAsync = id => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.remove(id);
        dispatch(removePatient(res.data._id));
        dispatch(setSnackbar('Dossier patient Supprimé avec succes', 'success'));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const selectPatient = value => ({
    type: SELECT_PATIENT,
    payload: value,
});

const selectPatientAsync = id => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.getById(id);
        dispatch(selectPatient(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

export {
    setPatientsList,
    setPatientsListAsync,
    addPatient,
    addPatientAsync,
    updatePatient,
    updatePatientAsync,
    removePatient,
    removePatientAsync,
    selectPatient,
    selectPatientAsync,
    setGrantedAccessListAsync,
};

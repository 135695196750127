import React from 'react';
import PropTypes from 'prop-types';
import {
    Card, Input,
} from 'react-rainbow-components/components';
import {
    Map,
    City,
    Phone,
    Email,
} from '../icons';
import './styles.css';


const AddressAndContact = (props) => {
    const { handleChange, info } = props;

    return (
        <div className="container">
            <form noValidate className="form">
                <Card
                    className="rainbow-rainbow-forms_contact-details"
                >
                    <article className="rainbow-rainbow-forms_inputs-container">
                        <div className="rainbow-flex  rainbow-justify rainbow-p-horizontal_small">
                            <Input
                                onChange={(e) => { handleChange(e); }}
                                className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                icon={<Map />}
                                value={info.address || ''}
                                name="address"
                                label="Adresse"
                                type="text" />
                            <Input
                                onChange={(e) => { handleChange(e); }}
                                className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                icon={<City />}
                                value={info.city || ''}
                                name="city"
                                label="Ville"
                                type="text" />
                            <Input
                                onChange={(e) => { handleChange(e); }}
                                className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                icon={<Map />}
                                value={info.postalCode || ''}
                                name="postalCode"
                                label="Code postale"
                                type="text" />
                        </div>

                        <div className="rainbow-flex rainbow-justify rainbow-p-horizontal_small">

                            <Input
                                onChange={(e) => { handleChange(e); }}
                                className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                icon={<Phone />}
                                value={info.phone || ''}
                                name="phone"
                                label="Téléphone fixe"
                                type="text" />
                            <Input
                                onChange={(e) => { handleChange(e); }}
                                className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                icon={<Phone />}
                                value={info.cellphone || ''}
                                name="cellphone"
                                label="Téléphone portable"
                                type="text" />
                            <Input
                                onChange={(e) => { handleChange(e); }}
                                className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                icon={<Email />}
                                value={info.email || ''}
                                name="email"
                                label="Email"

                                type="text" />
                        </div>


                    </article>
                </Card>
            </form>

        </div>
    );
};

AddressAndContact.propTypes = {
    handleChange: PropTypes.func,
    info: PropTypes.object.isRequired,
};


AddressAndContact.defaultProps = {
    handleChange: () => null,
};

export default (AddressAndContact);

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-rainbow-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateTime } from 'luxon';
import GeneralInfo from './general';
import PatientAntecedentsTable from '../../../components/PatientAntecedents';
import Documents from './documents';
import { addPatientAsync, updatePatientAsync } from '../../../redux/actions/patients';
import { setSnackbar } from '../../../redux/actions/snackbar';
import { API_PHOTOS } from '../../../config';
import { navigateTo } from '../../../history';

const medicalOptions = [
    { value: 'medical', label: 'Antécédent Médical' },
    { value: 'allergies', label: 'Allergie' },
];

const medicalSelectOptions = [
    { label: '', value: '' },
    { label: 'Diabéte', value: 'Diabète' },
    { label: 'Maladie Cardiovasculaire', value: 'Maladie Cardiovasculaire' },
    { label: 'Maladie Autoimmune', value: 'Maladie Autoimmune' },
    { label: 'Herpes ou Bouton de fièvre', value: 'Herpes ou Bouton de fièvre' },
    { label: 'Autres', value: 'Autres' },
];

const allergiesSelectOptions = [
    { label: '', value: '' },
    { label: 'Générale', value: 'Générale' },
    { label: 'Médicamenteuses', value: 'Médicamenteuse' },
];

const surgicalOptions = [
    { value: 'Chirurgie Générale', label: 'Chirurgie Générale' },
    { value: 'Chirurgie Esthétique', label: 'Chirurgie Esthétique' },
];


const treatmentsOptions = [
    { value: 'En cours', label: 'Traitements en cours' },
    { value: 'Ancien', label: 'Anciens traitements' },
];


const parseNature = (nature) => {
    switch (nature) {
        case 'medical': return 'Antécédent médical';
        case 'allergies': return 'Allérgie';
        case 'surgical': return 'Antécédent chirurgical';
        case 'treatments': return 'Traitement';
        default: return '';
    }
};

const parseNatureInversed = (invNature) => {
    switch (invNature) {
        case 'Antécédent médical': return 'medical';
        case 'Allérgie': return 'medical';
        case 'Antécédent chirurgical': return 'surgical';
        case 'Traitement': return 'treatments';
        default: return '';
    }
};

const decrementArrayElementsAsc = (array, startIndex, decreaseNumber) => {
    // eslint-disable-next-line no-plusplus
    for (let i = startIndex; i < array.length; i++) {
        // eslint-disable-next-line no-param-reassign
        array[i] -= decreaseNumber;
    }
    return array;
};


class SubContent extends React.Component {
      state = {
          tableData: {
              medical: [],
              surgical: [],
              treatments: [],
              documents: [],
          },
          dataToAdd: {
              medical: { radioValue: null },
              surgical: { radioValue: null },
              treatments: { radioValue: null },
          },
          info: {
              birthDate: new Date(),
              smoker: false,
          },
          images: { bio: [], radio: [], autres: [] },
          imagesFiles: { bio: [], radio: [], autres: [] },
          imagesLinks: { bio: [], radio: [], autres: [] },
          imagesFileNames: { bio: [], radio: [], autres: [] },
          imagesBase64: { bio: [], radio: [], autres: [] },
          base64ImagesIndexes: { bio: [], radio: [], autres: [] },
      };


      componentDidUpdate(props) {
          const { selectedPatient } = this.props;
          if (props.selectedPatient !== selectedPatient && selectedPatient) {
              const {
                  antecedents, tobbaco, bio, radio, autres, ...info
              } = selectedPatient;
              const { value, startDate, numberPerDay } = tobbaco;
              const smoker = value;
              const images = this.mapImages({ bio, radio, autres });
              info.startDate = startDate;
              info.numberPerDay = numberPerDay;
              info.smoker = smoker;
              // eslint-disable-next-line react/no-did-update-set-state
              this.setState({
                  tableData: antecedents,
                  info,
                  imagesLinks: { ...images },
                  imagesFileNames: {
                      bio, radio, autres,
                  },
              });
          }
      }


      mapImages = (images) => {
          const { selectedPatient } = this.props;
          const patientId = selectedPatient._id;
          const result = { bio: [], radio: [], autres: [] };
          Object.keys(images)
              .map(category => images[category]
                  .map(fileName => result[category].push({
                      original: `${API_PHOTOS}/load/${patientId}/${category}/lossless/`.concat(
                          fileName,
                      ),
                      thumbnail: `${API_PHOTOS}/load/${patientId}/${category}/thumbnail/`.concat(
                          fileName,
                      ),
                  })));
          return result;
      }

      handleAntecedentChange = (e, nature) => {
          const { dataToAdd } = this.state;
          const { name, value } = e.target;
          const type = dataToAdd[nature].radioValue;
          this.setState({
              dataToAdd: {
                  ...dataToAdd,
                  [nature]: {
                      ...dataToAdd[nature],
                      [type]: {
                          ...dataToAdd[nature][type],
                          [name]: value,
                      },
                  },
              },
          });
      };

      handleRadioChange = (e, nature) => {
          const { value } = e.target;
          const { dataToAdd } = this.state;
          this.setState({
              dataToAdd: {
                  ...dataToAdd,
                  [nature]: {
                      radioValue: value,
                      [value]: {
                          traitement: '',
                          date: '',
                          observation: '',
                      },
                  },

              },
          });
      }

      parseDate = (date) => {
          const newDate = new Date(date).getTime();
          return DateTime.fromMillis(newDate).toFormat('dd/MM/yyyy');
      }

      // eslint-disable-next-line consistent-return
      handleAddToDataTable = (nature) => {
          const { setSnackbar } = this.props;
          const { tableData, dataToAdd } = this.state;
          const type = dataToAdd[nature].radioValue;
          let body;
          const { date, observation, traitement } = dataToAdd[nature][type];
          if (!date && !observation && !traitement) {
              setSnackbar('Veuillez remplir au moins un champ', 'error');
              return false;
          } if (!date && nature === 'treatments') {
              setSnackbar('Veuillez ajouter une date', 'error');
              return false;
          }
          const newTable = [...tableData[nature]];

          if (nature === 'medical') {
              if (!dataToAdd[nature][type].type || dataToAdd[nature][type].type === '') {
                  setSnackbar("Veuillez choisir le type de l'antécédent", 'error');
                  return false;
              }
              body = {
                  nature: parseNature(dataToAdd[nature].radioValue),
                  type: dataToAdd[nature][type].type,
                  date: date ? this.parseDate(date) : '',
                  observation,
                  traitement,
                  ind: newTable.length,
              };
          } else {
              body = {
                  nature: parseNature(nature),
                  type,
                  date: date ? this.parseDate(date) : '',
                  observation,
                  traitement,
                  ind: newTable.length,
              };
          }
          newTable.push(body);
          const newdataToAdd = {
              [nature]: {
                  radioValue: null,
              },
          };

          this.setState({
              tableData: { ...tableData, [nature]: newTable },
              dataToAdd: { ...dataToAdd, ...newdataToAdd },
          });
          setSnackbar('Antécédent ajouté avec succès', 'success');
          return true;
      };

      handleInfoChange = (e) => {
          const { name, value } = e.target;
          const { info } = this.state;
          this.setState({ info: { ...info, [name]: value } });
      }


       deleteImageIfUpdate = (category, index, imageGalleryRef) => {
           const {
               base64ImagesIndexes,
               imagesBase64,
               imagesFiles, imagesLinks, imagesFileNames,
           } = this.state;
           const isUploadedImage = base64ImagesIndexes[category].indexOf(index);
           if (isUploadedImage !== -1) {
               base64ImagesIndexes[category].splice(isUploadedImage, 1);
               decrementArrayElementsAsc(
                   base64ImagesIndexes[category],
                   isUploadedImage,
                   1,
               );
               imagesBase64[category].splice(isUploadedImage, 1);
               this.setState({ imagesBase64: { ...imagesBase64 } });
               imagesFiles[category].splice(index, 1);
               imageGalleryRef.slideToIndex(0);
               this.setState({ imagesFiles: { ...imagesFiles } });
           } else {
               if (index < this.state.imagesLinks[category].length) {
                   decrementArrayElementsAsc(base64ImagesIndexes[category], 0, 1);
               }
               imagesLinks[category].splice(index, 1);
               imageGalleryRef.slideToIndex(0);
               this.setState({ imagesLinks: { ...imagesLinks } });

               imagesFileNames[category].splice(index, 1);
               this.setState({ imagesFileNames: { ...imagesFileNames } });
           }
           this.setState({ base64ImagesIndexes: { ...base64ImagesIndexes } });
       }


      handleDeleteImage = (category, index, imageGalleryRef) => {
          const { selectedPatient } = this.props;
          if (selectedPatient) {
              this.deleteImageIfUpdate(category, index, imageGalleryRef);
          } else {
              const { images, imagesFiles } = this.state;
              const newImagesState = images;
              newImagesState[category].splice(index, 1);
              this.setState({ images: { ...newImagesState } });
              const newImagesFilesState = imagesFiles;
              newImagesFilesState[category].splice(index, 1);
              imageGalleryRef.slideToIndex(newImagesFilesState[category].length - 1);
              this.setState({ imagesFiles: { ...newImagesFilesState } });
          }
      };

    handleCapture = (event, category, imageGalleryRef) => {
        const { selectedPatient } = this.props;
        const { target } = event;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            if (selectedPatient) {
                const { base64ImagesIndexes, imagesBase64, imagesLinks } = this.state;
                const indexOfAddedImage = imagesBase64[category].length
                     + imagesLinks[category].length;
                base64ImagesIndexes[category].push(indexOfAddedImage);
                imagesBase64[category].push({
                    original: e.target.result,
                    thumbnail: e.target.result,
                    originalClass: 'original-image-class',
                });
                const { imagesFiles } = this.state;
                imagesFiles[category].push(target.files[0]);
                this.setState({ imagesBase64 });
                this.setState({ imagesFiles });
                this.setState({ base64ImagesIndexes });
                imageGalleryRef.slideToIndex(indexOfAddedImage);
            } else {
                const images = { ...this.state.images };
                images[category].push({
                    original: e.target.result,
                    thumbnail: e.target.result,
                    originalClass: 'original-image-class',

                });
                const imagesFiles = { ...this.state.imagesFiles };
                imagesFiles[category].push(target.files[0]);
                this.setState({ images });
                this.setState({ imagesFiles });
                imageGalleryRef.slideToIndex(images[category].length - 1);
            }
        };
    };

    handleDeleteAntec = (row) => {
        const { tableData } = this.state;
        const { setSnackbar } = this.props;
        const nt = parseNatureInversed(row.nature);
        const newNatureTable = tableData[nt];
        if (row.ind >= 0) {
            newNatureTable.splice(row.ind, 1);
            for (let i = row.ind; i < newNatureTable.length; i += 1) {
                newNatureTable[i].ind -= 1;
            }
            this.setState({ tableData: { ...tableData, [nt]: newNatureTable } });
            setSnackbar('Antécédent supprimé avec succès', 'success');
        }
    }

      switchStep = () => {
          const {
              generalInfoTab, globalTab, handleOnSelect, documentsTab, selectedPatient,
          } = this.props;
          const {
              dataToAdd, tableData, info, images, imagesLinks, imagesBase64,
          } = this.state;
          let imagesData;
          if (selectedPatient) {
              imagesData = { bio: [], radio: [], autres: [] };
              Object.keys(imagesLinks).map(category => imagesData[category].push(
                  ...imagesLinks[category],
                  ...imagesBase64[category],
              ));
          } else {
              imagesData = images;
          }

          let addProps;
          switch (globalTab) {
              case 'general-info':
                  return (
                      <div style={{ padding: '0 3em' }}>
                          <GeneralInfo
                              handleChange={this.handleInfoChange}
                              currentTab={generalInfoTab}
                              info={info}
                              handleOnSelect={handleOnSelect} />
                      </div>
                  );
              case 'medical-history':
                  addProps = {
                      options: medicalOptions,
                      accordionLabel: '+ Ajouter un antécédent',
                      showTreatment: true,
                      nature: 'medical',
                      dataToAdd,
                  };

                  return (
                      <PatientAntecedentsTable
                          handleDelete={this.handleDeleteAntec}
                          globalTab={globalTab}
                          medicalSelectOptions={medicalSelectOptions}
                          allergiesSelectOptions={allergiesSelectOptions}
                          tableData={tableData.medical}
                          showTreatment
                          handleRadioChange={this.handleRadioChange}
                          handleAntecedentChange={this.handleAntecedentChange}
                          handleAddToDataTable={this.handleAddToDataTable}
                          addProps={addProps} />
                  );
              case 'surgical-history':
                  addProps = {
                      options: surgicalOptions,
                      accordionLabel: '+ Ajouter un antécédent',
                      showTreatment: false,
                      nature: 'surgical',
                      dataToAdd,
                  };
                  return (
                      <PatientAntecedentsTable
                          handleDelete={this.handleDeleteAntec}
                          globalTab={globalTab}
                          tableData={tableData.surgical}
                          showTreatment={false}
                          handleRadioChange={this.handleRadioChange}
                          handleAntecedentChange={this.handleAntecedentChange}
                          handleAddToDataTable={this.handleAddToDataTable}
                          addProps={addProps} />
                  );
              case 'treatments':
                  addProps = {
                      options: treatmentsOptions,
                      accordionLabel: '+ Ajouter un traitement',
                      showTreatment: true,
                      nature: 'treatments',
                      dataToAdd,
                  };
                  return (
                      <PatientAntecedentsTable
                          handleDelete={this.handleDeleteAntec}
                          globalTab={globalTab}
                          tableData={tableData.treatments}
                          showTreatment
                          handleRadioChange={this.handleRadioChange}
                          handleAntecedentChange={this.handleAntecedentChange}
                          handleAddToDataTable={this.handleAddToDataTable}
                          addProps={addProps} />
                  );
              case 'documents':
                  return (
                      <Documents
                          images={imagesData}
                          currentTab={documentsTab}
                          handleOnSelect={handleOnSelect}
                          handleCapture={this.handleCapture}
                          handleDeleteImage={this.handleDeleteImage}
                      />
                  );
              default: return null;
          }
      }


      checkInputs = () => {
          const { info } = this.state;
          const {
              firstName, lastName, nationality, birthDate,
          } = info;
          const { setSnackbar } = this.props;
          if (!firstName || firstName.trim().length < 1) {
              setSnackbar('Veuillez insérer le prénom du patient', 'error');
              return false;
          }
          if (!lastName || lastName.trim().length < 1) {
              setSnackbar('Veuillez insérer le nom du patient', 'error');
              return false;
          }
          if (!nationality || nationality === '') {
              setSnackbar('Veuillez insérer le pays du patient', 'error');
              return false;
          }
          if (!birthDate) {
              setSnackbar('Veuillez insérer la date de naissance du patient', 'error');
              return false;
          }
          return true;
      }

      handleSubmit = () => {
          if (this.checkInputs()) {
              const {
                  tableData, info, imagesFiles, imagesFileNames,
              } = this.state;
              const { addPatientAsync, selectedPatient, updatePatientAsync } = this.props;
              const reqBody = {
                  ...info,
                  tobbaco: {
                      value: info.smoker,
                      numberPerDay: info.numberPerDay ? info.numberPerDay : '',
                      startDate: info.startDate ? info.startDate : '',
                  },
                  antecedents: { ...tableData },
              };
              if (!selectedPatient) {
                  reqBody.images = { bio: [], radio: [], autres: [] };
                  addPatientAsync(reqBody, imagesFiles);
              } else {
                  reqBody.images = imagesFileNames;
                  updatePatientAsync(reqBody, imagesFiles);
              }
          }
      }


      render() {
          return (
              <div>
                  {this.switchStep()}
                  <div className="buttons-container">
                      <div className="pair-buttons">
                          <Button variant="destructive" className="marged-button variant-destructive" onClick={() => { navigateTo('/patients'); }} label="Annuler et quitter" />
                          <Button variant="success" className="marged-button variant-success" onClick={this.handleSubmit} label="Enregistrer et quitter" />
                      </div>
                  </div>
              </div>
          );
      }
}

SubContent.propTypes = {
    generalInfoTab: PropTypes.string.isRequired,
    documentsTab: PropTypes.string.isRequired,
    globalTab: PropTypes.string.isRequired,
    handleOnSelect: PropTypes.func.isRequired,
    addPatientAsync: PropTypes.func.isRequired,
    selectedPatient: PropTypes.func.isRequired,
    updatePatientAsync: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
};


const stateToProps = state => ({
    selectedPatient: state.patients.selectedPatient,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        addPatientAsync,
        updatePatientAsync,
        setSnackbar,
    },
    dispatch,
);


export default connect(stateToProps, dispatchToProps)(SubContent);

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-rainbow-components';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import '../styles.css';

const formatDate = (date) => {
    const newDate = new Date(date).getTime();
    return DateTime.fromMillis(newDate).toLocaleString(DateTime.DATETIME_MED, { locale: 'fr' });
};

const startButtonStyles = {
    width: '100%',
    margin: '1rem auto',
    fontSize: '1.1rem',
    letterSpacing: 1,
};

const enableStartButton = (date) => {
    const newJSDate = new Date(date).getTime();
    const newLuxonDate = DateTime.fromMillis(newJSDate);
    const result = newLuxonDate.diffNow('hours');
    const { hours } = result.values;
    if ((hours >= 0 && hours <= 1)) {
        return true;
    }
    if (hours > -1 && hours <= 0) {
        return true;
    }
    return false;
};

const StartingTodayCard = (props) => {
    const preparePatientName = patient => `${patient.lastName} ${patient.firstName}`;
    const { telecList, handleStart } = props;
    return (
        <div style={{ marginTop: '3rem', padding: '0 1.5rem' }} className="rainbow-flex rainbow-flex_wrap rainbow-justify_center">
            {telecList.map(telec => (
                <Card
                    title="Vous avez une téléconsultation aujourd'hui"
                    className="rainbow-m-bottom_large starting-today-card is-jura rainbow-m_auto"
                >
                    <p className="rainbow-m-left_small" style={{ marginTop: '1.3rem' }}>
            Avec :
                        <span className="rainbow-color_brand">
              &nbsp;
                            {preparePatientName(telec.patient)}
                        </span>
                    </p>
                    <p className="rainbow-m-left_small">
            Prévue le :&nbsp;
                        <span className="rainbow-color_brand">
                            {formatDate(telec.date)}
                        </span>
                    </p>
                    <Button
                        onClick={() => handleStart(telec)}
                        style={startButtonStyles}
                        label="Démarrer"
                        className="variant-success marged-button"
                        variant="success"
                        disabled={!enableStartButton(telec.date)}
                    />
                </Card>
            ))}
        </div>
    );
};

StartingTodayCard.propTypes = {
    telecList: PropTypes.func.isRequired,
    handleStart: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    telecList: state.teleconsultation.startingToday,
});

export default connect(stateToProps)(StartingTodayCard);

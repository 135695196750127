/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Modal } from 'react-rainbow-components';
import PropTypes from 'prop-types';
import './styles.css';

const textStyles = {
    textAlign: 'center',
    fontSize: 15,
    padding: '0 16px',
};

const AccountActivation = (props) => {
    const {
        open,
    } = props;
    return (
        <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">

            <Modal
                isOpen={open}
                hideCloseButton
                title="Compte en cours de validation"
            >
                <p style={textStyles}>
                Pour des raisons de sécurité, une démarche de vérification
                de votre compte est en cours.
                    <br />
                    <br />
                Votre compte sera validé dans un délai maximum de 24h.
                Pour de plus amples informations vous pouvez contacter notre support à l'adresse
                    <b style={{ color: '#01b6f5' }}> info@katomi.co </b>
                </p>

            </Modal>
        </div>
    );
};


AccountActivation.propTypes = {
    open: PropTypes.bool.isRequired,
};


export default AccountActivation;

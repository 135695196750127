/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-alert */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Modal, Input, Button } from 'react-rainbow-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Peer from 'peerjs';
import SelectedContact from '../../messages/selectedContact';
import ChatMessages from '../../messages/chatMessages';
import './styles.css';
import { getUserStatus } from '../../../api/teleconsultation';
import CheckMedia from './checkMedia';
import * as logActions from '../../../redux/actions/teleconsultation/logsHelper';
import { postLog, updateTelecAsync } from '../../../redux/actions/teleconsultation';
import { ENDED } from '../../../config';

class StartContainer extends React.Component {
  state = {
      // eslint-disable-next-line react/no-unused-state
      userStatus: 'disconnected',
      messages: [],
      msgToSend: '',
      conn: null,
      localStream: null,
      mic: true,
      cam: true,
  };

  componentDidUpdate(oldProps, oldState) {
      const { localStream } = this.state;

      if (oldState.localStream !== localStream && localStream) {
          const videoCaller = document.querySelector('#me');
          videoCaller.srcObject = localStream;
          videoCaller.volume = 0;
          this.connectPeer();
      }
  }

  componentWillUnmount() {
      const { peer } = this.state;
      if (peer) {
          peer.disconnect();
          peer.destroy();
      }
  }


  endCall = () => {
      const { updateTelecAsync, selectedTelec, handleClose } = this.props;
      const { peer } = this.state;
      const { _id } = selectedTelec;
      postLog(_id, logActions.USER_ENDED_CALL);
      updateTelecAsync(_id, { status: ENDED });
      if (peer) {
          peer.destroy();
      }
      handleClose();
  }

  connectPeer = () => {
      const { user, selectedTelec } = this.props;
      const peer = new Peer(user._id, { host: 'p2p.katomi.co', secure: true });
      this.setState({ peer });
      peer.on('open', () => {
          postLog(selectedTelec._id, logActions.PEER_OPEN);
          this.listenToConnection(peer);
      });

      peer.on('error', (err) => {
          postLog(selectedTelec._id, logActions.PEER_ERROR, { err });
      });
  };

  listenToConnection = (peer) => {
      const { localStream } = this.state;
      const { selectedTelec } = this.props;
      // PEER CONNECTION ESTABLISHED (MESSSAGES EXCHANGE)
      peer.on('connection', (conn) => {
          postLog(selectedTelec._id, logActions.PEER_CONNECTION, { conn });

          this.setState({ conn });

          const remoteUserId = selectedTelec.patient._id;

          conn.on('data', (data) => {
              postLog(selectedTelec._id, logActions.PEER_CONNECTION_DATA, { data });
              this.pushMessage(data, remoteUserId);
          });

          conn.on('error', (err) => {
              postLog(selectedTelec._id, logActions.PEER_CONNECTION_EROR, { ...err });
          });
      });

      // PEER CALL RECEIVED (VIDEO EXCHANGE)
      peer.on('call', (call) => {
          postLog(selectedTelec._id, logActions.PEER_CALL, { call });
          call.answer(localStream);

          call.on('stream', (remoteStream) => {
              postLog(selectedTelec._id, logActions.PEER_CALL_STREAM);
              const videoReceiver = document.querySelector('#receiver');
              videoReceiver.srcObject = remoteStream;
              postLog(selectedTelec._id, logActions.CALL_STARTED);
          });
      });
  };

  handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
  };

  pushMessage = (message, sender) => {
      const { messages } = this.state;
      const { user } = this.props;
      const newMessages = [...messages];
      newMessages.push({
          sender,
          content: message,
          time: Date.now(),
      });
      setTimeout(() => {
          this.scrollToBottom();
      }, 500);
      if (user._id === sender) {
          this.setState({ messages: newMessages, msgToSend: '' });
      } else {
          this.setState({ messages: newMessages });
      }
  };

  setUserStatus = async () => {
      const { selectedTelec } = this.props;
      try {
          await getUserStatus(selectedTelec.patient._id, (snapshot) => {
              if (snapshot) {
                  // eslint-disable-next-line react/no-unused-state
                  this.setState({ userStatus: snapshot.data().status });
              }
          });
      } catch (err) {
      // TODO do something with err
      }
  };

  handleSendMessage = (e) => {
      e.preventDefault();
      const { msgToSend, conn } = this.state;
      const { user } = this.props;
      if (conn && msgToSend.trim().length > 0) {
          conn.send(msgToSend);
          this.pushMessage(msgToSend, user._id);
      }
  };

  scrollToBottom = () => {
      const element = document.getElementById('teleconv');
      if (element) {
          element.scrollTop = element.scrollHeight;
      }
  };

  handleDisable = (option) => {
      const { localStream } = this.state;
      if (option === 'mic') {
          const { mic } = this.state;
          localStream.getAudioTracks()[0].enabled = !localStream.getAudioTracks()[0].enabled;


          this.setState({ mic: !mic });
      } else {
          const { cam } = this.state;

          localStream.getVideoTracks()[0].enabled = !localStream.getVideoTracks()[0].enabled;

          this.setState({ cam: !cam });
      }
  }

  render() {
      const { open, handleClose, selectedTelec } = this.props;
      const {
          messages, msgToSend, localStream, mic, cam,
      } = this.state;
      let patientFullName = '';
      if (selectedTelec) {
          patientFullName = `${selectedTelec.patient.lastName} ${selectedTelec.patient.firstName}`;
      }
      return (
          <div className="rainbow-m-around_large">
              {localStream ? (
                  <Modal
                      isOpen={open}
                      onRequestClose={handleClose}
                      hideCloseButton
                      className="telec-start-modal"
                  >
                      <div className="telec-content-container">
                          <div className="call-container">
                              <div style={{ position: 'relative' }}>
                                  <video id="receiver" autoPlay className="receiver" />
                                  <div className="telec-icons-container">
                                      <i
                                          onClick={() => {
                                              this.handleDisable('mic');
                                          }}
                                          className={mic ? 'fal fa-microphone' : 'fal fa-microphone-slash'}
                                          style={{ cursor: 'pointer', marginRight: '1rem', color: '#01b6f5' }}
                                      />
                                      <i
                                          onClick={() => {
                                              this.handleDisable('cam');
                                          }}
                                          className={cam ? 'fal fa-video' : 'fal fa-video-slash'}
                                          style={{ cursor: 'pointer', marginRight: '1rem', color: '#01b6f5' }}
                                      />
                                      <i className="fal fa-phone-slash"
                                          onClick={() => {
                                              this.endCall();
                                          }}
                                          style={{ cursor: 'pointer', color: '#ea4243' }}

                                      />
                                  </div>
                              </div>
                              <video id="me" autoPlay className="caller" />
                          </div>
                          <div className="chat-container">
                              <div style={{ flex: 1, maxHeight: '80%' }}>
                                  <SelectedContact
                                      selectedUser={selectedTelec.patient}
                                      style={{ position: 'relative', left: '1.25rem' }}
                                  />
                                  <div className="messages-cont" id="teleconv">
                                      <ChatMessages messages={messages} />
                                  </div>
                              </div>
                              <div className="react-rainbow-admin-messages_input-container">
                                  <form
                                      onSubmit={(e) => {
                                          this.handleSendMessage(e);
                                      }}
                                  >
                                      <Input
                                          className="react-rainbow-admin-messages_input"
                                          label="Tapez votre message"
                                          hideLabel
                                          name="msgToSend"
                                          value={msgToSend}
                                          onChange={this.handleChange}
                                          placeholder="Tapez votre message"
                                          iconPosition="right"
                                      />
                                      <Button
                                          type="submit"
                                          style={{ marginTop: '1rem', width: '100%' }}
                                          label="Envoyer"
                                          variant="brand"
                                      />
                                  </form>
                              </div>
                          </div>
                      </div>
                  </Modal>
              ) : (
                  <CheckMedia
                      patientFullName={patientFullName}
                      selectedTelecId={selectedTelec._id}
                      // eslint-disable-next-line max-len
                      setLocalStream={(localstream) => {
                          this.setState({ localStream: localstream });
                      }}
                      handleClose={() => { handleClose(); }}
                      open={open}
                  />
              )}
          </div>
      );
  }
}

StartContainer.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    selectedTelec: PropTypes.object.isRequired,
    updateTelecAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});


const dispatchToProps = dispatch => bindActionCreators({
    updateTelecAsync,
}, dispatch);


export default connect(stateToProps, dispatchToProps)(StartContainer);

/*


.icons-container {
    position: absolute;
bottom: 4px;
display: flex;
justify-content: center;
width: 100%;
font-size: 1.5rem;
}

                            */

/* eslint-disable no-script-url, jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-rainbow-components/components/Card';
import './styles.css';


const fontSize = '0.875rem';
export default function ListCard(props) {
    const {
        title,
        component,
        footerLink,
        ...rest
    } = props;

    return (
        <Card className="react-rainbow-admin-dashboard_card rainbow-p-top_medium rainbow-p-bottom_small rainbow-p-horizontal_small list-card-v2" {...rest}>
            <h1 style={{ fontSize }} className="react-rainbow-admin-dashboard_chart-title rainbow-align-content_left rainbow-color_dark-1 rainbow-p-bottom_medium is-jura">
                {title}
            </h1>
            {component}
            <div className="rainbow-p-top_small rainbow-align-content_center is-jura">
                {footerLink}
                <i className="fad fa-eye view-icon-v2" />
            </div>
        </Card>
    );
}

ListCard.propTypes = {
    title: PropTypes.string,
    component: PropTypes.object,
    footerLink: PropTypes.node,
};

ListCard.defaultProps = {
    title: undefined,
    component: {},
    footerLink: undefined,
};

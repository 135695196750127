/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Avatar } from 'react-rainbow-components/components';
import { PersonIcon } from '../../components/icons';
import { API_PHOTOS } from '../../config';

const getClassNames = isSelected => classnames('react-rainbow-admin-messages_contact', {
    'react-rainbow-admin-messages_contact--active': isSelected,
});


const PatientsList = ({ patients, selectedUser, onClick }) => (patients.map((patient, index) => {
    let isSelected = false;
    if (selectedUser) {
        isSelected = selectedUser._id === patient._id;
    }
    const name = `${patient.lastName} ${patient.firstName}`;
    const photoUrl = `${API_PHOTOS}/load/${patient._id}/profile/thumbnail/profile-picture.webp`;

    const key = `patient-${index}`;
    return (
        <article
            role="presentation"
            key={key}
            className={getClassNames(isSelected)}
            onClick={() => onClick(patient)}>
            <Avatar
                className="react-rainbow-admin-messages_contact-avatar"
                src={photoUrl}
                icon={<PersonIcon />}
                size="small" />
            <div className="react-rainbow-admin-messages_contact-content">
                <div className="rainbow-flex rainbow-justify_spread">
                    <h3 className="react-rainbow-admin-messages_contact-name">{name}</h3>
                </div>
                <p className="react-rainbow-admin-messages_contact-message">Patient</p>
            </div>
        </article>
    );
})
);


PatientsList.propTypes = {
    patients: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string,
        lastName: PropTypes.string,
        firstName: PropTypes.string,
    })),
    selectedUser: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};

PatientsList.defaultProps = {
    patients: [],
};

const DoctorsList = ({ doctors, selectedUser, onClick }) => (doctors.map((doctor, index) => {
    let isSelected = false;
    if (selectedUser) {
        isSelected = selectedUser._id === doctor._id;
    }
    const name = `${doctor.lastName} ${doctor.firstName}`;
    const photoUrl = `${API_PHOTOS}/load/${doctor._id}/profile/thumbnail/profile-picture.webp`;

    const key = `doctor-${index}`;
    return (
        <article
            role="presentation"
            key={key}
            className={getClassNames(isSelected)}
            onClick={() => onClick(doctor)}>
            <Avatar
                className="react-rainbow-admin-messages_contact-avatar"
                src={photoUrl}
                icon={<PersonIcon />}
                size="small" />
            <div className="react-rainbow-admin-messages_contact-content">
                <div className="rainbow-flex rainbow-justify_spread">
                    <h3 className="react-rainbow-admin-messages_contact-name">{name}</h3>
                </div>
                <p className="react-rainbow-admin-messages_contact-message">{doctor.speciality}</p>
            </div>
        </article>
    );
}));


DoctorsList.propTypes = {
    doctors: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string,
        lastName: PropTypes.string,
        firstName: PropTypes.string,
        speciality: PropTypes.string,
    })),
    selectedUser: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};

DoctorsList.propTypes = {
    doctors: [],
};


const Divider = ({ text }) => (
    <p className="react-rainbow-admin-messages_body-selected-contact--status">
        {text}
    </p>
);

Divider.propTypes = {
    text: PropTypes.string.isRequired,
};

const Contacts = (props) => {
    const {
        doctors, patients, onClick, selectedUser,
    } = props;
    return (
        <div>

            {/* <span className="react-rainbow-admin-messages_body--divider" /> */}
            {patients.length > 0 ? (
                <div>
                    <Divider text="Patients" />
                    <PatientsList
                        patients={patients}
                        onClick={onClick}
                        selectedUser={selectedUser} />

                </div>

            ) : null}

            {/* <span className="react-rainbow-admin-messages_body--divider" /> */}
            {doctors.length > 0 ? (
                <div>
                    <Divider text="Médecins" />
                    <DoctorsList doctors={doctors} onClick={onClick} selectedUser={selectedUser} />
                </div>
            ) : null}


        </div>
    );
};

Contacts.propTypes = {
    doctors: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string,
        lastName: PropTypes.string,
        firstName: PropTypes.string,
        speciality: PropTypes.string,
    })),
    patients: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string,
        lastName: PropTypes.string,
        firstName: PropTypes.string,
    })),
    selectedUser: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};

Contacts.defaultProps = {
    doctors: [],
    patients: [],
};


const stateToProps = state => ({
    selectedUser: state.chat.selectedUser,
});

export default connect(stateToProps, null)(Contacts);

/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-rainbow-components';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSyringe, faStethoscope, faLaptopMedical } from '@fortawesome/free-solid-svg-icons';

import ListCard from './listCard';
import ChartsSection from './chartsSection';
import PatientsTable from '../patients/patientsTable';
import ConversationsList from '../messages/discussions';
import Tile from '../../components/Tile';
import { fetchDashboardData } from '../../redux/actions/dashboard';
import { setGrantedAccessListAsync } from '../../redux/actions/patients';
import { setConversationsListAsync } from '../../redux/actions/chat';
// import TrialMessage from '../../components/TrialMessage';
import { monthsInFrench } from '../../config';
import './styles.css';

const mBottom1rem = { marginBottom: '1rem' };

const ContentPage = (props) => {
    const {
        consultationsStats,
        telecStats,
        numberOfActs,
        consultationsNumber,
        telecNumber,
        numberOfNewPatients,
        isLoading,
    } = props;
    const todaysMonth = new Date().getMonth();

    if (isLoading) {
        return <Spinner size="large" />;
    }
    return (
        <div>
            <section style={{ alignItems: 'stretch' }} className="react-rainbow-admin-dashboard_section rainbow-align-content_space-between rainbow-p-top_large">
                <Tile
                    title={`Actes /${monthsInFrench[todaysMonth]}`}
                    label={numberOfActs}
                    // icon={<FontAwesomeIcon icon={faSyringe} className="tile-icon-v2 fal fa-3x" />}
                    icon={<i className="tile-icon-v2 fal fa-3x fa-syringe" />}
                    style={mBottom1rem}
                />
                <Tile
                    title={`Consultations /${monthsInFrench[todaysMonth]}`}
                    label={consultationsNumber}
                    // icon={<FontAwesomeIcon icon={faStethoscope} className="tile-icon-v2 fal fa-3x" />}
                    icon={<i className="tile-icon-v2 fa-3x fal fa-stethoscope" />}
                    style={mBottom1rem}
                />

                <Tile
                    title={`Téléconsultations /${monthsInFrench[todaysMonth]}`}
                    label={telecNumber}
                    // icon={<FontAwesomeIcon icon={faLaptopMedical} className="tile-icon-v2 fal fa-3x" />}
                    icon={<i className="tile-icon-v2 fal fa-3x fa-laptop-medical" />}
                    style={mBottom1rem}
                />

                <Tile
                    title={`Nouveaux Patients /${monthsInFrench[todaysMonth]}`}
                    label={numberOfNewPatients}
                    // icon={<FontAwesomeIcon icon={<i className="tile-icon-v2 fal fa-3x fa-users-medical" />} className="tile-icon-v2 fal fa-3x" />}
                    icon={<i className="tile-icon-v2 fal fa-3x fa-users-medical" />}
                    style={mBottom1rem}
                />
            </section>

            <ChartsSection
                consultationsStats={consultationsStats}
                telecStats={telecStats}
            />

            <section style={{ alignItems: 'stretch' }} className="react-rainbow-admin-dashboard_section rainbow-align-content_space-between rainbow-p-top_large">
                <ListCard
                    title="Dossiers patients partagés"
                    component={<PatientsTable loadPatients={false} />}
                    footerLink={(
                        <Link
                            className="react-rainbow-admin-dashboard_list-card--footer-link rainbow-color_brand"
                            to="/patients"
                        >
                          Voir tous les dossiers
                        </Link>
                    )}
                />

                <ListCard
                    title="Derniers messages"
                    component={(
                        <ConversationsList
                            showSelectedUser={false}
                            onClick={() => null}
                        />
                    )}
                    footerLink={(
                        <Link
                            className="react-rainbow-admin-dashboard_list-card--footer-link rainbow-color_brand"
                            to="/messages"
                        >
                Voir tous les messages
                        </Link>
                    )}
                />
            </section>
        </div>
    );
};

ContentPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    consultationsStats: PropTypes.array.isRequired,
    telecStats: PropTypes.array.isRequired,
    numberOfActs: PropTypes.number.isRequired,
    consultationsNumber: PropTypes.number.isRequired,
    telecNumber: PropTypes.number.isRequired,
    // user: PropTypes.object.isRequired,
    numberOfNewPatients: PropTypes.number.isRequired,
};

class Dashboard extends Component {
    componentDidMount() {
        const {
            fetchDashboardData,
            setGrantedAccessListAsync,
            setConversationsListAsync,
        } = this.props;
        fetchDashboardData();
        setConversationsListAsync(true);
        setGrantedAccessListAsync();
    }

    render() {
        return (
            <div className="react-rainbow-admin-dashboard_view-port">
                <ContentPage {...this.props} />
            </div>
        );
    }
}

Dashboard.propTypes = {
    fetchDashboardData: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    setGrantedAccessListAsync: PropTypes.func.isRequired,
    setConversationsListAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    ...state.dashboard,
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        fetchDashboardData,
        setGrantedAccessListAsync,
        setConversationsListAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(Dashboard);

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-case-declarations */
import {
    SET_PATIENTS_LIST,
    ADD_PATIENT,
    UPDATE_PATIENT,
    REMOVE_PATIENT,
    SELECT_PATIENT,
} from '../../actions/patients';

const initialState = {
    patientsList: [],
    selectedPatient: null,
};

const patients = (state = initialState, action) => {
    switch (action.type) {
        case SET_PATIENTS_LIST:
            return { ...state, patientsList: action.payload };
        case ADD_PATIENT:
            return {
                ...state,
                patientsList: [action.payload, ...state.patientsList],
            };
        case UPDATE_PATIENT:
            const patientsList = [];
            for (const patient of state.patientsList) {
                if (patient._id === action.payload._id) {
                    patientsList.push(action.payload);
                } else patientsList.push(patient);
            }
            return {
                ...state,
                patientsList,
            };
        case REMOVE_PATIENT:
            return {
                ...state,
                patientsList: state.patientsList.filter(
                    element => element._id !== action.payload,
                ),
            };
        case SELECT_PATIENT:
            return { ...state, selectedPatient: action.payload };
        default:
            return state;
    }
};

export default patients;

import * as selectOptions from './select';

const API_ENDPOINT = 'https://api.katomi.co/v1';
// const API_ENDPOINT = 'http://localhost:8080/v1';
const API_AUTH = `${API_ENDPOINT}/doctor`;
const API_CHAT = `${API_ENDPOINT}/chat`;
const API_PATIENTS = `${API_ENDPOINT}/patients`;
const API_CONSULTATIONS = `${API_ENDPOINT}/consultations`;
const API_PHOTOS = `${API_ENDPOINT}/photos`;
const API_CONFIG = `${API_ENDPOINT}/config`;
const API_CONSULTATION_PURPOSES = `${API_CONFIG}/c_purposes`;
const API_PATIENT_PHOTOS = `${API_ENDPOINT}/uploads`;
const API_TELECONSULTATION = `${API_ENDPOINT}/teleconsultations`;
const API_PATIENTS_USERS = `${API_ENDPOINT}/patients-users`;
const API_TELEEXPERTISE = `${API_ENDPOINT}/teleexpertise`;

// CONSULTATION TYPES
const TELECONSULTATION = 'Téléconsultation';
const CONSULTATION = 'Consultation';
const OPERATION = 'Chirurgie/Acte';
const UNAVAILABILITY = 'unavailability';
const AVAILABILITY = 'availability';

// TELECONSULTATION STATUS
const PENDING = "En attente d'une réponse";
const PAYMENT_PENDING = 'En attente de paiement';
const REJECTED = 'Réfuté';
const CHANGED = "Proposition d'une nouvelle date";
const PAYED = 'Payé';
const STARTED = 'started';
const ENDED = 'ended';


const errorCodes = {
    'auth/user-not-found': "Aucun compte n'est associé à cette adresse e-mail.",
    'auth/invalid-email': "L'adresse email saisie est invalide.",
    'auth/user-disabled':
      'Le compte associé à cette adresse e-mail a été désactivé.',
    'auth/wrong-password': 'Mot de passe incorrect. Veuillez réessayer.',
    'auth/user-token-expired':
      'Votre session a expirée. Veuillez vous reconnecter.',
    'auth/invalid-user-token':
      'Votre session a expirée. Veuillez vous reconnecter.',
    'auth/network-request-failed':
      'Impossible de communiquer avec le serveur. Veuillez vérifier votre connexion à internet.',
    'auth/email-already-in-use':
    'Vous avez déja un compte associé a cet adresse email',
};

const monthsInFrench = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];


export {
    API_AUTH,
    API_PATIENTS,
    API_CONSULTATIONS,
    API_CONSULTATION_PURPOSES,
    API_PHOTOS,
    API_PATIENTS_USERS,
    API_PATIENT_PHOTOS,
    API_CHAT,
    API_TELECONSULTATION,
    API_TELEEXPERTISE,

    TELECONSULTATION,
    CONSULTATION,
    OPERATION,
    UNAVAILABILITY,
    AVAILABILITY,

    PENDING,
    PAYMENT_PENDING,
    REJECTED,
    CHANGED,
    PAYED,
    STARTED,
    ENDED,

    selectOptions,
    errorCodes,
    monthsInFrench,
};

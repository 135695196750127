import React, { Component } from 'react';
import './styles.css';

export default function (ComposedComponent) {
    class NetworkDetector extends Component {
    state = {
        isDisconnected: false,
    }

    componentDidMount() {
        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
    }


    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            const webPing = setInterval(
                () => {
                    fetch('//google.com', {
                        mode: 'no-cors',
                    })
                        .then(() => {
                            this.setState({ isDisconnected: false }, () => clearInterval(webPing));
                        }).catch(() => this.setState({ isDisconnected: true }));
                }, 2000,
            );
            return;
        }
        // eslint-disable-next-line consistent-return
        return this.setState({ isDisconnected: true });
    }

    render() {
        const { isDisconnected } = this.state;
        return (
            <div>
                { isDisconnected ? (
                    <div className="no-internet-container">
                        <div>
                            <img src="/assets/images/katomi-k.png" style={{ marginLeft: '5rem' }} alt="katomi logo" className="katomi-logo-v2 logo-rotate" />
                            <p>Internet connection lost</p>
                        </div>
                    </div>
                ) : (
                    <ComposedComponent {...this.props} />
                )
                }


            </div>
        );
    }
    }

    return NetworkDetector;
}

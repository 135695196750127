import dashboard from './dashboard';
import app from './app';
import auth from './auth';
import loading from './loading';
import patients from './patients';
import snackbar from './snackbar';
import consultations from './consultations';
import cPurposes from './cPurposes';
import chat from './chat';
import teleexpertise from './teleexpertise';
import notifications from './notifications';
import teleconsultation from './teleconsultation';

export default {
    dashboard,
    app,
    auth,
    loading,
    patients,
    snackbar,
    consultations,
    cPurposes,
    chat,
    teleexpertise,
    notifications,
    teleconsultation,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Modal,
    RadioGroup, DateTimePicker, Button,
} from 'react-rainbow-components/components';
import { PAYMENT_PENDING, REJECTED, CHANGED } from '../../config';
import './styles.css';

const options = [
    { label: 'Accepter', value: PAYMENT_PENDING },
    { label: 'Refuser', value: REJECTED },
    { label: 'Proposer une nouvelle date', value: CHANGED },
];


const TelecRespondModal = (props) => {
    const { open, handleClose, handleSubmit } = props;
    const [selectedOption, setSelectedOption] = useState();
    const [date, setDate] = useState();
    return (
        <Modal
            isOpen={open}
            onRequestClose={handleClose}
            className="padding-modal"
            title="Répondre a une invitation de téléconsultation"
            footer={(
                <div className="rainbow-flex rainbow-justify_end">
                    <Button label="Annuler" variant="brand" className="variant-brand" onClick={() => handleClose()} />
                    <Button label="Valider" variant="brand" className="variant-brand" onClick={() => handleSubmit(selectedOption, date)} />
                </div>
            )}
        >
            <RadioGroup
                options={options}
                value={selectedOption}
                onChange={(e) => { setSelectedOption(e.target.value); }}
            />
            {selectedOption === CHANGED ? (
                <DateTimePicker
                    locale="fr"
                    value={date}
                    minDate={new Date()}
                    label="Proposer une nouvelle date"
                    onChange={(val) => { setDate(val); }} />
            ) : null}
        </Modal>
    );
};

TelecRespondModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};


export default (TelecRespondModal);


const START_BC = 'S_C';
const ALLOW_BC = 'A_C';
const ALLOW_ER_PERMISSION = 'AE_PERMISSION';
const ALLOW_ER_NOTFOUND = 'AE_NOTFOUND';
const PEER_OPEN = 'P_O';
const PEER_CONNECTION = 'P_CO';
const PEER_CONNECTION_EROR = 'P_CO_ERR';
const PEER_CONNECTION_DATA = 'P_CO_D';
const PEER_CALL = 'P_C';
const PEER_CALL_STREAM = 'P_C_S';
const PEER_ERROR = 'P_ERR';

const CALL_STARTED = 'C_S';
const USER_ENDED_CALL = 'U_E_C';
const REMTOE_ENDED_CALL = 'U_E_C';
const TIMER_STARTED = 'T_S';
const TIMER_RUNOUT = 'T_E';

const parseAction = (action) => {
    switch (action) {
        case START_BC: return 'User clicked on start teleconsultation button';
        case ALLOW_BC: return 'User clicked on allow access to mic/cam button';
        case ALLOW_ER_PERMISSION: return 'User had error permission on getting localstream';
        case ALLOW_ER_NOTFOUND: return 'User had error not found on getting localstream';
        case PEER_OPEN: return 'User successfully connected to Peer';
        case PEER_CONNECTION: return 'User successfully connected with the remote user';
        case PEER_CONNECTION_DATA: return 'User successfully received data from remote user';
        case PEER_CONNECTION_EROR: return 'Error occured for the established connection';
        case PEER_CALL: return 'User received a call from the remote user';
        case PEER_CALL_STREAM: return 'User received the remotestream object';
        case PEER_ERROR: return 'Peerjs Error listner';


        case CALL_STARTED: return 'Call started';
        case USER_ENDED_CALL: return 'User ended the call on purpose';
        case REMTOE_ENDED_CALL: return 'RemoteUser ended the call on purpose';
        case TIMER_STARTED: return 'Timer started';
        case TIMER_RUNOUT: return 'Timer runout and the call was ended by force';
        default: return 'Unknown action status';
    }
};


module.exports = {
    START_BC,
    ALLOW_BC,
    ALLOW_ER_PERMISSION,
    ALLOW_ER_NOTFOUND,
    PEER_OPEN,
    PEER_CONNECTION,
    PEER_CONNECTION_EROR,
    PEER_CONNECTION_DATA,
    PEER_CALL,
    PEER_CALL_STREAM,
    PEER_ERROR,

    CALL_STARTED,
    USER_ENDED_CALL,
    REMTOE_ENDED_CALL,
    TIMER_STARTED,
    TIMER_RUNOUT,

    parseAction,
};

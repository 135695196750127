/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
    Badge,
    ButtonIcon,
    Card,
    Avatar,
    Button,
} from 'react-rainbow-components';
import UserField from './userField';
import { PersonIcon } from '../../components/icons';
import { addImages } from '../../api/patients';
import { setSnackbar } from '../../redux/actions/snackbar';
import { updateUserAsync } from '../../redux/actions/auth';
import { API_PHOTOS } from '../../config';
import ImageCrop from '../../components/ImageCrop';
import './styles.css';

class Profile extends React.Component {
  state = {
      photoURL: null,
      address: '',
      phoneNumber: '',
      readOnly: true,
      cropOpen: false,
  };

  componentDidMount() {
      const { user } = this.props;
      if (user) {
          const { phoneNumber, address } = user;
          this.setState({ phoneNumber, address });
      }
  }

handleSubmit = () => {
    const { phoneNumber, address, readOnly } = this.state;
    const { updateUserAsync } = this.props;
    updateUserAsync({ phoneNumber, address });
    this.setState({ readOnly: !readOnly });
}

  handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
  }

  handleCapture = async (event) => {
      const { target } = event;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = (e) => {
          this.setState({ photoURL: e.target.result, cropOpen: true });
      };
  };

  updateProfilePicture = async (data) => {
      const { cropOpen } = this.state;
      const { updateUserAsync, user, setSnackbar } = this.props;
      try {
          await addImages(user._id, 'profile', data);
          this.setState({ cropOpen: !cropOpen });
          updateUserAsync({}, null, user._id);
      } catch (err) {
          setSnackbar("Une erreur s'est produite veuillez réessayer", 'error');
      }
  }

  render() {
      const { user } = this.props;
      const { photoURL, readOnly, cropOpen } = this.state;
      const backupPhotoUrl = user
          ? `${API_PHOTOS}/load/${user._id}/profile/thumbnail/profile-picture.webp?${Date.now()}`
          : null;
      const userCardTitle = (
          <span
              className="rainbow-color_gray-4 rainbow-font-size-heading_small rainbow-flex fullwidth"
              style={{ justifyContent: 'center' }}
          >
              <div
                  style={{
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                  }}
              >
                  <Avatar
                      size="large"
                      src={backupPhotoUrl}
                      icon={<PersonIcon />}
                      className="profile-picture"
                  />
                  <input
                      accept="image/*"
                      id="icon-button-photo-profile"
                      onChange={(e) => {
                          this.handleCapture(e, 'profile');
                      }}
                      type="file"
                      label="Modifier la photo de profile"
                      style={{ display: 'none' }}
                  />
                  <label
                      htmlFor="icon-button-photo-profile"
                      className="custom-input-add-file section-add-button"
                  >
            Ajouter une photo de profil
                  </label>
              </div>
          </span>
      );

      const userCardFooter = (
          <div className="rainbow-align-content_space-between">
              <div className="rainbow-flex">
                  <UserField label="Nombre de téléconsultations" value={0} />
                  <div className="react-rainbow-admin-user-detail_vertical-divider" />
                  <UserField label="Solde" value="0 €" />
                  <div className="react-rainbow-admin-user-detail_vertical-divider" />
                  <Button disabled label="Créditer mon solde sur mon compte bancaire" />
              </div>
          </div>
      );

      const {
          email, firstName, lastName, speciality,
      } = user || this.state;
      const { phoneNumber, address } = this.state;
      const fullName = `${lastName} ${firstName}`;
      return (
          <div>
              {user ? (
                  <div>
                      <div className="react-rainbow-admin-user-detail_cards-container">
                          <Card
                              title={userCardTitle}
                              footer={userCardFooter}
                              className="react-rainbow-admin-user-detail_card center-item-profile fullwidth"
                          >
                              <div className="react-rainbow-admin-user-detail_card-content">
                                  <span className="react-rainbow-admin-user-detail_user-name">
                    Docteur
                                      {' '}
                                      {fullName}
                                  </span>
                                  <Badge
                                      variant="lightest"
                                      className="react-rainbow-admin-user-detail_user-status"
                                  >
                                      <FontAwesomeIcon icon={faCheck} pull="left" />
                                      <span>Compte Vérifié</span>
                                  </Badge>
                              </div>
                          </Card>
                          <Card
                              title="Identité"
                              actions={readOnly ? (
                                  <ButtonIcon
                                      variant="border"
                                      onClick={() => { this.setState({ readOnly: !readOnly }); }}
                                      icon={<FontAwesomeIcon icon={faPencilAlt} />}
                                  />
                              ) : (
                                  <div style={{ display: 'flex' }}>
                                      {' '}
                                      <ButtonIcon
                                          variant="border"
                                          style={{ marginRight: 3 }}
                                          onClick={() => this.setState({ readOnly: !readOnly })}
                                          icon={<FontAwesomeIcon icon={faTimes} />} />
                                      <ButtonIcon onClick={this.handleSubmit} variant="border" icon={<FontAwesomeIcon icon={faCheck} />} />
                                      {' '}
                                  </div>
                              )}
                              className="react-rainbow-admin-user-detail_card"
                          >
                              <div className="react-rainbow-admin-user-detail_card-content react-rainbow-admin-user-detail_user-identity">
                                  <UserField
                                      label="Nom et Prénom"
                                      value={fullName}
                                      className="react-rainbow-admin-user-detail_user-identity_field"
                                  />
                                  <UserField
                                      onChange={this.handleChange}
                                      readOnly={readOnly}
                                      label="Numéro de téléphone"
                                      value={phoneNumber}
                                      name="phoneNumber"
                                      className="react-rainbow-admin-user-detail_user-identity_field"
                                  />
                                  <UserField
                                      label="Spécialité"
                                      value={speciality}
                                      className="react-rainbow-admin-user-detail_user-identity_field"
                                  />
                                  <UserField
                                      onChange={this.handleChange}
                                      readOnly={readOnly}
                                      label="Adresse"
                                      name="address"
                                      value={address}
                                      className="react-rainbow-admin-user-detail_user-identity_field"
                                  />
                                  <UserField
                                      label="Email"
                                      value={email}
                                      className="react-rainbow-admin-user-detail_user-identity_field"
                                  />
                              </div>
                          </Card>
                      </div>
                  </div>
              ) : null}
              {cropOpen ? (
                  <ImageCrop
                      open={cropOpen}
                      upImg={photoURL}
                      handleSubmit={this.updateProfilePicture}
                      handleClose={() => { this.setState({ cropOpen: false }); }} />
              ) : null}
          </div>
      );
  }
}

Profile.propTypes = {
    user: PropTypes.object.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    updateUserAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setSnackbar,
        updateUserAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(Profile);

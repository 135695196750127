/* eslint-disable no-underscore-dangle */
import * as TelexAPI from '../../../api/teleexpertise';
import { setSnackbar } from '../snackbar';
import { sessionExpired } from '../auth';
import { createNotification } from '../../../api/notifications';

export const SET_TELEEXPERTISE_LIST = 'SET_TELEEXPERTISE_LIST';
export const SELECT_TELEEXPERTISE = 'SELECT_TELEEXPERTISE';
export const ADD_TELEEXPERTISE = 'ADD_TELEEXPERTISE';
export const UPDATE_TELEEXPERTISE = 'UPDATE_TELEEXPERTISE';

export const setTelexList = value => ({
    type: SET_TELEEXPERTISE_LIST,
    payload: value,
});
export const selectTelex = value => ({
    type: SELECT_TELEEXPERTISE,
    payload: value,
});
export const addTelex = value => ({
    type: ADD_TELEEXPERTISE,
    payload: value,
});
export const updateTelex = value => ({
    type: UPDATE_TELEEXPERTISE,
    payload: value,
});

export const setTelexListAsync = () => async (dispatch) => {
    try {
        const response = await TelexAPI.getByUser();
        if (response.data) {
            dispatch(setTelexList(response.data));
        }
    } catch (err) {
        if (!sessionExpired(err, dispatch)) {
            dispatch(
                setSnackbar(
                    "Une erreut s'est produite ! veuillez réessayer plus tard",
                    'error',
                ),
            );
        }
    }
};

export const selectTelexAsync = id => async (dispatch) => {
    try {
        const response = await TelexAPI.getById(id);
        if (response.data) {
            dispatch(selectTelex(response.data));
        }
    } catch (err) {
        if (!sessionExpired(err, dispatch)) {
            dispatch(
                setSnackbar(
                    "Une erreut s'est produite ! veuillez réessayer plus tard",
                    'error',
                ),
            );
        }
    }
};

export const createTelexAsync = data => async (dispatch) => {
    try {
        const response = await TelexAPI.create(data);
        if (response.data) {
            dispatch(addTelex(response.data));
            dispatch(setSnackbar('Opération effectuée avec succès', 'success'));
            const doctorName = `${response.data.sender.lastName} ${response.data.sender.firstName}`;
            const notifContent = `Docteur ${doctorName} souhaite avoir votre avis professionnel sur un cas`;
            const notification = {
                user: data.receiver,
                content: notifContent,
                type: 'teleexpertise',
            };
            await createNotification(notification);
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
};

export const postTelexMessageAsync = (id, data) => async (dispatch) => {
    try {
        const response = await TelexAPI.postMessage(id, data);
        if (response.data) {
            dispatch(updateTelex(response.data));
            // dispatch(selectTelex(response.data))
            dispatch(setSnackbar('Opération effectuée avec succès', 'success'));
            // TODO add notification
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
};

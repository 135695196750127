/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
import React from 'react';
import {
    Modal, Button, DateTimePicker,
} from 'react-rainbow-components';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UNAVAILABILITY } from '../../config';
import { setSnackbar } from '../../redux/actions/snackbar';
import { addConsultationAsync, updateConsultationAsync, removeConsultationAsync } from '../../redux/actions/consultations';

class AddUnavailability extends React.Component {
    state = {
        modalTitle: 'Ajouter une indisponibilité',
        type: 'add',
        endDate: null,
        startDate: null,

    }

    componentDidUpdate(props) {
        const { consultation, open } = this.props;

        if (props.open !== open) {
            if (consultation) {
                const {
                    start, end, id,
                } = consultation;
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    _id: id,
                    startDate: start,
                    endDate: end,
                    modalTitle: 'Modifier une indisponibilité',
                    type: 'edit',
                });
            }
        }
    }


    closeModal = () => {
        const { handleClose } = this.props;
        this.setState({
            modalTitle: 'Ajouter une indisponibilité',
            type: 'add',
            endDate: null,
            startDate: null,
        });
        handleClose();
    }

    handleDateChange = (type, value) => {
        this.setState({ [type]: value });
    }

    validateInputs = () => {
        const {
            startDate, endDate,
        } = this.state;
        const { setSnackbar } = this.props;
        if (!startDate) {
            setSnackbar('Veuillez Choisir la date de début', 'error');
            return false;
        }

        if (!endDate) {
            setSnackbar('Veuillez choisir la date de fin');
            return false;
        }


        if (endDate) {
            if (startDate > endDate) {
                setSnackbar('Date de début et Date de fin incompatibles', 'error');
                return false;
            }
        }
        return true;
    }


    handleSubmit = () => {
        const {
            type, startDate, endDate, _id,
        } = this.state;
        const {
            addConsultationAsync,
            updateConsultationAsync,
        } = this.props;
        if (this.validateInputs()) {
            const isStartDateObject = typeof (startDate) === 'object';
            const isEndDateObject = typeof (endDate) === 'object';
            const dateStart = DateTime.fromMillis(isStartDateObject ? startDate.getTime() : startDate);
            const dateEnd = DateTime.fromMillis(isEndDateObject ? endDate.getTime() : endDate);
            const body = {
                consultationType: UNAVAILABILITY,
                date: dateStart,
                dateEnd,
            };
            if (type === 'add') {
                addConsultationAsync(body);
                this.closeModal();
            } else {
                body._id = _id;
                updateConsultationAsync(body);
                this.closeModal();
            }
        }
    }

    handleDeleteEvent = () => {
        const { _id } = this.state;
        const { removeConsultationAsync } = this.props;
        removeConsultationAsync(_id);
        this.closeModal();
    };

    render() {
        const { open } = this.props;
        const {
            modalTitle, type, startDate,
            endDate,

        } = this.state;

        return (
            <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">

                <Modal
                    isOpen={open}
                    onRequestClose={this.closeModal}
                    title={modalTitle}
                    footer={(
                        <div className="rainbow-flex rainbow-justify_end">
                            {type === 'edit' ? (
                                <Button
                                    className="rainbow-m-right_large variant-destructive"
                                    label="Supprimer"
                                    variant="destructive"
                                    onClick={this.handleDeleteEvent}
                                />
                            ) : null}

                            <Button label="Enregistrer" className="variant-brand" variant="brand" onClick={() => { this.handleSubmit(); }} />
                        </div>
                    )}
                >
                    <div className="rainbow-flex  rainbow-justify rainbow-p-horizontal_small" syle={{ marginTop: '1em' }}>
                        <DateTimePicker
                            locale="fr"
                            className="rainbow-p-horizontal_medium"
                            label="Date et heure de début"
                            onChange={(value) => { this.handleDateChange('startDate', value.getTime()); }}
                            value={startDate ? new Date(startDate) : null}
                        />
                    </div>
                    <div className="rainbow-flex  rainbow-justify rainbow-p-horizontal_small" style={{ marginTop: '3em' }}>
                        <DateTimePicker
                            locale="fr"
                            className="rainbow-p-horizontal_medium"
                            label="Date et heure de fin"
                            onChange={(value) => { this.handleDateChange('endDate', value.getTime()); }}
                            value={endDate ? new Date(endDate) : null}
                        />
                    </div>

                </Modal>
            </div>
        );
    }
}


AddUnavailability.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    addConsultationAsync: PropTypes.func.isRequired,
    updateConsultationAsync: PropTypes.func.isRequired,
    removeConsultationAsync: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    consultation: PropTypes.object,
};

AddUnavailability.defaultProps = {
    consultation: null,
};


const dispatchToProps = dispatch => bindActionCreators(
    {
        addConsultationAsync,
        updateConsultationAsync,
        removeConsultationAsync,
        setSnackbar,
    },
    dispatch,
);


export default connect(null, dispatchToProps)(AddUnavailability);

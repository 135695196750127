import React from 'react';
import {
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { Breadcrumb, Breadcrumbs, Button } from 'react-rainbow-components';
import { navigateTo } from '../../history';
import PatientsTable from './patientsTable';
// import TrialMessage from '../../components/TrialMessage';
import './styles.css';

const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome');


const Patients = () => (
    <div className="patients-container">
        <div className="react-rainbow-admin-users_header-container">
            <Breadcrumbs>
                <Breadcrumb className="is-jura" label="Dossiers Patients" onClick={() => navigateTo('/patients')} />
            </Breadcrumbs>
        </div>

        <Button variant="brand" className="add-patient-button section-add-button" onClick={() => navigateTo('/patients/add')}>
            <FontAwesomeIcon icon={faPlus} className="rainbow-m-right_medium" />
                    Ajouter un nouveau patient
        </Button>

        <PatientsTable />

    </div>
);

Patients.propTypes = {
};


const stateToProps = state => ({
    user: state.auth.user,
});

export default connect(stateToProps, null)(Patients);

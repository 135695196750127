/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
} from 'react-rainbow-components';
import { DateTime } from 'luxon';
import { PENDING, PAYMENT_PENDING, PAYED } from '../../../config';

const TelecTableActions = (props) => {
    const {
        row, handleDeleteDialog, handleRespondDialog, handleCancel,
    } = props;
    const rowTS = DateTime.fromString(row.date, 'dd/MM/yyyy').ts;
    const isIncomingTelec = Date.now() > rowTS;
    const secButtonLabel = row.status === isIncomingTelec && (PAYED || PENDING || PAYMENT_PENDING) ? 'Annuler' : 'Supprimer';
    const disableRespondButton = row.status !== PENDING;

    return (
        <div className="rainbow-flex rainbow-flex_wrap">

            {!disableRespondButton ? (
                <Button
                    label="Répondre"
                    variant="brand"
                    className="button-action variant-brand"
                    onClick={() => { handleRespondDialog(row); }} />

            ) : null}


            <Button
                label={secButtonLabel}
                variant="destructive"
                className="button-action variant-destructive"
                onClick={() => {
                    if (secButtonLabel === 'Annuler') {
                        handleCancel(row);
                    } else {
                        handleDeleteDialog(row);
                    }
                }

                }
            />
        </div>


    );
};

TelecTableActions.propTypes = {
    row: PropTypes.object.isRequired,
    handleDeleteDialog: PropTypes.func.isRequired,
    handleRespondDialog: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
};


export default (TelecTableActions);

import React from 'react';
import PropTypes from 'prop-types';
import {
    Card, Input,
    CheckboxToggle,

} from 'react-rainbow-components/components';
import {
    User, WeightScale,
    Smoke,
} from '../icons';
import './styles.css';


const GeneralInfo = (props) => {
    const { handleChange, info } = props;

    return (
        <div className="container">
            <form noValidate className="form">
                <Card
                    className="rainbow-rainbow-forms_contact-details"
                >
                    <article className="rainbow-rainbow-forms_inputs-container">
                        <div className="rainbow-flex  rainbow-justify rainbow-p-horizontal_small">
                            <Input
                                onChange={(e) => { handleChange(e); }}
                                className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                icon={<WeightScale />}
                                value={info.weight || ''}
                                name="weight"
                                label="Poids (KG)"
                                type="text" />
                            <Input
                                onChange={(e) => { handleChange(e); }}
                                className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                icon={<User />}
                                value={info.size || ''}
                                name="size"
                                label="Taille (CM)"
                                type="text" />
                        </div>

                        <div className="rainbow-flex rainbow-justify rainbow-p-horizontal_small">
                            <CheckboxToggle
                                style={{ margin: '1rem', marginBottom: 0 }}
                                name="smoker"
                                label="Fumeur ?"
                                value={info.smoker}
                                onChange={() => { handleChange({ target: { name: 'smoker', value: !info.smoker } }); }}
                            />


                        </div>
                        <div className="rainbow-flex rainbow-justify rainbow-p-horizontal_small">

                            <Input
                                onChange={(e) => { handleChange(e); }}
                                className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                icon={<Smoke />}
                                value={info.numberPerDay || ''}
                                disabled={!info.smoker}
                                name="numberPerDay"
                                label="Nombre de cigarettes par jour"
                                type="text" />
                            <Input
                                onChange={(e) => { handleChange(e); }}
                                className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                icon={<Smoke />}
                                value={info.startDate || ''}
                                disabled={!info.smoker}
                                name="startDate"
                                label="Depuis quand?"
                                type="text" />
                        </div>

                    </article>
                </Card>
            </form>

        </div>
    );
};

GeneralInfo.propTypes = {
    handleChange: PropTypes.func,
    info: PropTypes.object.isRequired,
};


GeneralInfo.defaultProps = {
    handleChange: () => null,
};

export default (GeneralInfo);

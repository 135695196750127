/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
} from 'react-rainbow-components';

const AntecTableActions = (props) => {
    const {
        row, handleDelete,
    } = props;
    return (
        <div>
            <Button
                label="Supprimer"
                variant="destructive"
                className="button-action variant-destructive"
                onClick={() => { handleDelete(row); }} />
        </div>


    );
};

AntecTableActions.propTypes = {
    row: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired,
};


export default (AntecTableActions);

import React from 'react';
import {
    Tabset,
    Tab,
} from 'react-rainbow-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectPatient } from '../../../redux/actions/patients';
import SubContent from './subcontent';
// import TrialSubContent from '../trialConsult';

class Content extends React.Component {
      state = {
          globalTab: 'general-info',
          generalInfoTab: 'identity',
          documentsTab: 'bio',
      };


      componentWillMount() {
          const { selectPatient } = this.props;
          selectPatient(null);
      }

      handleSelectTab = (tab, category) => {
          switch (category) {
              case 'general':
                  return this.setState({ generalInfoTab: tab });
              case 'documents':
                  // eslint-disable-next-line react/no-unused-state
                  return this.setState({ documentsTab: tab });
              default: return null;
          }
      }

        handleSelectGlobalTab = (tab) => {
            this.setState({ globalTab: tab });
        }


        render() {
            const { globalTab, generalInfoTab, documentsTab } = this.state;
            return (
                <div className="rainbow-m-bottom_large rainbow-p-bottom_large">

                    <Tabset
                        id="tabset-1"
                        onSelect={(ev, tab) => { this.handleSelectGlobalTab(tab); }}
                        activeTabName={globalTab}
                        className="rainbow-background-color_gray-1 rainbow-p-horizontal_x-large"
                    >
                        <Tab
                            label="Informations Générales"
                            name="general-info"
                            id="general-info"
                            ariaControls="generalInfoTab"
                        />
                        <Tab
                            label="Antécédents Médicaux"
                            name="medical-history"
                            id="medical-history"
                            ariaControls="medicalHistoryTab"
                        />
                        <Tab
                            label="Antécédents Chirurgicaux"
                            name="surgical-history"
                            id="surgical-history"
                            ariaControls="surgicalHistoryTab"
                        />
                        <Tab
                            label="Traitements"
                            name="treatments"
                            id="treatments"
                            ariaControls="treatmentsTab"
                        />
                        <Tab
                            label="Documents et Images"
                            name="documents"
                            id="documents"
                            ariaControls="documentsTab"
                        />
                    </Tabset>
                    <div className="content-container">
                        <SubContent
                            generalInfoTab={generalInfoTab}
                            documentsTab={documentsTab}
                            globalTab={globalTab}
                            handleOnSelect={this.handleSelectTab} />
                    </div>


                </div>
            );
        }
}


Content.propTypes = {
    selectPatient: PropTypes.func.isRequired,
    // user: PropTypes.object.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        selectPatient,
    },
    dispatch,
);


export default connect(stateToProps, dispatchToProps)(Content);

import React from 'react';
import {
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Breadcrumbs, Button } from 'react-rainbow-components';
import CPurposesTable from './cPurposesTable';
import { navigateTo } from '../../history';
import './styles.css';

const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome');


const CPurposes = () => (
    <div className="patients-container">
        <div className="react-rainbow-admin-users_header-container">
            <Breadcrumbs>
                <Breadcrumb className="is-jura" label="Motifs de Consultations" onClick={() => navigateTo('/c-purposes')} />
            </Breadcrumbs>
        </div>
        <Button variant="brand" className="add-purpose-button section-add-button" onClick={() => navigateTo('/c-purposes/add')}>
            <FontAwesomeIcon icon={faPlus} className="rainbow-m-right_medium" />
                    Ajouter un nouveau motif de consultation
        </Button>
        <CPurposesTable />
    </div>
);


export default (CPurposes);

/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import * as AuthAPI from '../../../api/auth';
import { auth, db } from '../../../firebase';
import { setSnackbar } from '../snackbar';
import { addImages } from '../../../api/patients';
import { errorCodes } from '../../../config';
import { setAppIsLoading, setContentIsLoading } from '../loading';


export const SET_USER = 'SET_USER';

export const setUser = value => ({
    type: SET_USER,
    payload: value,
});


export const sessionExpired = async (err, dispatch) => {
    const errMsg = errorCodes[err.code];
    if (errMsg) {
        dispatch(setSnackbar(errMsg, 'error'));
        await auth.signOut();
        await AuthAPI.logout();
        dispatch(setUser(null));
    }
};

export const checkAuthAsync = () => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        const authStatus = await AuthAPI.getStatus();
        if (authStatus.user) dispatch(setUser(authStatus.user));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setAppIsLoading(false));
};

export const signUpAsync = credentials => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        const res = await auth.createUserWithEmailAndPassword(
            credentials.email,
            credentials.password,
        );
        if (res.user) {
            const token = await res.user.getIdToken();
            res.user.sendEmailVerification();
            await db.collection('teleconsultations').add({ [res.user.uid]: 'disconnected' });
            credentials._id = res.user.uid;
            credentials.token = token;
            const response = await AuthAPI.register(credentials);

            if (response) {
                if (credentials.imageToUpload) {
                    addImages(credentials._id, 'com', credentials.imageToUpload);
                }
                dispatch(setUser(response.data));
            }
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setAppIsLoading(false));
};

export const loginAsync = credentials => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        const res = await auth.signInWithEmailAndPassword(
            credentials.email,
            credentials.password,
        );
        if (res.user) {
            const idToken = await res.user.getIdToken(true);
            const id = res.user.uid;
            const response = await AuthAPI.login({ idToken, id });
            const AuthenticatedUser = response.data;
            dispatch(setUser(AuthenticatedUser));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setAppIsLoading(false));
};

export const updateUserAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await AuthAPI.update(body);
        if (res.data) {
            dispatch(setUser(res.data));
            // dispatch(setSnackbar('Profile modifié avec succès', 'success'));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const disconnectAsync = () => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        await auth.signOut();
        await AuthAPI.logout();
        dispatch(setUser(null));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setAppIsLoading(false));
};

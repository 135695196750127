/* eslint-disable no-loop-func */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Column, Table } from 'react-rainbow-components';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TablePagination from '../../../components/TablePagination';
import TableActions from './tableActions';
import {
    updateTelecAsync,
} from '../../../redux/actions/teleconsultation';
import RespondToTelec from '../RespondModal';
import { CHANGED } from '../../../config';
import { setSnackbar } from '../../../redux/actions/snackbar';
import TableTitle from '../../../components/TableTitle';

const formatDate = (date) => {
    const newDate = new Date(date).getTime();
    return DateTime.fromMillis(newDate).toFormat('dd/MM/yyyy à hh:mm');
};

class PlanificationTable extends Component {
       state = {
           activePage: 1,
           openRespondModal: false,
           selectedTelec: null,
       }


    prepareTableData = () => {
        const { telecList } = this.props;
        const result = [];
        if (telecList) {
            telecList.map(telec => result.push({
                fullName: `${telec.patient.lastName} ${telec.patient.firstName}`,
                date: formatDate(telec.date),
                status: telec.status,
                _id: telec._id,
                patient: telec.patient._id,
                doctorFullName: `${telec.patient.lastName} ${telec.patient.firstName}`,
                telec,
            }));
        }
        return result;
    }

    getTableData = () => {
        const { activePage } = this.state;
        const firstItem = (activePage - 1) * 8;
        const lastItem = firstItem + 8;

        return this.prepareTableData().slice(firstItem, lastItem);
    }

    getPages = () => {
        const { telecList } = this.props;
        return Math.ceil(telecList.length / 8);
    }


    handleOnChange = (event, page) => {
        this.setState({ activePage: page });
    }

    handleRespondModal = (telec) => {
        const { openRespondModal } = this.state;
        this.setState({
            openRespondModal: !openRespondModal,
            selectedTelec: telec,
        });
    }

    handleRespond = (selectedOption, date) => {
        const { selectedTelec } = this.state;
        const { setSnackbar, updateTelecAsync } = this.props;
        if (!selectedOption) {
            setSnackbar('Veuillez choisir une option', 'error');
        } else {
            const body = {
                status: selectedOption,
                patient: selectedTelec.patient,
                doctorFullName: selectedTelec.doctorFullName,
            };

            if (selectedOption === CHANGED) {
                if (date) {
                    const newDate = new Date(date);
                    if (newDate.getTime() < Date.now()) {
                        setSnackbar('Veuillez choisir une date valide', 'error');
                    } else {
                        body.date = date;
                    }
                } else {
                    setSnackbar('Veuillez choisir une date', 'error');
                }
            }
            updateTelecAsync(selectedTelec._id, body);
            this.handleRespondModal();
        }
    }

    render() {
        const { activePage, openRespondModal } = this.state;
        return (
            <div className="react-rainbow-admin-users_tab-content">
                <TableTitle title="Prochaines Téléconsultations" />

                <Table resizeColumnDisabled data={this.getTableData()} keyField="_id" sortedBy="date" emptyDescription="Aucune téléconsultation a afficher" emptyTitle="">
                    <Column header="Date" field="date" />
                    <Column header="Patient" field="fullName" />
                    <Column header="Status" field="status" />
                    <Column header="Actions" field="_id" component={TableActions} handleStartDialog={this.handleStartModal} handleRespondDialog={this.handleRespondModal} handleDeleteDialog={this.handleDeleteModal} />
                </Table>

                <TablePagination
                    pages={this.getPages()}
                    activePage={activePage}
                    onChange={this.handleOnChange} />

                {openRespondModal ? (
                    <RespondToTelec
                        handleSubmit={this.handleRespond}
                        open={openRespondModal}
                        handleClose={this.handleRespondModal}
                    />
                ) : null}

            </div>
        );
    }
}

PlanificationTable.propTypes = {
    telecList: PropTypes.array.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    updateTelecAsync: PropTypes.func.isRequired,
};


const stateToProps = state => ({
    telecList: state.teleconsultation.incoming,
});

const dispatchToProps = dispatch => bindActionCreators({
    setSnackbar,
    updateTelecAsync,
}, dispatch);

export default connect(stateToProps, dispatchToProps)(PlanificationTable);

import React from 'react';
import PropTypes from 'prop-types';
import {
    Card, Input,
    Select, DatePicker,
} from 'react-rainbow-components/components';
import {
    User,
} from '../icons';
import { selectOptions } from '../../config';
import './styles.css';


const Identity = (props) => {
    const { handleChange, info } = props;
    const COUNTRY_OPTIONS = selectOptions.allCountriesList;

    return (
        <div className="container">
            <form noValidate className="form">
                <Card
                    className="rainbow-rainbow-forms_contact-details"
                >
                    <article className="rainbow-rainbow-forms_inputs-container">
                        <div className="rainbow-flex  rainbow-justify rainbow-p-horizontal_small">
                            <Input
                                onChange={(e) => { handleChange(e); }}
                                className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                icon={<User />}

                                required
                                name="lastName"
                                label="Nom"
                                value={info.lastName || ''}
                                type="text" />
                            <Input
                                onChange={(e) => { handleChange(e); }}
                                className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                icon={<User />}
                                required
                                name="firstName"
                                label="Prénom"
                                value={info.firstName || ''}
                                type="text" />
                        </div>

                        <div className="rainbow-flex rainbow-justify rainbow-p-horizontal_small">
                            <Select
                                style={{ flex: 1 }}
                                onChange={(e) => { handleChange(e); }}
                                className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                value={info.nationality}
                                options={COUNTRY_OPTIONS}
                                name="nationality"
                                label="Pays"
                                required
                                placeholder="Pays"
                                type="text" />
                        </div>
                        <div className="rainbow-flex rainbow-justify rainbow-p-horizontal_small">

                            <DatePicker
                                locale="fr"
                                style={{ margin: '1em', textTransform: 'initial' }}
                                value={info.birthDate}
                                label="Date de naissance"
                                maxDate={new Date()}
                                name="date-picker"
                                onChange={(value) => {
                                    handleChange({
                                        target: {
                                            name: 'birthDate',
                                            value,
                                        },

                                    });
                                }}
                            />


                        </div>

                    </article>
                </Card>
            </form>

        </div>
    );
};

Identity.propTypes = {
    handleChange: PropTypes.func,
    info: PropTypes.object.isRequired,
};


Identity.defaultProps = {
    handleChange: () => null,
};

export default (Identity);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Input, RadioGroup, DatePicker, Button, Select,
} from 'react-rainbow-components/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSnackbar } from '../../redux/actions/snackbar';
import './styles.css';

const margedInputStyle = { margin: '1rem auto' };


const AddAntecedent = (props) => {
    const {
        handleChange,
        accordionLabel,
        showTreatment,
        options,
        nature,
        handleAdd, handleRadioChange,
        dataToAdd,
        medicalSelectOptions,
        allergiesSelectOptions,
        globalTab,
    } = props;


    const selectedOption = dataToAdd[nature].radioValue;
    let dateValue = new Date();
    if (selectedOption) {
        if (dataToAdd[nature][selectedOption].date) {
            dateValue = dataToAdd[nature][selectedOption].date;
        }
    }


    let dateLabel = 'Date';
    switch (globalTab) {
        case 'medical-history':
            dateLabel = 'Date de début de la maladie'; break;
        case 'surgical-history':
            dateLabel = "Date de l'intervention"; break;
        case 'treatments':
            dateLabel = 'Date de début du traitement'; break;
        default: dateLabel = 'Date'; break;
    }
    if (selectedOption === 'allergies') {
        dateLabel = "Date de debut de l'allergie";
    }
    const [showForm, setShowForm] = useState(false);

    const handleClick = () => {
        if (selectedOption) {
            if (handleAdd(nature)) {
                setShowForm(!showForm);
            }
        } else {
            const { setSnackbar } = props;
            setSnackbar("Veuillez remplir le formulaire avant d'ajouter un antécédent", 'error');
        }
    };

    return (
        <div className="accordion-container">

            {!showForm ? (
                <Button style={{ marginBottom: '1em' }} variant="base" label={accordionLabel} onClick={() => { setShowForm(!showForm); }} />

            ) : null}
            {showForm ? (
                <div>
                    <RadioGroup
                        options={options}
                        value={selectedOption}
                        onChange={(e) => { handleRadioChange(e, nature); }}
                    />
                    {selectedOption === 'medical' ? (
                        <Select
                            className="rainbow-p-horizontal_small"
                            style={margedInputStyle}
                            label="Type d'antécédent"
                            options={medicalSelectOptions}
                            name="type"
                            onChange={e => handleChange(e, nature)}
                        />
                    ) : (null)}

                    {selectedOption === 'allergies' ? (
                        <Select
                            className="rainbow-p-horizontal_small"
                            style={margedInputStyle}
                            label="Type d'allergie"
                            options={allergiesSelectOptions}
                            name="type"
                            onChange={e => handleChange(e, nature)}
                        />
                    ) : (null)}

                    <div className="rainbow-flex rainbow-justify rainbow-p-horizontal_small" style={{ flexWrap: 'wrap' }}>

                        {showTreatment ? (
                            <Input
                                onChange={(e) => { handleChange(e, nature); }}
                                style={margedInputStyle}
                                className="flex-grow"
                                component={Input}
                                name="traitement"
                                label="Traitement"
                                placeholder="Traitement"
                                disabled={selectedOption === null}
                                type="text" />
                        ) : null}


                        <DatePicker
                            locale="fr"
                            maxDate={new Date()}
                            style={margedInputStyle}
                            value={dateValue}
                            label={dateLabel}
                            disabled={selectedOption === null}
                            name="date"
                            onChange={(value) => {
                                handleChange({

                                    target: {
                                        name: 'date',
                                        value: value.toString(),
                                    },

                                }, nature);
                            }}
                        />
                        <Input
                            onChange={(e) => { handleChange(e, nature); }}
                            style={margedInputStyle}
                            className="flex-grow"
                            component={Input}
                            name="observation"
                            label="Observation"
                            disabled={selectedOption === null}
                            placeholder="observation"
                            type="text" />
                    </div>
                    <div className="rainbow-flex rainbow-justify rainbow-p-horizontal_small">

                        <Button className="button-add" label="Ajouter" variant="success" onClick={() => { handleClick(); }} />
                    </div>
                </div>
            ) : null}


        </div>
    );
};

AddAntecedent.propTypes = {
    handleChange: PropTypes.func,
    options: PropTypes.array.isRequired,
    dataToAdd: PropTypes.object.isRequired,
    accordionLabel: PropTypes.string.isRequired,
    showTreatment: PropTypes.bool,
    handleAdd: PropTypes.func.isRequired,
    handleRadioChange: PropTypes.func.isRequired,
    nature: PropTypes.string.isRequired,
    medicalSelectOptions: PropTypes.func,
    allergiesSelectOptions: PropTypes.func,
    // reset: PropTypes.func.isRequired,
    globalTab: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
};


AddAntecedent.defaultProps = {
    handleChange: () => null,
    showTreatment: true,
    medicalSelectOptions: [],
    allergiesSelectOptions: [],
};

const dispatchToProps = dispatch => bindActionCreators(
    {
        setSnackbar,
    },
    dispatch,
);

export default connect(null, dispatchToProps)(AddAntecedent);


/*
rainbow-rainbow-forms_inputs-field */

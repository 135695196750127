/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
} from 'react-rainbow-components';
import { navigateTo } from '../../../history';

const TelexTableButtons = (props) => {
    const {
        value,
        handleEdit,
    } = props;
    const { _id, conversationData, userId } = value;
    let notifNumber = 0;
    if (conversationData.lastMessage.sender !== userId) {
        notifNumber = conversationData.unseenNumber;
    }
    return (
        <div className="rainbow-flex rainbow-justify_center rainbow-flex_wrap" style={{ alignItems: 'center' }}>
            <span className="telex-notif-indicator">
                {notifNumber}
            </span>
            <Button label="Consulter et Modifier" variant="brand" className="button-action variant-brand" onClick={() => { navigateTo(`/teleexpertise/${_id}`); handleEdit(value); }} />
        </div>


    );
};

TelexTableButtons.propTypes = {
    value: PropTypes.object.isRequired,
    handleEdit: PropTypes.func.isRequired,
};


export default (TelexTableButtons);

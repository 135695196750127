import React from 'react';
import {
    Router,
    Switch,
    Route,
} from 'react-router-dom';
import history from './history';
import Dashboard from './sections/dashboard';
import Patients from './sections/patients';
import Messages from './sections/messages';
// import ComingSoon from './sections/comingSoon';
import Login from './sections/login';
import Signup from './sections/signup';
import AddPatient from './sections/patients/add';
import ConsultationHistory from './sections/patients/consultationsHistory';
import Agenda from './sections/agenda';
import EmailVerification from './components/EmailVerification';
import CPurposes from './sections/cPurpose';
import AddCPurposes from './sections/cPurpose/add';
import ProfilePage from './sections/profile';
import TeleexpertisePage from './sections/teleexpertise';
import AddTelex from './sections/teleexpertise/add';
import ConsultTelex from './sections/teleexpertise/consult';
import TeleconsultationPage from './sections/teleconsultation';

export default function Routes() {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route path="/messages" component={Messages} />
                <Route path="/patients" exact component={Patients} />
                <Route path="/patients/add" component={AddPatient} />
                <Route exact path="/patients/:id" component={AddPatient} />
                <Route path="/patients/:id/consultations" component={ConsultationHistory} />
                <Route exact path="/c-purposes" component={CPurposes} />
                <Route path="/c-purposes/add" component={AddCPurposes} />
                <Route path="/c-purposes/:id" component={AddCPurposes} />
                <Route exact path="/teleexpertise" component={TeleexpertisePage} />
                <Route path="/teleexpertise/add" component={AddTelex} />
                <Route path="/teleexpertise/:id" component={ConsultTelex} />
                <Route path="/profile" component={ProfilePage} />
                <Route path="/teleconsultation" component={TeleconsultationPage} />
                <Route path="/login" component={Login} />
                <Route path="/signup" component={Signup} />
                <Route path="/agenda" component={Agenda} />
                <Route path="/unconfirmed" component={EmailVerification} />

            </Switch>
        </Router>
    );
}

/* eslint-disable no-loop-func */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Column, Table } from 'react-rainbow-components';
// import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateTime } from 'luxon';
import TablePagination from '../../../components/TablePagination';
import DeleteTelex from '../../../components/DeleteModal';
import {
    setTelexListAsync,
    selectTelexAsync,
    // postTelexMessageAsync,
} from '../../../redux/actions/teleexpertise';
import TelexTableButtons from './TelexTableButtons';
import '../styles.css';

class TeleexpertiseTable extends Component {
  state = {
      activePage: 1,
      openDeleteDialog: false,
  };

  componentDidMount() {
      const { setTelexListAsync } = this.props;
      setTelexListAsync();
      //    selectTelexAsync();
  }

  isSender = (telex) => {
      const { user } = this.props;
      return telex.sender._id === user._id;
  };

  prepareName = doctor => `${doctor.lastName} ${doctor.firstName}`;

  prepareTelex = () => {
      const { teleexpertise, user } = this.props;
      const results = [];
      teleexpertise.map((telex) => {
          const doctor = this.isSender(telex)
              ? this.prepareName(telex.receiver)
              : this.prepareName(telex.sender);
          const patientName = this.isSender(telex)
              ? `${telex.patient.lastName} ${telex.patient.firstName}`
              : 'Patient anonyme';

          const status = this.isSender(telex) ? 'Envoyé' : 'Réçu';
          const data = {
              doctor,
              patient: patientName,
              status,
              date: DateTime.fromISO(telex.updatedAt).toLocaleString(
                  { locale: 'fr' },
                  DateTime.DATE_FULL,
              ),
              subject: telex.subject,
              data: {
                  _id: telex._id,
                  conversationData: {
                      unseenNumber: telex.unseenMessages,
                      lastMessage: telex.messages[0],
                  },
                  userId: user._id,
              },
          };
          return results.push(data);
      });

      return results;
  };

  getTableData = () => {
      const { activePage } = this.state;
      const firstItem = (activePage - 1) * 8;
      const lastItem = firstItem + 8;

      return this.prepareTelex().slice(firstItem, lastItem);
  };

  getPages = () => {
      const { teleexpertise } = this.props;
      return Math.ceil(teleexpertise.length / 8);
  };

  handleOnChange = (event, page) => {
      this.setState({ activePage: page });
  };

  handleDeleteDialog = (telex) => {
      const { openDeleteDialog } = this.state;
      this.setState({
          openDeleteDialog: !openDeleteDialog,
          telexToDelete: telex,
      });
  };

  handleEdit = (data) => {
      const { selectTelexAsync } = this.props;
      selectTelexAsync(data._id);
  };

  render() {
      const { activePage, openDeleteDialog, telexToDelete } = this.state;
      return (
          <div>
              <div className="react-rainbow-admin-users_tab-content">
                  <Table
                      resizeColumnDisabled
                      data={this.getTableData()}
                      keyField="fullName"
                      sortedBy="fullName"
                      emptyDescription="Aucune donnée a afficher"
                      emptyTitle=""
                  >
                      <Column header="Médecin" field="doctor" />
                      <Column header="Patient" field="patient" />
                      <Column header="Envoyé/Reçu" width={150} field="status" />
                      <Column header="Date" field="date" width={130} />
                      <Column header="Motif" field="subject" />
                      <Column
                          header="Actions"
                          field="data"
                          component={TelexTableButtons}
                          handleEdit={this.handleEdit}
                          handleDeleteDialog={this.handleDeleteDialog}
                      />
                  </Table>
                  <TablePagination
                      pages={this.getPages()}
                      activePage={activePage}
                      onChange={this.handleOnChange}
                  />
                  {openDeleteDialog ? (
                      <DeleteTelex
                          open={openDeleteDialog}
                          handleClose={this.handleDeleteDialog}
                          modalTitle="Supression d'un échange d'avis"
                          contentText="êtes vous sûre de vouloir supprimer cet échange"
                          data={telexToDelete}
                          handleSubmit={this.handleDeletePatient}
                      />
                  ) : null}
              </div>
          </div>
      );
  }
}

TeleexpertiseTable.propTypes = {
    teleexpertise: PropTypes.array,
    setTelexListAsync: PropTypes.func.isRequired,
    selectTelexAsync: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    // postTelexMessageAsync: PropTypes.func.isRequired,
};

TeleexpertiseTable.defaultProps = {
    teleexpertise: [],
};

const stateToProps = state => ({
    teleexpertise: state.teleexpertise.teleexpertiseList,
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setTelexListAsync,
        selectTelexAsync,
        // postTelexMessageAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(TeleexpertiseTable);

import countryList from 'react-select-country-list';


export const doctorSpecialitiesList = [
    { label: '', value: '' },
    { label: 'Anatomie et cytologie pathologiques', value: 'Anatomie et cytologie pathologiques' },
    { label: 'Anesthésie réanimation', value: 'Anesthésie réanimation' },
    { label: 'Assistant(e) Médical', value: 'Assistant(e) Médical' },
    { label: 'Biologie médicale', value: 'Biologie médicale' },
    { label: 'Biologiste', value: 'Biologiste' },
    { label: 'Cardiologie et maladies vasculaires', value: 'Cardiologie et maladies vasculaires' },
    { label: 'Cardiologue', value: 'Cardiologue' },
    { label: 'Chirurgie générale', value: 'Chirurgie générale' },
    { label: 'Chirurgie maxillo-faciale et stomatologie', value: 'Chirurgie maxillo-faciale et stomatologie' },
    { label: 'Chirurgie orthopedique et traumatologie', value: 'Chirurgie orthopedique et traumatologie' },
    { label: 'Chirurgie plastique, reconstructrice et esthétique', value: 'Chirurgie plastique, reconstructrice et esthétique' },
    { label: 'Chirurgie thoracique et cardiovasculaire', value: 'Chirurgie thoracique et cardiovasculaire' },
    { label: 'Chirurgie urologique', value: 'Chirurgie urologique' },
    { label: 'Chirurgie vasculaire', value: 'Chirurgie vasculaire' },
    { label: 'Chirurgie viscérale et digestive', value: 'Chirurgie viscérale et digestive' },
    { label: 'Chirurgien dentiste', value: 'Chirurgien dentiste' },
    { label: 'Coronarographiste', value: 'Coronarographiste' },
    { label: 'Dermatologie et vénéréologie', value: 'Dermatologie et vénéréologie' },
    { label: 'Dermatologue', value: 'Dermatologue' },
    { label: 'Endocrinologie, diabète, maladies métaboliques', value: 'Endocrinologie, diabète, maladies métaboliques' },
    { label: 'Gastro-entérologie et hépatologie', value: 'Gastro-entérologie et hépatologie' },
    { label: 'Gastro-entéroloque', value: 'Gastro-entéroloque' },
    { label: 'Généraliste', value: 'Généraliste' },
    { label: 'Gériatrie', value: 'Gériatrie' },
    { label: 'Gynécologue', value: 'Gynécologue' },
    { label: 'Gynécologue obstétricien', value: 'Gynécologue obstétricien' },
    { label: 'Infirmier', value: 'Infirmier' },
    { label: 'Kinésithérapeute', value: 'Kinésithérapeute' },
    { label: 'Médecin du travail', value: 'Médecin du travail' },
    { label: 'Médecine nucléaire', value: 'Médecine nucléaire' },
    { label: 'Médecine physique et de réadaptation', value: 'Médecine physique et de réadaptation' },
    { label: 'Néphrologue', value: 'Néphrologue' },
    { label: 'Neurologue', value: 'Neurologue' },
    { label: 'Nutritionniste', value: 'Nutritionniste' },
    { label: 'Oncologue', value: 'Oncologue' },
    { label: 'Ophtalmologiste', value: 'Ophtalmologiste' },
    { label: 'Pédiatre', value: 'Pédiatre' },
    { label: 'Pharmacien', value: 'Pharmacien' },
    { label: 'Pneumologue', value: 'Pneumologue' },
    { label: 'Psychiatre', value: 'Psychiatre' },
    { label: 'Psychologue', value: 'Psychologue' },
    { label: 'Psychothérapeute', value: 'Psychothérapeute' },
    { label: 'Radiodiagnostic et imagerie médicale', value: 'Radiodiagnostic et imagerie médicale' },
    { label: 'Radiologue', value: 'Radiologue' },
    { label: 'Réanimation medicale', value: 'Réanimation medicale' },
    { label: 'Rhumatologue', value: 'Rhumatologue' },
    { label: 'Sage-femme', value: 'Sage-femme' },
    { label: 'Stomatologue', value: 'Stomatologue' },
    { label: 'Urologue', value: 'Urologue' }];

export const allCountriesList = [{ label: '', value: '' }, ...countryList().getData()];

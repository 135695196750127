/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Breadcrumbs,
    Breadcrumb,
    Card,
    Input,
    Chip,
    Button,
    ButtonIcon,
} from 'react-rainbow-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faSave,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { navigateTo } from '../../../history';
import {
    addCPurposeAsync,
    updateCPurposeAsync,
    selectCPurpose,
} from '../../../redux/actions/cPurposes';
import { setSnackbar } from '../../../redux/actions/snackbar';

import './styles.css';

class AddCPurpose extends React.Component {
  state = {
      indexationCriteria: [],
      label: '',
      type: 'add',
  };

  componentDidMount() {
      const { selectedCPurpose } = this.props;
      if (selectedCPurpose) {
          this.setState({ type: 'edit', ...selectedCPurpose });
      }
  }

  componentWillUnmount() {
      const { selectCPurpose } = this.props;
      selectCPurpose(null);
  }

  handleLeave = () => {
      navigateTo('/c-purposes');
  };

  handleSubmit = () => {
      const {
          indexationCriteria, label, type, _id,
      } = this.state;
      const { addCPurposeAsync, updateCPurposeAsync, setSnackbar } = this.props;
      if (label.length) {
          if (type === 'add') {
              addCPurposeAsync({ label, indexationCriteria });
              this.handleLeave();
          }
          if (type === 'edit') {
              updateCPurposeAsync({ _id, label, indexationCriteria });
              this.handleLeave();
          }
      } else {
          setSnackbar(
              "Veuillez indiquer le label du motif avant d'enregistrer",
              'error',
          );
      }
  };

  handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
  };

  handleAddCriteria = () => {
      const { indexCr, indexationCriteria } = this.state;
      const { setSnackbar } = this.props;
      if (indexCr && indexCr.length) {
          indexationCriteria.push({ label: indexCr });
          this.setState({ indexCr: '', indexationCriteria });
      } else {
          setSnackbar(
              "Veuillez ajouter un nom au critére d'indexation que vous souhaitez ajouter",
              'error',
          );
      }
  };

  handleDeleteCriteria = (crIndex) => {
      const { indexationCriteria } = this.state;
      const newState = [...indexationCriteria];
      newState.splice(crIndex, 1);
      this.setState({ indexationCriteria: newState });
  };

  render() {
      const {
          indexCr, label, indexationCriteria, type,
      } = this.state;
      const breadCrumbLabel = type === 'add' ? "Ajout d'un motif de consultation" : label;
      return (
          <div>
              <div className="react-rainbow-admin-users_header-container">
                  <Breadcrumbs>
                      <Breadcrumb
                          className="is-jura"
                          label="Motifs de Consultations"
                          onClick={() => navigateTo('/c-purposes')}
                      />
                      <Breadcrumb className="is-jura" label={breadCrumbLabel} />
                  </Breadcrumbs>
              </div>
              <div
                  className="rainbow-m-bottom_large rainbow-p-bottom_large"
                  style={{ display: 'flex', justifyContent: 'center' }}
              >
                  <Card className="rainbow-p-top_large card-cpurpose charts-card-v2 index-c-card">
                      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-start' }}>
                          <div>
                              <div className="rainbow-flex  rainbow-justify rainbow-p-horizontal_small">
                                  <Input
                                      style={{ flex: 1 }}
                                      onChange={(e) => {
                                          this.handleChange(e);
                                      }}
                                      className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                      value={label}
                                      name="label"
                                      label="Libellé du motif"
                                      placeholder="Exemple: Fracture, Grippe..."
                                      required
                                      type="text"
                                  />
                              </div>

                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <Input
                                      onChange={(e) => {
                                          this.handleChange(e);
                                      }}
                                      style={{ margin: '0.75rem 1.5rem' }}
                                      className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                      value={indexCr}
                                      name="indexCr"
                                      label="Critére d'indexation"
                                      placeholder="Exemple: Aigue, Chronique..."
                                      iconPosition="right"
                                      icon={(
                                          <ButtonIcon
                                              disabled={!indexCr || indexCr.trim().length <= 0}
                                              onClick={() => {
                                                  this.handleAddCriteria();
                                              }}
                                              icon={(
                                                  <FontAwesomeIcon
                                                      color={
                                                          !indexCr || indexCr.trim().length <= 0
                                                              ? '#e5e5e5'
                                                              : '#01b6f5'
                                                      }
                                                      icon={faPlus}
                                                  />
                                              )}
                                          />
                                      )}
                                      type="text"
                                  />

                              </div>

                          </div>
                          <div className="index-c-list rainbow-p-vertical_large rainbow-align-content_center rainbow-flex_wrap">
                              <h4 className="index-c-title">
                                  {' '}
                  Liste des critéres d'indexation
                                  {' '}
                              </h4>

                              {indexationCriteria.length > 0 ? (
                                  <div className="index-c-elems">
                                      {indexationCriteria.map((ind, i) => (
                                          <Chip
                                              className="rainbow-m-around_medium criteria-ship variant-brand"
                                              key={i.toString()}
                                              label={ind.label}
                                              onDelete={() => {
                                                  this.handleDeleteCriteria(i);
                                              }}
                                          />
                                      ))}
                                  </div>
                              ) : (
                                  <h4 style={{ marginTop: '2rem' }}>
                                      {' '}
                    Aucun critére d'indexation
                                  </h4>
                              )}
                          </div>


                      </div>

                      <div className="rainbow-flex  rainbow-p-top_large submit-buttons-cpurpose">
                          <Button
                              variant="destructive"
                              className="add-index-c-button variant-destructive"
                              onClick={() => {
                                  this.handleLeave();
                              }}
                          >
                Quitter
                              <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  className="rainbow-m-left_small"
                              />
                          </Button>
                          <Button
                              variant="success"
                              className="add-index-c-button variant-success"
                              onClick={() => {
                                  this.handleSubmit();
                              }}
                          >
                Enregistrer
                              <FontAwesomeIcon
                                  icon={faSave}
                                  className="rainbow-m-left_small"
                              />
                          </Button>
                      </div>
                  </Card>
              </div>
          </div>
      );
  }
}

AddCPurpose.propTypes = {
    selectedCPurpose: PropTypes.object,
    addCPurposeAsync: PropTypes.func.isRequired,
    updateCPurposeAsync: PropTypes.func.isRequired,
    selectCPurpose: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
};

AddCPurpose.defaultProps = {
    selectedCPurpose: null,
};

const stateToProps = state => ({
    selectedCPurpose: state.cPurposes.selectedCPurpose,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        addCPurposeAsync,
        updateCPurposeAsync,
        selectCPurpose,
        setSnackbar,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AddCPurpose);

import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Breadcrumb } from 'react-rainbow-components';
import { connect } from 'react-redux';
import ContentPage from './content';
import { navigateTo } from '../../../history';

const AddPatient = (props) => {
    const { selectedPatient } = props;
    const breadCrumbLabel = selectedPatient ? `${selectedPatient.lastName} ${selectedPatient.firstName}` : "Ajout d'un patient";
    return (
        <div className="patient-medrec-container">
            <div className="react-rainbow-admin-users_header-container">
                <Breadcrumbs>
                    <Breadcrumb
                        className="is-jura"
                        label="Dossier Patients"
                        onClick={() => navigateTo('/patients')}
                    />
                    <Breadcrumb className="is-jura" label={breadCrumbLabel} />
                </Breadcrumbs>
            </div>
            <ContentPage />
        </div>
    );
};
AddPatient.propTypes = {
    selectedPatient: PropTypes.object,
};

AddPatient.defaultProps = {
    selectedPatient: null,
};

const stateToProps = state => ({
    selectedPatient: state.patients.selectedPatient,
});

export default connect(stateToProps, null)(AddPatient);

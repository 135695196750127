/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { Button } from 'react-rainbow-components';

import 'react-image-gallery/styles/css/image-gallery.css';
import './styles.css';


const Documents = (props) => {
    const {
        handleCapture, images, handleDeleteImage, category, allowEdits,
    } = props;
    let { componentRef } = props;
    const [rotationDeg, changeRotation] = useState(0);
    return (
        <div>

            {allowEdits ? (
                <div>
                    <input
                        accept="image/*"
                        style={{ display: 'none', dragging: false }}
                        id="icon-button-photox"
                        onChange={e => handleCapture(e, category, componentRef)}
                        type="file"
                    />
                    <label htmlFor="icon-button-photox" style={{ margin: '1em' }}>
                        <div
                            variant="brand"
                            className="image-input variant-brand"
                        >
                            <span>
                                            Ajouter un nouveau document
                            </span>
                        </div>
                    </label>
                </div>
            ) : null }

            {images.length > 0 ? (
                <Button
                    style={{ display: 'block', margin: '1rem auto' }}
                    variant="neutral"
                    onClick={() => { changeRotation(rotationDeg < 270 ? rotationDeg + 90 : 0); }}
                >
                    Faire pivoter
                </Button>
            ) : null}


            {!allowEdits && images.length === 0 ? (
                <h2 style={{ display: 'flex', justifyContent: 'center', fontSize: '2em' }}> Aucun document à afficher</h2>
            ) : (
                <div style={{ marginTop: '1em' }}>
                    <ImageGallery
                        id={category}
                        ref={(i) => { componentRef = i; }}
                        items={images}
                        showPlayButton={false}
                        showBullets
                        showFullscreenButton={images.length > 0}
                        additionalClass={`rotate-${rotationDeg}`}

                    />
                </div>
            )}


            {images.length > 0 && allowEdits ? (
                <Button
                    style={{
                        display: 'block', margin: '2rem auto',
                    }}
                    onClick={() => handleDeleteImage(
                        category,
                        componentRef.getCurrentIndex(),
                        componentRef,
                    )
                    }
                >
                    Supprimer ce document
                </Button>
            ) : null}
            {' '}
        </div>
    );
};


Documents.propTypes = {
    handleCapture: PropTypes.func,
    handleDeleteImage: PropTypes.func,
    images: PropTypes.array.isRequired,
    category: PropTypes.string.isRequired,
    componentRef: PropTypes.node,
    allowEdits: PropTypes.bool,
};

Documents.defaultProps = {
    handleCapture: () => {},
    handleDeleteImage: () => {},
    componentRef: null,
    allowEdits: true,
};

export default Documents;

/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Card from 'react-rainbow-components/components/Card';
import Input from 'react-rainbow-components/components/Input';
import Button from 'react-rainbow-components/components/Button';
import { Email, Lock } from '../../components/icons';
import validate from './validate';
import { loginAsync, sessionExpired } from '../../redux/actions/auth';
import { setSnackbar } from '../../redux/actions/snackbar';
import { auth } from '../../firebase';
import PasswordRecovery from '../../components/PasswordRecovery';
import history from '../../history';

import './styles.css';

class SignForm extends React.Component {
    state = {
        email: '',
        password: '',
        modalIsOpen: false,
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        const { loginAsync } = this.props;
        loginAsync({ email, password });
    }

    handleOpenModal = () => {
        const { modalIsOpen } = this.state;
        this.setState({ email: '', modalIsOpen: !modalIsOpen });
    }


    handleSubmitPasswordReset = async () => {
        const { emailRecovery } = this.state;
        const { setSnackbar, dispatch } = this.props;
        try {
            await auth
                .sendPasswordResetEmail(emailRecovery);
            setSnackbar('Un email de récupération vous à été envoyé', 'success');
            this.handleOpenModal();
        } catch (err) {
            sessionExpired(err, dispatch);
        }
    }

    render() {
        const { modalIsOpen } = this.state;
        return (
            <div className="login-container-form">
                <Card className="login-card">
                    <form noValidate onSubmit={(e) => { this.handleSubmit(e); }}>
                        <div className="react-rainbow-admin-forms_header">
                            <img src="/assets/images/logo_dark.png" alt="katomi logo" className="logo-img-auth" />
                            <h1 style={{ color: '#576574' }}>Connectez vous à votre espace sécurisé</h1>
                            <div className="rainbow-flex rainbow-align_center" style={{ margin: '1rem auto' }}>
                                <p>Vous n'avez pas un compte?</p>
                                <p onClick={() => { history.push('/signup'); }}>Inscrivez-vous</p>
                            </div>
                        </div>
                        <article>
                            <Field
                                onChange={(e) => { this.handleChange(e); }}
                                className="login-field"
                                icon={<Email />}
                                component={Input}
                                name="email"
                                defaultMessage="Adresse email"
                                placeholder="Tapez votre email"
                                type="email" />
                            <Field
                                onChange={(e) => { this.handleChange(e); }}
                                className="login-field"
                                icon={<Lock />}
                                component={Input}
                                name="password"
                                defaultMessage="Mot de passe"
                                placeholder="Tapez votre mot de passe"
                                type="password" />
                            <Button
                                className="rainbow-m-top_medium variant-brand"
                                style={{ display: 'block', margin: 'auto' }}
                                type="submit"
                                variant="brand">
                                <span>Connexion</span>
                                <i className="fad fa-sign-in login-icon-v2" />
                            </Button>
                            <p className="rainbow-rainbow-forms_forgot rainbow-m-top_small forgot-v2"
                                style={{ cursor: 'pointer' }}
                                onClick={this.handleOpenModal}>
                                Mot de passe oublié?
                                <i className="fad fa-unlock-alt forgot-icon-v2" />
                            </p>
                        </article>
                    </form>

                </Card>
                <PasswordRecovery
                    open={modalIsOpen}
                    handleClose={this.handleOpenModal}
                    handleSubmit={this.handleSubmitPasswordReset}
                    handleChange={this.handleChange} />
            </div>

        );
    }
}

SignForm.propTypes = {
    loginAsync: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
};

SignForm.defaultProps = {
};

const dispatchToProps = dispatch => bindActionCreators(
    {
        loginAsync,
        setSnackbar,
    },
    dispatch,
);

export default reduxForm({
    form: 'sign-in-form',
    validate,
})(connect(null, dispatchToProps)(SignForm));

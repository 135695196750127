/* eslint-disable no-underscore-dangle */
import * as ConsultationsAPI from '../../../api/consultations';
import { setContentIsLoading, setDialogIsLoading } from '../loading';
import { setSnackbar } from '../snackbar';
import { UNAVAILABILITY, AVAILABILITY, TELECONSULTATION } from '../../../config';
import { sessionExpired } from '../auth';

export const SET_CONSULTATIONS_LIST = 'SET_CONSULTATIONS_LIST';
export const SET_CONSULTATIONS_BY_PATIENT_LIST = 'SET_CONSULTATIONS_BY_PATIENT_LIST';
export const ADD_CONSULTATION = 'ADD_CONSULTATION';
export const UPDATE_CONSULTATION = 'UPDATE_CONSULTATION';
export const REMOVE_CONSULTATION = 'REMOVE_CONSULTATION';
export const SELECT_CONSULTATION = 'SELECT_CONSULTATION';

const setConsultationsList = value => ({
    type: SET_CONSULTATIONS_LIST,
    payload: value,
});

const setConsultationsListAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.getAll();
        dispatch(setConsultationsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const setConsultationsByPatientList = value => ({
    type: SET_CONSULTATIONS_BY_PATIENT_LIST,
    payload: value,
});

const setConsultationsByPatientListAsync = id => async (dispatch) => {
    dispatch(setDialogIsLoading(true));
    try {
        const res = await ConsultationsAPI.getByPatient(id);
        dispatch(setConsultationsByPatientList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setDialogIsLoading(false));
};

const addConsultation = consultation => ({
    type: ADD_CONSULTATION,
    payload: consultation,
});

const addConsultationAsync = consultation => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.create({
            ...consultation,
        });
        if (res.data) {
            dispatch(addConsultation(res.data));

            switch (consultation.consultationType) {
                case UNAVAILABILITY:
                    setSnackbar('Indisponibilité Ajoutée Avec succès', 'success');
                    break;
                case AVAILABILITY:
                case TELECONSULTATION:
                    setSnackbar('Téléconsultation Ajoutée Avec succès', 'success');
                    break;
                default:
                    dispatch(setSnackbar('Consultation Ajoutée Avec succès', 'success'));
            }
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const updateConsultation = consultation => ({
    type: UPDATE_CONSULTATION,
    payload: consultation,
});

const updateConsultationAsync = consultation => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.update(consultation._id, consultation);
        if (res.data) {
            dispatch(updateConsultation(res.data));
            dispatch(setSnackbar('Consultation Modifiée Avec succès', 'success'));
        } else {
            dispatch(
                setSnackbar(
                    'Erreur ! Veuillez Remplir Tous les Champs Indiqués par *',
                    'error',
                ),
            );
        }
    } catch (err) {
        if (!sessionExpired(err, dispatch)) {
            dispatch(
                setSnackbar(
                    'Erreur ! Veuillez Remplir Tous les Champs Indiqués par *',
                    'error',
                ),
            );
        }
    }
    dispatch(setContentIsLoading(false));
};

const removeConsultation = id => ({
    type: REMOVE_CONSULTATION,
    payload: id,
});

const removeConsultationAsync = (id, consultationType) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.remove(id);
        if (res.data) {
            dispatch(removeConsultation(res.data._id));
            if (consultationType === UNAVAILABILITY) {
                dispatch(
                    setSnackbar('Indisponibilité suprimée avec succès', 'success'),
                );
            }
            dispatch(setSnackbar('événement suprimée avec succès', 'success'));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

const selectConsultation = value => ({
    type: SELECT_CONSULTATION,
    payload: value,
});

const selectConsultationAsync = id => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.getById(id);
        dispatch(selectConsultation(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

export {
    setConsultationsList,
    setConsultationsListAsync,
    setConsultationsByPatientList,
    setConsultationsByPatientListAsync,
    addConsultation,
    addConsultationAsync,
    updateConsultation,
    updateConsultationAsync,
    removeConsultation,
    removeConsultationAsync,
    selectConsultation,
    selectConsultationAsync,
};

/* eslint-disable no-loop-func */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Column, Table, Spinner,
} from 'react-rainbow-components';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ConsultPatient from './Actions';
import TablePagination from '../../../components/TablePagination';
import DeletePatient from '../../../components/DeleteModal';
import { removePatientAsync, setPatientsListAsync } from '../../../redux/actions/patients';


class PatientsTable extends Component {
       state = {
           activePage: 1,
           openDeleteDialog: false,
           patientToDelete: {
               fullName: '',
               _id: '',
           },
       }

       componentDidMount() {
           const { setPatientsListAsync, loadPatients } = this.props;
           if (loadPatients) {
               setPatientsListAsync();
           }
       }

       preparePatients = () => {
           let { patients } = this.props;
           const results = [];
           let res;
           let lastConsultation;
           if (!patients) {
               patients = [];
           }
           const dt = Date.now();
           for (const patient of patients) {
               lastConsultation = DateTime.fromISO('1998-06-19');
               // eslint-disable-next-line array-callback-return
               patient.consultations.map((consult) => {
                   const consultDate = DateTime.fromISO(consult.date);
                   if (lastConsultation.ts < consultDate.ts) {
                       if (consultDate.ts < dt) {
                           lastConsultation = null;
                           lastConsultation = consultDate;
                       }
                   }
               });
               if (
                   !lastConsultation
            || lastConsultation.ts === DateTime.fromISO('1998-06-19').ts
               ) {
                   res = 'Aucune';
               } else {
                   res = DateTime.fromISO(lastConsultation, {
                       locale: 'fr',
                   }).toLocaleString(DateTime.DATETIME_SHORT);
               }
               const fullName = `${patient.lastName} ${patient.firstName}`;
               const result = {
                   fullName,
                   lastConsultation: res,
                   patientData: { fullName, _id: patient._id },
               };
               results.push(result);
           }

           return results;
       };

    getTableData = () => {
        const { activePage } = this.state;
        const firstItem = (activePage - 1) * 8;
        const lastItem = firstItem + 8;

        return this.preparePatients().slice(firstItem, lastItem);
    }

    getPages = () => {
        const { patients } = this.props;
        return Math.ceil(patients.length / 8);
    }


    handleOnChange = (event, page) => {
        this.setState({ activePage: page });
    }

    handleDeleteDialog = (patient) => {
        const { openDeleteDialog } = this.state;
        this.setState({
            openDeleteDialog: !openDeleteDialog,
            patientToDelete: patient || {
                fullName: '',
                _id: '',
            },
        });
    }

    handleDeletePatient = (id) => {
        const { removePatientAsync } = this.props;
        removePatientAsync(id);
        this.handleDeleteDialog();
    }

    render() {
        const { activePage, openDeleteDialog, patientToDelete } = this.state;
        const { loadPatients, contentIsLoading } = this.props;
        if (contentIsLoading) return <Spinner />;
        return (
            <div>
                <div className="react-rainbow-admin-users_tab-content">
                    <Table resizeColumnDisabled data={this.getTableData()} keyField="fullName" sortedBy="fullName" emptyDescription="Aucune donnée a afficher" emptyTitle="">
                        <Column header="Nom et Prénom" field="fullName" />
                        <Column header="Date de la dérniére visite" field="lastConsultation" />
                        {loadPatients ? (
                            <Column header="Actions" field="patientData" width={window.innerWidth * 40 / 100} component={ConsultPatient} handleDeleteDialog={this.handleDeleteDialog} />
                        ) : null}
                    </Table>
                    <TablePagination
                        pages={this.getPages()}
                        activePage={activePage}
                        onChange={this.handleOnChange} />
                    {loadPatients ? (
                        <DeletePatient
                            open={openDeleteDialog}
                            handleClose={this.handleDeleteDialog}
                            modalTitle="Supression d'un dossier patient"
                            contentText={`êtes vous sûre de bien vouloir supprimer le patient ${patientToDelete.fullName}`}
                            data={patientToDelete}
                            handleSubmit={this.handleDeletePatient} />
                    ) : null}

                </div>
            </div>
        );
    }
}

PatientsTable.propTypes = {
    patients: PropTypes.array.isRequired,
    removePatientAsync: PropTypes.func.isRequired,
    setPatientsListAsync: PropTypes.func.isRequired,
    loadPatients: PropTypes.bool,
    contentIsLoading: PropTypes.bool.isRequired,
};

PatientsTable.defaultProps = {
    loadPatients: true,
};

const stateToProps = state => ({
    patients: state.patients.patientsList,
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators({
    removePatientAsync,
    setPatientsListAsync,
}, dispatch);

export default connect(stateToProps, dispatchToProps)(PatientsTable);

import { db } from '../firebase';

const getNotifications = (userId, callback) => db
    .collection('notifications')
    .where('user', '==', userId)
    .onSnapshot((querySnapshot) => {
        callback(querySnapshot);
    });

const removeMessagesNotifications = async (messagesEntry) => {
    messagesEntry.map(async (id) => {
        await db
            .collection('notifications')
            .doc(id)
            .delete();
    });
};

const removeNotification = async (id) => {
    await db
        .collection('notifications')
        .doc(id)
        .delete();
};

const createNotification = async (data) => {
    await db.collection('notifications').add(data);
};

export {
    getNotifications,
    removeNotification,
    createNotification,
    removeMessagesNotifications,
};

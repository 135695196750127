import { getStats } from '../../../api/auth';
import { sessionExpired } from '../auth';

export const START_LOADING_DASHBOARD = 'START_LOADING_DASHBOARD';
export const STOP_LOADING_DASHBOARD = 'STOP_LOADING_DASHBOARD';
export const LOAD_DASHBOARD_DATA = 'LOAD_DASHBOARD_DATA';

export const loadDashboardData = value => ({
    type: LOAD_DASHBOARD_DATA,
    payload: value,
});

export const startLoadDashboardData = () => ({
    type: LOAD_DASHBOARD_DATA,
    payload: true,
});

export const stopLoadDashboardData = () => ({
    type: LOAD_DASHBOARD_DATA,
    payload: false,
});


export const fetchDashboardData = () => async (dispatch) => {
    dispatch(startLoadDashboardData());
    try {
        const res = await getStats();
        if (res.data) {
            const {
                numberOfActs, numberOfConsultations, consultationsStats, numberOfNewPatients,
            } = res.data;
            const body = {
                numberOfActs,
                consultationsNumber: numberOfConsultations,
                consultationsStats,
                telecNumber: 0,
                numberOfNewPatients,
                // telecStats: res.data.
                // TODO FIX TELEC NUMBER AND STATS !
            };
            dispatch(loadDashboardData(body));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(stopLoadDashboardData());
};

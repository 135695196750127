/* eslint-disable no-loop-func */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Column, Table,
} from 'react-rainbow-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TablePagination from '../../../components/TablePagination';
import CpurposeTableButtons from './cPurposeTableButtons';
import { setCPurposesListAsync, removeCPurposeAsync, selectCPurpose } from '../../../redux/actions/cPurposes';
import DeleteCPurpose from '../../../components/DeleteModal';


class CpurposePage extends Component {
       state = {
           activePage: 1,
           motifToDelete: {
               label: '',
               _id: '',
           },
       }


       componentDidMount() {
           const { setCPurposesListAsync } = this.props;
           setCPurposesListAsync();
       }

    getTableData = () => {
        const { activePage } = this.state;
        const { cPurposes } = this.props;
        const firstItem = (activePage - 1) * 8;
        const lastItem = firstItem + 8;

        return this.prepareCPurposes(cPurposes).slice(firstItem, lastItem);
    }

    getPages = () => {
        const { cPurposes } = this.props;
        return Math.ceil(cPurposes.length / 8);
    }


    handleOnChange = (event, page) => {
        this.setState({ activePage: page });
    }

    handleDeleteDialog = (motif) => {
        const { openDeleteDialog } = this.state;
        this.setState({
            openDeleteDialog: !openDeleteDialog,
            motifToDelete: motif || {
                label: '',
                _id: '',
            },
        });
    }

    handleDelete = () => {
        const { removeCPurposeAsync } = this.props;
        const { motifToDelete } = this.state;
        removeCPurposeAsync(motifToDelete._id);
        this.handleDeleteDialog();
    }

    handleEditCPurpose = (data) => {
        const { selectCPurpose } = this.props;
        selectCPurpose(data);
    }

    prepareCPurposes = (cPurposes) => {
        const results = [];
        for (const cPurpose of cPurposes) {
            const { label, indexationCriteria } = cPurpose;
            const indexCriterias = indexationCriteria
                .map(item => item.label)
                .join(', ');

            const result = { label, indexCriterias, motifData: { ...cPurpose } };

            results.push(result);
        }

        return results;
    };

    render() {
        const { activePage, openDeleteDialog, motifToDelete } = this.state;
        return (
            <div>
                <div className="react-rainbow-admin-users_tab-content">
                    <Table resizeColumnDisabled data={this.getTableData()} keyField="label" sortedBy="label" emptyDescription="Aucune donnée a afficher" emptyTitle="">
                        <Column header="Motif" field="label" />
                        <Column header="Critères d'indexation" field="indexCriterias" />
                        <Column header="Actions" field="motifData" component={CpurposeTableButtons} handleDeleteDialog={this.handleDeleteDialog} handleEdit={this.handleEditCPurpose} />
                    </Table>
                    <TablePagination
                        pages={this.getPages()}
                        activePage={activePage}
                        onChange={this.handleOnChange} />
                    <DeleteCPurpose
                        open={openDeleteDialog}
                        handleClose={this.handleDeleteDialog}
                        modalTitle="Supression d'un motif de consultation"
                        contentText={`êtes vous sûre de bien vouloir supprimer le motif ${motifToDelete.label}`}
                        data={motifToDelete}
                        handleSubmit={this.handleDelete}
                    />

                </div>
            </div>
        );
    }
}

CpurposePage.propTypes = {
    setCPurposesListAsync: PropTypes.func.isRequired,
    removeCPurposeAsync: PropTypes.func.isRequired,
    cPurposes: PropTypes.array,
    selectCPurpose: PropTypes.func.isRequired,
};

CpurposePage.defaultProps = {
    cPurposes: null,
};


const stateToProps = state => ({
    cPurposes: state.cPurposes.cPurposesList,
});

const dispatchToProps = dispatch => bindActionCreators({
    setCPurposesListAsync,
    removeCPurposeAsync,
    selectCPurpose,
}, dispatch);

export default connect(stateToProps, dispatchToProps)(CpurposePage);

import axios from 'axios';
import { API_AUTH } from '../config';

const getStatus = async () => {
    const res = await axios.get(API_AUTH, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const register = async (body) => {
    const res = await axios.post(`${API_AUTH}/register`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
};

const login = async (credentials) => {
    const res = await axios.post(`${API_AUTH}/login`, credentials, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const logout = async () => {
    const res = await axios.post(`${API_AUTH}/logout`, null, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const update = async (body) => {
    const res = await axios.put(`${API_AUTH}/profile`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const getStats = async () => {
    const res = await axios.get(`${API_AUTH}/stats`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
};

export {
    getStatus, register, login, logout, update, getStats,
};

import React from 'react';
import {
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Breadcrumbs, Button } from 'react-rainbow-components';
import { navigateTo } from '../../history';
import TeleexpertiseTable from './teleexpertiseTable';
import './styles.css';

const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome');


const Teleexpertise = () => (
    <div className="patients-container">
        <div className="react-rainbow-admin-users_header-container">
            <Breadcrumbs>
                <Breadcrumb className="is-jura" label="Téléexpertise" onClick={() => navigateTo('/teleexpertise')} />
            </Breadcrumbs>
        </div>
        <Button variant="brand" className="add-patient-button section-add-button" onClick={() => navigateTo('/teleexpertise/add')}>
            <FontAwesomeIcon icon={faPlus} className="rainbow-m-right_medium" />
                 Demander un avis complémentaire
        </Button>
        <TeleexpertiseTable />

    </div>
);


export default (Teleexpertise);

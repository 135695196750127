function validate(values) {
    const {
        email, password,
        firstName,
        lastName,
        numCom,
        country,
        speciality,
    } = values;
    const errors = {};
    if (!email) {
        errors.email = 'Veuillez insérer votre email';
    }
    if (!password) {
        errors.password = 'Veuillez insérer votre mot de passe';
    }
    if (password && password.length < 6) {
        errors.password = 'Votre mot de passe doit contenir au moins 6 caractères';
    }
    if (!firstName) {
        errors.firstName = 'Veuillez insérer votre nom';
    }
    if (!lastName) {
        errors.lastName = 'Veuillez insérer votre prénom';
    }
    if (!numCom) {
        errors.numCom = "Veuillez insérer votre numéro d'inscription au conseil de l'ordre des médecins";
    }
    if (!speciality) {
        errors.speciality = 'Veuillez choisir votre spécialité';
    }
    if (!country) {
        errors.country = 'Veuillez choisir votre pays';
    }
    return errors;
}

export default validate;

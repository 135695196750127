/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
    ActivityTimeline,
    TimelineMarker,
    Breadcrumbs,
    Breadcrumb,
    Textarea,
    Button,
} from 'react-rainbow-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateTime } from 'luxon';
import { navigateTo } from '../../../history';
import {
    updateConsultationAsync,
    setConsultationsByPatientList,
} from '../../../redux/actions/consultations';

class ConsultationsHistory extends React.Component {
  state = {
      inputs: {},
  };

  componentWillMount() {
      const { setConsultationsByPatientList } = this.props;
      setConsultationsByPatientList([]);
  }

  componentDidUpdate(props) {
      const { consultations } = this.props;
      if (props.consultations !== consultations && consultations) {
          const result = {};
          // eslint-disable-next-line no-return-assign
          consultations.map(
              // eslint-disable-next-line no-return-assign
              (consult, key) => (result[`inp-${key}`] = consult.postExamObservation || ''),
          );
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ inputs: result });
      }
  }

  handleChange = (e) => {
      const { inputs } = this.state;
      this.setState({ inputs: { ...inputs, [e.target.name]: e.target.value } });
  };

  handleSave = (consult, key) => {
      const { inputs } = this.state;
      const { updateConsultationAsync } = this.props;
      const postExamObservation = inputs[`inp-${key}`];
      const body = {
          _id: consult._id,
          postExamObservation,
      };
      updateConsultationAsync(body);
  };

  // eslint-disable-next-line consistent-return
  mapConsultations = () => {
      const { consultations } = this.props;

      if (consultations && consultations.length) {
          const mapDate = (date) => {
              const millis = new Date(date);
              return DateTime.fromMillis(
                  millis.getTime(),
              ).toLocaleString(DateTime.DATETIME_SHORT, { locale: 'fr' });
          };
          const { inputs } = this.state;
          const motifLabel = (consult) => {
              // eslint-disable-next-line max-len
              if (consult.consultationPurpose && consult.consultationPurpose.label) return consult.consultationPurpose.label;
              return '';
          };
          return consultations.map((consult, key) => (
              <TimelineMarker
                  id={key}
                  label={consult.consultationType}
                  datetime={`le ${mapDate(consult.date)}`}
                  description={`Motif de consultation:  ${motifLabel(consult)}`}
              >
                  <Textarea
                      value={inputs[`inp-${key}`]}
                      rows={2}
                      label="Observations"
                      name={`inp-${key}`}
                      onChange={(e) => {
                          this.handleChange(e);
                      }}
                  />
                  <Button
                      label="Enregistrer"
                      variant="success"
                      style={{
                          display: 'block', margin: '1rem auto', letterSpacing: 1, fontSize: '1.05rem',
                      }}
                      className="variant-success"
                      onClick={() => {
                          this.handleSave(consult, key);
                      }}
                  />
              </TimelineMarker>
          ));
      }
      return <h1 style={{ fontSize: '2em' }}> Aucune consultation</h1>;
  };

  render() {
      const { selectedPatient } = this.props;
      let patientName = '';
      if (selectedPatient) {
          patientName = `${selectedPatient.lastName} ${selectedPatient.firstName}`;
      }
      return (
          <div>
              {selectedPatient ? (
                  <div className="patients-container">
                      <div className="react-rainbow-admin-users_header-container">
                          <Breadcrumbs>
                              <Breadcrumb
                                  label="Dossier Patients"
                                  onClick={() => navigateTo('/patients')}
                              />
                              <Breadcrumb
                                  label={patientName}
                                  disabled
                                  onClick={() => navigateTo(`/patients/${selectedPatient._id}`)}
                              />
                              <Breadcrumb label="Historique des consultations" />
                          </Breadcrumbs>
                      </div>
                      <div className="rainbow-m-around_xx-large">
                          <ActivityTimeline>{this.mapConsultations()}</ActivityTimeline>
                      </div>
                  </div>
              ) : null}
          </div>
      );
  }
}

ConsultationsHistory.propTypes = {
    updateConsultationAsync: PropTypes.func.isRequired,
    setConsultationsByPatientList: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object,
    consultations: PropTypes.array,
};

ConsultationsHistory.defaultProps = {
    selectedPatient: null,
    consultations: [],
};

const stateToProps = state => ({
    selectedPatient: state.patients.selectedPatient,
    consultations: state.consultations.consultationsByPatientList,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateConsultationAsync,
        setConsultationsByPatientList,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(ConsultationsHistory);

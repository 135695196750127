/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-len */
import React from 'react';
import { Modal, Button, DateTimePicker } from 'react-rainbow-components';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AVAILABILITY, TELECONSULTATION } from '../../config';
import { setSnackbar } from '../../redux/actions/snackbar';
import {
    addConsultationAsync,
    updateConsultationAsync,
    removeConsultationAsync,
} from '../../redux/actions/consultations';
import UserField from '../profile/userField';
import ExistingConsultAlert from './ExistingConsultAlert';

class TelecAvailability extends React.Component {
  state = {
      startDate: null,
      modalTitle: 'Ajouter une plage horaire pour une téléconsultation ',
      type: 'add',
      alertForExistingConsultIsOpen: false,
  };

  componentDidUpdate(props) {
      const { consultation, open } = this.props;

      if (props.open !== open) {
          if (consultation) {
              const {
                  start, end, id, patient, title,
              } = consultation;
              // eslint-disable-next-line react/no-did-update-set-state
              this.setState({
                  _id: id,
                  startDate: start,
                  endDate: end,
                  patient,
                  title,
                  modalTitle: 'Téléconsultation Planifiée',
                  type: 'edit',
                  alertForExistingConsultIsOpen: false,
              });
          }
      }
  }

  showAlertForExistingConsult = (alertContent) => {
      const { alertForExistingConsultIsOpen } = this.state;
      if (alertContent) {
          this.setState({
              alertForExistingConsultIsOpen: !alertForExistingConsultIsOpen,
              alertContent: alertContent.name,
          });
      } else {
          this.setState({
              alertForExistingConsultIsOpen: !alertForExistingConsultIsOpen,
          });
      }
  };

  closeModal = () => {
      const { handleClose } = this.props;
      this.setState({
          modalTitle: 'Ajouter une plage horaire pour une téléconsultation',
          type: 'add',
          endDate: null,
          startDate: null,
          patient: null,
          title: '',
      });
      handleClose();
  };

  handleDateChange = (type, value) => {
      this.setState({ [type]: value });
  };

  validateInputs = () => {
      const HOUR_IN_MILLIS = 3600000;
      const { startDate, alertForExistingConsultIsOpen } = this.state;
      const { setSnackbar, consultationTimes } = this.props;

      if (!startDate) {
          setSnackbar('Veuillez Choisir la date de début', 'error');
          return false;
      }
      const keys = Object.keys(consultationTimes);

      for (let i = 0; i < keys.length; i += 1) {
          if (Math.abs(keys[i] - startDate) < HOUR_IN_MILLIS) {
              const key = keys[i];
              if (
                  consultationTimes[key].type !== AVAILABILITY
               && consultationTimes[key].type !== TELECONSULTATION
               && !alertForExistingConsultIsOpen
              ) {
                  this.showAlertForExistingConsult(consultationTimes[key]);
                  return false;
              }
              if (
                  consultationTimes[key].type === AVAILABILITY
               || consultationTimes[key].type === TELECONSULTATION
              ) {
                  if (alertForExistingConsultIsOpen) this.showAlertForExistingConsult();
                  setSnackbar(
                      'Cet horraire est déja reservé pour une téléconsultation',
                      'error',
                  );
                  return false;
              }
          }
      }

      return true;
  };

  handleSubmit = () => {
      const {
          type, startDate, _id, alertForExistingConsultIsOpen,
      } = this.state;
      const { addConsultationAsync, updateConsultationAsync } = this.props;
      if (this.validateInputs()) {
          const isStartDateObject = typeof startDate === 'object';
          const dateStart = DateTime.fromMillis(
              isStartDateObject ? startDate.getTime() : startDate,
          );
          const body = {
              consultationType: AVAILABILITY,
              date: dateStart,
          };
          if (type === 'add') {
              addConsultationAsync(body);
              this.closeModal();
          } else {
              body._id = _id;
              updateConsultationAsync(body);
              this.closeModal();
          }
          if (alertForExistingConsultIsOpen) {
              this.showAlertForExistingConsult();
          }
      }
  };

  handleDeleteEvent = () => {
      const { _id } = this.state;
      const { removeConsultationAsync } = this.props;
      removeConsultationAsync(_id);
      this.closeModal();
  };

  render() {
      const { open } = this.props;
      const {
          modalTitle,
          type,
          startDate,
          patient,
          title,
          alertContent,
          alertForExistingConsultIsOpen,
      } = this.state;
      const allowDelete = type === 'edit' && !patient;
      let disabledDTP = false;
      if (type === 'add' || allowDelete) {
          disabledDTP = false;
      } else {
          disabledDTP = true;
      }
      return (
          <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">
              <Modal
                  isOpen={open}
                  onRequestClose={this.closeModal}
                  title={modalTitle}
                  footer={
                      patient ? null : (
                          <div className="rainbow-flex rainbow-justify_end">
                              {allowDelete ? (
                                  <Button
                                      className="rainbow-m-right_large variant-destructive"
                                      label="Supprimer"
                                      variant="destructive"
                                      onClick={this.handleDeleteEvent}
                                  />
                              ) : null}

                              {!disabledDTP ? (
                                  <Button
                                      label="Enregistrer"
                                      variant="brand"
                                      className="variant-brand"
                                      onClick={() => {
                                          this.handleSubmit();
                                      }}
                                  />
                              ) : null}
                          </div>
                      )
                  }
              >
                  {disabledDTP ? null : (
                      <div
                          className="rainbow-flex  rainbow-justify rainbow-p-horizontal_small"
                          syle={{ marginTop: '1em' }}
                      >
                          <DateTimePicker
                              locale="fr"
                              className="rainbow-p-horizontal_medium"
                              label="Date et heure de début"
                              minDate={new Date(new Date().valueOf() + 86400000)}
                              onChange={(value) => {
                                  this.handleDateChange('startDate', value.getTime());
                              }}
                              value={startDate ? new Date(startDate) : null}
                          />
                      </div>
                  )}

                  {type === 'add' ? (
                      <div
                          className="telecav-notice-container"
                          syle={{ marginTop: '1em' }}
                      >
                          <h4 className="telecav-notice-title is-jura">
                Comment ça marche?
                          </h4>
                          <div className="telecav-notice-content">
                              <div className="telecav-notice-elem">
                                  <p>
                    Votre plage horaire va apparaître comme disponible pour vos
                    patients
                                  </p>
                              </div>

                              <div className="telecav-notice-elem">
                                  <p>
                    Vous pouvez modifier cette plage horaire tant qu’elle n’a
                    pas été réservée par un patient
                                  </p>
                              </div>
                              <div className="telecav-notice-elem">
                                  <p>
                    Cette plage horaire sera automatiquement supprimé si elle
                    n'est pas réservée par un patient
                                  </p>
                              </div>
                              <div className="telecav-notice-elem">
                                  <p>
                    La téléconsultation est validée lorsque le patient procède
                    au paiement
                                  </p>
                              </div>
                              <div className="telecav-notice-elem">
                                  <p>Le tarif de la téléconsultation est de 25 euros</p>
                              </div>
                              <div className="telecav-notice-elem">
                                  <p>La durée maximale d’une téléconsultation est de 60 mn </p>
                              </div>
                              <div className="telecav-notice-elem">
                                  <p>
                    La téléconsultation ne permet pas de délivrer de certificats
                    médicaux : arrêt de travail, présence, aptitude, etc.
                                  </p>
                              </div>
                              <div className="telecav-notice-elem">
                                  <p>
                    La téléconsultation ne peut se substituer à une consultation
                    physique en cas de motifs d’urgences
                                  </p>
                              </div>
                              <div className="telecav-notice-elem">
                                  <p>
                    Aucun médicament à risque ne pourra être délivré par
                    téléconsultation
                                  </p>
                              </div>
                          </div>
                      </div>
                  ) : null}
                  {patient ? (
                      <div style={{ padding: '2rem' }}>
                          <UserField
                              label="Date et heure de début"
                              value={
                                  startDate
                                      ? DateTime.fromMillis(startDate.getTime()).toFormat(
                                          "dd/MM/yyyy 'à' HH':'mm ",
                                      )
                                      : ''
                              }
                          />
                          <UserField label="Patient" value={title} />
                          <UserField label="Montant payé" value="25€" />
                          <UserField
                              label="Durée maximale de la téléconsultation"
                              value="1 heure"
                          />
                      </div>
                  ) : null}
              </Modal>
              <ExistingConsultAlert
                  alertContent={alertContent}
                  open={alertForExistingConsultIsOpen}
                  handleCancel={this.showAlertForExistingConsult}
                  handleSubmit={this.handleSubmit}
              />
          </div>
      );
  }
}

TelecAvailability.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    addConsultationAsync: PropTypes.func.isRequired,
    updateConsultationAsync: PropTypes.func.isRequired,
    removeConsultationAsync: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    consultation: PropTypes.object,
    consultationTimes: PropTypes.array.isRequired,
};

TelecAvailability.defaultProps = {
    consultation: null,
};

const dispatchToProps = dispatch => bindActionCreators(
    {
        addConsultationAsync,
        updateConsultationAsync,
        removeConsultationAsync,
        setSnackbar,
    },
    dispatch,
);

export default connect(null, dispatchToProps)(TelecAvailability);

/* eslint-disable no-underscore-dangle */
import {
    SET_CPURPOSES_LIST,
    ADD_CPURPOSE,
    UPDATE_CPURPOSE,
    REMOVE_CPURPOSE,
    SELECT_CPURPOSE,
} from '../../actions/cPurposes';

const initialState = {
    cPurposesList: [],
    selectedCPurpose: null,
};

const cPurposes = (state = initialState, action) => {
    switch (action.type) {
        case SET_CPURPOSES_LIST:
            return { ...state, cPurposesList: action.payload };
        case ADD_CPURPOSE:
            return {
                ...state,
                cPurposesList: [...state.cPurposesList, action.payload],
            };
        case UPDATE_CPURPOSE:
            // eslint-disable-next-line no-case-declarations
            const cPurposesList = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const cPurpose of state.cPurposesList) {
                if (cPurpose._id === action.payload._id) {
                    cPurposesList.push(action.payload);
                } else cPurposesList.push(cPurpose);
            }
            return {
                ...state,
                cPurposesList,
            };
        case REMOVE_CPURPOSE:
            return {
                ...state,
                cPurposesList: state.cPurposesList.filter(
                    element => element._id !== action.payload,
                ),
            };
        case SELECT_CPURPOSE:
            return { ...state, selectedCPurpose: action.payload };
        default:
            return state;
    }
};

export default cPurposes;

import axios from 'axios';
import { API_CONSULTATION_PURPOSES } from '../config';

const getAll = async () => {
    const res = await axios.get(API_CONSULTATION_PURPOSES, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_CONSULTATION_PURPOSES}/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const create = async (body) => {
    const res = await axios.post(API_CONSULTATION_PURPOSES, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const update = async (id, body) => {
    const res = await axios.put(`${API_CONSULTATION_PURPOSES}/${id}`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const remove = async (id) => {
    const res = await axios.delete(`${API_CONSULTATION_PURPOSES}/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

export {
    getAll, getById, create, update, remove,
};

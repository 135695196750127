import axios from 'axios';
import { API_PATIENTS, API_PATIENTS_USERS, API_PHOTOS } from '../config';
import { navigateTo } from '../history';

const getAll = async () => {
    const res = await axios.get(API_PATIENTS, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_PATIENTS}/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const create = async (body) => {
    const res = await axios.post(API_PATIENTS, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const update = async (id, body) => {
    const res = await axios.put(`${API_PATIENTS}/${id}`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const remove = async (id) => {
    const res = await axios.delete(`${API_PATIENTS}/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const getAllPatientsUsers = async (body) => {
    const res = await axios.post(`${API_PATIENTS_USERS}/doctor`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
};

const addImages = async (patientId, category, images) => {
    const data = new FormData();
    data.append('files', images);
    const res = await axios.post(
        `${API_PHOTOS}/upload/${patientId}/${category}`,
        data,
        {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    if (category === 'profile') {
        navigateTo('/profile');
    }
    return res.data;
};

export {
    getAll,
    getById,
    create,
    update,
    remove,
    getAllPatientsUsers,
    addImages,
};

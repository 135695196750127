import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-rainbow-components/components/Card';
import Chart from 'react-rainbow-components/components/Chart';
import Dataset from 'react-rainbow-components/components/Dataset';
import { monthsInFrench } from '../../config';

const fontSize = '0.875rem';

const ChartsSection = (props) => {
    const {
        consultationsStats, telecStats,
    } = props;
    return (
        <section className="react-rainbow-admin-dashboard_section rainbow-align-content_space-between rainbow-p-top_large">
            <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small charts-card-v2">

                <h1 style={{ fontSize }} className="react-rainbow-admin-dashboard_chart-title rainbow-align-content_left rainbow-color_dark-1 rainbow-p-bottom_medium is-jura">
                    Consultations
                </h1>
                <Chart className="rainbow-p-top_x-small" showLegend={false} labels={monthsInFrench} type="line">
                    <Dataset values={consultationsStats} backgroundColor="#1de9b6" borderColor="#1de9b6" />
                </Chart>
            </Card>

            <Card className="react-rainbow-admin-dashboard_card rainbow-p-vertical_medium rainbow-p-horizontal_small charts-card-v2">
                <h1 style={{ fontSize }} className="react-rainbow-admin-dashboard_chart-title rainbow-align-content_left rainbow-color_dark-1 rainbow-p-bottom_medium is-jura">
                    Téléconsultations
                </h1>

                <Chart className="rainbow-p-top_x-small" showLegend={false} labels={monthsInFrench} type="line">
                    <Dataset values={telecStats} backgroundColor="#01b6f5" borderColor="#01b6f5" />
                </Chart>
            </Card>


        </section>
    );
};


ChartsSection.propTypes = {
    consultationsStats: PropTypes.array.isRequired,
    telecStats: PropTypes.array.isRequired,
};


export default ChartsSection;

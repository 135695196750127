/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import {
    SET_CONSULTATIONS_LIST,
    SET_CONSULTATIONS_BY_PATIENT_LIST,
    ADD_CONSULTATION,
    UPDATE_CONSULTATION,
    REMOVE_CONSULTATION,
    SELECT_CONSULTATION,
} from '../../actions/consultations';

const initialState = {
    consultationsList: null,
    consultationsByPatientList: null,
    selectedConsultation: null,
};

const consultations = (state = initialState, action) => {
    let consultationsList = [];
    switch (action.type) {
        case SET_CONSULTATIONS_LIST:
            return { ...state, consultationsList: action.payload };
        case SET_CONSULTATIONS_BY_PATIENT_LIST:
            return { ...state, consultationsByPatientList: action.payload };
        case ADD_CONSULTATION:
            return {
                ...state,
                consultationsList: [...state.consultationsList, action.payload],
            };
        case UPDATE_CONSULTATION:
            consultationsList = [];
            if (state.consultationsList) {
                for (const consultation of state.consultationsList) {
                    if (consultation._id === action.payload._id) {
                        consultationsList.push(action.payload);
                    } else consultationsList.push(consultation);
                }
            }
            return {
                ...state,
                consultationsList,
            };
        case REMOVE_CONSULTATION:
            return {
                ...state,
                consultationsList: state.consultationsList.filter(
                    element => element._id !== action.payload,
                ),
            };
        case SELECT_CONSULTATION:
            return { ...state, selectedConsultation: action.payload };
        default:
            return state;
    }
};

export default consultations;

/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import {
    Input,
    Tabset,
    Tab,
    Button,
    Breadcrumbs,
    Breadcrumb,
} from 'react-rainbow-components/components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Peer from 'peerjs';
import { ArrowUp } from '../../components/icons';
import Contacts from './contacts';
import Discussions from './discussions';
// import filter from './filter';
import ChatMessages from './chatMessages';
import SelectedContact from './selectedContact';
import {
    setSelectUser,
    postMessageAsync,
    setUsersAsync,
    setConversationsListAsync,
    selectConversationAsync,
    updateConversationList,
    selectConversation,
} from '../../redux/actions/chat';
import { deleteMessageNotifs } from '../../redux/actions/notifications';
import './styles.css';

class Messages extends Component {
  state = {
      //   searchTerm: '',
      activeTab: 'discussions',
      messageToSend: '',
      activeConnections: {},
  };

  componentDidMount() {
      const {
          setUsersAsync, setConversationsListAsync,
          unseenMessages, deleteMessageNotifs,
      } = this.props;
      setUsersAsync();
      setConversationsListAsync();
      if (unseenMessages.length) {
          deleteMessageNotifs(unseenMessages);
      }
  }

  componentDidUpdate() {
      const { selectedConversation, conversations, user } = this.props;
      const { peer } = this.state;
      const vals = Object.values(conversations);
      if (!selectedConversation && vals.length) {
          selectConversationAsync(vals[0]._id);
      }

      if (user && !peer) {
          this.connectToPeer();
      }
      this.scrollToBottom();
  }

  componentWillUnmount() {
      const { peer } = this.state;
      if (peer) {
          peer.destroy();
      }
  }

  scrollToBottom = () => {
      setTimeout(() => {
          const { selectedConversation } = this.props;
          if (document && selectedConversation) {
              const element = document.getElementById('chat');
              if (element) {
                  element.scrollTop = element.scrollHeight;
              }
          }
      }, 500);
  };

  connectToPeer = () => {
      const { user } = this.props;

      const peer = new Peer(user._id, { host: 'p2p.katomi.co', secure: true });
      this.setState({ peer });
      peer.on('connection', (conn) => {
          const { activeConnections } = this.state;
          this.setState({
              activeConnections: { ...activeConnections, [conn.peer]: conn },
          });

          conn.on('data', (data) => {
              this.handleReceiveData(data, conn);
          });

          conn.on('close', () => {
              this.removeActiveConnection(conn.peer);
          });
      });
  };

  handleSelectTab = (tab) => {
      this.setState({ activeTab: tab });
  };

  handleTabs = () => {
      const { activeTab } = this.state;
      const { doctors, patients } = this.props;
      switch (activeTab) {
          case 'discussions':
              return <Discussions onClick={this.handleClickDiscussion} />;
          case 'contacts':
              return (
                  <Contacts
                      onClick={this.handleClickContact}
                      doctors={doctors}
                      patients={patients}
                  />
              );
          default:
              return null;
      }
  };

  removeActiveConnection = (peer) => {
      const { activeConnections } = this.state;
      const newConnections = { ...activeConnections };
      delete newConnections[peer];
      this.setState({ activeConnections: { ...newConnections } });
  }

  handleClickDiscussion = (conv) => {
      const { setSelectUser } = this.props;
      const _id = conv.user;
      setSelectUser({ _id, ...conv.user_info });
  };

  handleClickContact = (contact) => {
      const { setSelectUser } = this.props;
      const { _id } = contact;
      setSelectUser({
          _id,
          firstName: contact.firstName,
          lastName: contact.lastName,
          speciality: contact.speciality,
      });
  };

  handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmitMessage = (e) => {
      const {
          postMessageAsync,
          selectedUser,
          user,
          selectedConversation,
          conversations,
      } = this.props;
      const { messageToSend } = this.state;
      e.preventDefault();
      if (messageToSend.trim().length > 0) {
          const data = selectedUser
              ? {
                  content: messageToSend,
                  time: Date.now(),
                  u1: user._id,
                  u2: selectedUser._id,
                  u1_info: {
                      firstName: user.firstName,
                      lastName: user.lastName,
                      speciality: user.speciality,
                  },
                  u2_info: {
                      firstName: selectedUser.firstName,
                      lastName: selectedUser.lastName,
                  },
              }
              : {
                  content: messageToSend,
                  time: Date.now(),
                  u1: selectedConversation.u1,
                  u2: selectedConversation.u2,
              };
          const receiver = data.u1 === user._id ? data.u2 : data.u1;
          // eslint-disable-next-line camelcase
          const receiver_info = data.u1 === user._id ? data.u2_info : data.u1_info;
          data.seen = false;
          const withSelectConv = conversations[receiver] === undefined;
          postMessageAsync(data, { receiver, receiver_info }, withSelectConv);
          this.peerSendData(receiver, messageToSend);
          this.setState({ messageToSend: '' });
      }
  };

  handleReceiveData = (data, conn) => {
      const { conversations, updateConversationList } = this.props;
      const conv = conversations[conn.peer];
      if (conv) {
          const newConv = { ...conv };
          const msg = {
              sender: conn.peer,
              content: data,
              seen: false,
              time: Date.now(),
          };
          newConv.messages = [msg];
          updateConversationList(newConv);
          this.updateSelectedConv(conv, msg);
      } else {
          // TODO setConversationsListAsync()
      }
  };

  updateSelectedConv = (conv, msg) => {
      const { selectedConversation, selectConversation } = this.props;
      if (conv._id === selectedConversation._id) {
          const updatedSelectedConv = {
              ...selectedConversation,
              messages: [...selectedConversation.messages, msg],
          };
          selectConversation({ ...updatedSelectedConv });
          this.scrollToBottom();
      }
  };


  peerSendData = (remoteId, data) => {
      const { activeConnections, peer } = this.state;
      const { conversations, user } = this.props;
      if (activeConnections[remoteId]) {
      // Sending the message to the existing connection
          activeConnections[remoteId].send(data);
      } else {
      // connecting to peer
          const conn = peer.connect(remoteId);
          if (conn) {
              conn.on('open', () => {
                  // connection opened
                  this.setState({
                      activeConnections: { ...activeConnections, [conn.peer]: conn },
                  });
                  // sending the message through the opened connection
                  conn.send(data);

                  // listening to data from this connection
                  conn.on('data', (data) => {
                      this.handleReceiveData(data, conn);
                  });

                  conn.on('close', () => {
                      this.removeActiveConnection(conn.peer);
                  });
              });
          }
      }

      const msg = {
          content: data,
          sender: user._id,
          time: Date.now(),
      };
      if (conversations[remoteId]) {
          this.updateSelectedConv(conversations[remoteId], msg);
      }
  };

  render() {
      const { messageToSend, activeTab } = this.state;
      const { selectedConversation, selectedUser } = this.props;
      return (
          <div>
              <div className="react-rainbow-admin-users_header-container">
                  <Breadcrumbs>
                      <Breadcrumb className="is-jura" label="Messagerie" />
                  </Breadcrumbs>
              </div>
              <div className="react-rainbow-admin-messages patients-container" style={{ paddingTop: 10 }}>
                  <div className="react-rainbow-admin-messages_contacts-container">
                      <div className="react-rainbow-admin-messages_contacts">
                          <Tabset
                              id="tabset-chat"
                              onSelect={(ev, tab) => {
                                  this.handleSelectTab(tab);
                              }}
                              activeTabName={activeTab}
                              className="rainbow-background-color_gray-1 rainbow-flex_wrap"
                          >
                              <Tab
                                  label="Discussions"
                                  name="discussions"
                                  id="discussions"
                                  ariaControls="discussions"
                              />
                              <Tab
                                  label="Contacts"
                                  name="contacts"
                                  id="contacts"
                                  ariaControls="contacts"
                              />
                          </Tabset>
                          {this.handleTabs()}
                      </div>
                  </div>
                  <div className="react-rainbow-admin-messages_body">
                      <SelectedContact selectedUser={selectedUser} />
                      <span className="react-rainbow-admin-messages_body--divider" />
                      <div
                          className="react-rainbow-admin-messages_body-messages"
                          id="chat"
                      >
                          <ChatMessages
                              messages={
                                  selectedConversation ? selectedConversation.messages : []
                              }
                          />
                      </div>
                      <div className="react-rainbow-admin-messages_input-container">
                          <form
                              onSubmit={(e) => {
                                  this.handleSubmitMessage(e);
                              }}
                          >
                              <Input
                                  className="react-rainbow-admin-messages_input"
                                  label="Tapez votre message"
                                  hideLabel
                                  value={messageToSend}
                                  name="messageToSend"
                                  onChange={this.handleChange}
                                  placeholder="Tapez votre message"
                                  icon={<ArrowUp />}
                                  iconPosition="right"
                              />
                              <Button
                                  type="submit"
                                  icon={<ArrowUp />}
                                  style={{ marginTop: '1rem', width: '100%' }}
                                  label="Envoyer"
                                  className="variant-brand"
                                  variant="brand"
                              />
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      );
  }
}

Messages.propTypes = {
    setSelectUser: PropTypes.func.isRequired,
    postMessageAsync: PropTypes.func.isRequired,
    setUsersAsync: PropTypes.func.isRequired,
    setConversationsListAsync: PropTypes.func.isRequired,
    conversations: PropTypes.object.isRequired,
    selectedConversation: PropTypes.object,
    selectedUser: PropTypes.object,
    user: PropTypes.object.isRequired,
    doctors: PropTypes.array,
    patients: PropTypes.array,
    updateConversationList: PropTypes.func.isRequired,
    selectConversation: PropTypes.func.isRequired,
    deleteMessageNotifs: PropTypes.func.isRequired,
    unseenMessages: PropTypes.array.isRequired,
};

Messages.defaultProps = {
    selectedConversation: null,
    selectedUser: null,
    doctors: [],
    patients: [],
};

const stateToProps = state => ({
    selectedUser: state.chat.selectedUser,
    selectedConversation: state.chat.selectedConversation,
    conversations: state.chat.conversations,
    doctors: state.chat.doctors,
    patients: state.chat.patients,
    user: state.auth.user,
    unseenMessages: state.notifications.unseenMessages,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setSelectUser,
        postMessageAsync,
        setUsersAsync,
        setConversationsListAsync,
        selectConversationAsync,
        updateConversationList,
        selectConversation,
        deleteMessageNotifs,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(Messages);

//   getContacts = () => {
//       const { conversations, doctors, patients } = this.props;
//       const { searchTerm, activeTab } = this.state;
//       if (activeTab === 'discussions') {
//           return filter(searchTerm, [...doctors, ...patients]);
//       }
//   };

//   handleOnChange = (event) => {
//       this.setState({ searchTerm: event.target.value });
//   };

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Modal, Button } from 'react-rainbow-components';
import PropTypes from 'prop-types';

const textStyles = {
    textAlign: 'center',
    fontSize: 15,
    padding: '0 16px',
};

const formatContent = (alertContent) => {
    if (alertContent === 'indisponibilité') {
        return 'Vous avez marqué que vous seriez indisponnible pour cette date';
    }
    return `Vous avez déja reservé cet horaire pour le patient ${alertContent}`;
};

const ExistingConsultAlert = (props) => {
    const {
        open, handleCancel, handleSubmit, alertContent,
    } = props;
    const content = formatContent(alertContent);
    return (
        <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">

            <Modal
                id="modal-3"
                isOpen={open}
                hideCloseButton
                onRequestClose={handleCancel}
                title="Attention"
                footer={(
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button
                            className="rainbow-m-right_large"
                            label="Changer la date"
                            variant="neutral"
                            onClick={handleCancel}
                        />
                        <Button onClick={handleSubmit} label="Enregistrer quand même" className="variant-brand" variant="brand" />
                    </div>
                )}
            >
                <p style={textStyles}>
                    {content}
                </p>
            </Modal>
        </div>
    );
};

ExistingConsultAlert.propTypes = {
    open: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    alertContent: PropTypes.string.isRequired,
};


export default (ExistingConsultAlert);
